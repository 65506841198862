/* eslint-disable no-useless-escape */
// @flow

export type TGTMAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    metacritic_score: string,
    genre: string,
    release_year: string,
    notable_actor: string,
    director: string,
    ignore_images: boolean,
    imdb_id: string,
};

type TGTMAnswers = {
    [string]: TGTMAnswer
};

export const answers: TGTMAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Forrest Gump"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 82%; User: 7.8',
        genre: 'Drama, Romance',
        release_year: '1994',
        notable_actor: 'Tom Hanks',
        director: 'Robert Zemeckis',
        ignore_images: 'False',
        imdb_id: '',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["The Departed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 85%; User: 6.8',
        genre: 'Drama, Thriller, Crime',
        release_year: '2006',
        notable_actor: 'Leonardo DiCaprio',
        director: 'Martin Scorsese',
        ignore_images: 'False',
        imdb_id: '',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["Ant-Man and the Wasp: Quantumania"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 5.5',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2023',
        notable_actor: 'Paul Rudd',
        director: 'Peyton Reed',
        ignore_images: 'False',
        imdb_id: '',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["The Super Mario Bros. Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 46%; User: 8.2',
        genre: 'Animation, Adventure, Family, Fantasy, Comedy',
        release_year: '2023',
        notable_actor: 'Chris Pratt',
        director: 'Aaron Horvath, Michael Jelenic, Pierre Leduc, Fabien Polack',
        ignore_images: 'False',
        imdb_id: '',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Dungeons & Dragons: Honor Among Thieves"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 72%; User: 7.5',
        genre: 'Adventure, Fantasy, Comedy',
        release_year: '2023',
        notable_actor: 'Chris Pine',
        director: 'John Francis Daley, Jonathan Goldstein',
        ignore_images: 'False',
        imdb_id: '',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["The Last Kingdom: Seven Kings Must Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 6.2',
        genre: 'Action, Adventure, History, Drama, War',
        release_year: '2023',
        notable_actor: 'Mark Rowley',
        director: 'Edward Bazalgette',
        ignore_images: 'False',
        imdb_id: '',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Avatar: The Way of Water"],
        affiliate_links: [],
        franchise: 'James Cameron\'s Avatar',
        metacritic_score: 'Critic: 67%; User: 7.1',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2022',
        notable_actor: 'Sam Worthington',
        director: 'James Cameron',
        ignore_images: 'False',
        imdb_id: '',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Guardians of the Galaxy Volume 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 64%; User: 7.9',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2023',
        notable_actor: 'Chris Pratt',
        director: 'James Gunn',
        ignore_images: 'False',
        imdb_id: '',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Scream VI", "Scream 6"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 61%; User: 6.5',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2023',
        notable_actor: 'Courteney Cox',
        director: 'Matt Bettinelli-Olpin, Tyler Gillett',
        ignore_images: 'False',
        imdb_id: '',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Creed III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 73%; User: 6.8',
        genre: 'Drama, Action',
        release_year: '2023',
        notable_actor: 'Michael B. Jordan',
        director: 'Michael B. Jordan',
        ignore_images: 'False',
        imdb_id: '',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["The Shining"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 8.7',
        genre: 'Horror, Thriller',
        release_year: '1980',
        notable_actor: 'Jack Nicholson',
        director: 'Stanley Kubrick',
        ignore_images: 'False',
        imdb_id: '',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["Rogue One: A Star Wars Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 65%; User: 7.6',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2016',
        notable_actor: 'Felicity Jones',
        director: 'Gareth Edwards',
        ignore_images: 'False',
        imdb_id: '',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["The Dark Knight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 84%; User: 8.9',
        genre: 'Drama, Action, Crime, Thriller',
        release_year: '2008',
        notable_actor: 'Christian Bale',
        director: 'Christopher Nolan',
        ignore_images: 'False',
        imdb_id: '',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["John Wick: Chapter 4"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 8.5',
        genre: 'Action, Thriller, Crime',
        release_year: '2023',
        notable_actor: 'Keanu Reeves',
        director: 'Chad Stahelski',
        ignore_images: 'False',
        imdb_id: '',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["The Matrix"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 73%; User: 8.9',
        genre: 'Action, Science Fiction',
        release_year: '1999',
        notable_actor: 'Keanu Reeves',
        director: 'Lana Wachowski, Lilly Wachowski',
        ignore_images: 'False',
        imdb_id: '',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Cocaine Bear"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 54%; User: 5.3',
        genre: 'Thriller, Comedy, Crime',
        release_year: '2023',
        notable_actor: 'Keri Russell',
        director: 'Elizabeth Banks',
        ignore_images: 'False',
        imdb_id: '',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Boots: The Last Wish"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 73%; User: 8.5',
        genre: 'Animation, Family, Fantasy, Adventure, Comedy, Drama',
        release_year: '2022',
        notable_actor: 'Antonio Banderas',
        director: 'Joel Crawford, Januel Mercado',
        ignore_images: 'False',
        imdb_id: '',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Se7en", "Seven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 65%; User: 8.8',
        genre: 'Crime, Mystery, Thriller',
        release_year: '1995',
        notable_actor: 'Morgan Freeman',
        director: 'David Fincher',
        ignore_images: 'True',
        imdb_id: '',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Jumper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 35%; User: 5.8',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2008',
        notable_actor: 'Hayden Christensen',
        director: 'Doug Liman',
        ignore_images: 'False',
        imdb_id: '',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["Black Panther: Wakanda Forever"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 67%; User: 5.2',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2022',
        notable_actor: 'Letitia Wright',
        director: 'Ryan Coogler',
        ignore_images: 'False',
        imdb_id: '',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Titanic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 75%; User: 8.4',
        genre: 'Drama, Romance',
        release_year: '1997',
        notable_actor: 'Leonardo DiCaprio',
        director: 'James Cameron',
        ignore_images: 'False',
        imdb_id: '',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["Jurassic World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 6.8',
        genre: 'Action, Adventure, Science Fiction, Thriller',
        release_year: '2015',
        notable_actor: 'Chris Pratt',
        director: 'Colin Trevorrow',
        ignore_images: 'False',
        imdb_id: '',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Skyfall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 81%; User: 7.7',
        genre: 'Action, Adventure, Thriller',
        release_year: '2012',
        notable_actor: 'Daniel Craig',
        director: 'Sam Mendes',
        ignore_images: 'False',
        imdb_id: '',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["M3GAN"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 72%; User: 6.6',
        genre: 'Science Fiction, Horror, Comedy',
        release_year: '2022',
        notable_actor: 'Allison Williams',
        director: 'Gerard Johnstone',
        ignore_images: 'False',
        imdb_id: '',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Aladdin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 86%; User: 8.6',
        genre: 'Animation, Family, Adventure, Fantasy, Romance',
        release_year: '1992',
        notable_actor: 'Scott Weinger',
        director: 'Ron Clements, John Musker',
        ignore_images: 'False',
        imdb_id: '',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["Kill Bill: Vol. 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 69%; User: 8.5',
        genre: 'Action, Crime',
        release_year: '2003',
        notable_actor: 'Uma Thurman',
        director: 'Quentin Tarantino',
        ignore_images: 'False',
        imdb_id: '',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Zodiac"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 79%; User: 8.0',
        genre: 'Crime, Drama, Mystery, Thriller',
        release_year: '2007',
        notable_actor: 'Jake Gyllenhaal',
        director: 'David Fincher',
        ignore_images: 'False',
        imdb_id: '',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["The Fate of the Furious"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 56%; User: 6.9',
        genre: 'Action, Crime, Thriller',
        release_year: '2017',
        notable_actor: 'Vin Diesel',
        director: 'F. Gary Gray',
        ignore_images: 'False',
        imdb_id: '',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["Iron Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 79%; User: 8.4',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2008',
        notable_actor: 'Robert Downey Jr.',
        director: 'Jon Favreau',
        ignore_images: 'False',
        imdb_id: '',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Home Alone 2: Lost in New York"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 46%; User: 7.0',
        genre: 'Comedy, Family, Adventure',
        release_year: '1992',
        notable_actor: 'Macaulay Culkin',
        director: 'Chris Columbus',
        ignore_images: 'False',
        imdb_id: '',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Renfield"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 53%; User: 6.4',
        genre: 'Horror, Comedy, Fantasy',
        release_year: '2023',
        notable_actor: 'Nicholas Hoult',
        director: 'Chris McKay',
        ignore_images: 'False',
        imdb_id: '',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Joker"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 8.8',
        genre: 'Crime, Thriller, Drama',
        release_year: '2019',
        notable_actor: 'Joaquin Phoenix',
        director: 'Todd Phillips',
        ignore_images: 'False',
        imdb_id: '',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Grease"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 70%; User: 7.5',
        genre: 'Romance, Comedy',
        release_year: '1978',
        notable_actor: 'John Travolta',
        director: 'Randal Kleiser',
        ignore_images: 'False',
        imdb_id: '',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["Top Gun: Maverick"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 7.5',
        genre: 'Action, Drama',
        release_year: '2022',
        notable_actor: 'Tom Cruise',
        director: 'Joseph Kosinski',
        ignore_images: 'False',
        imdb_id: '',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["The Thing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 57%; User: 8.6',
        genre: 'Horror, Mystery, Science Fiction',
        release_year: '1982',
        notable_actor: 'Kurt Russell',
        director: 'John Carpenter',
        ignore_images: 'False',
        imdb_id: '',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Four"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 27%; User: 2.8',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: 'Miles Teller',
        director: 'Josh Trank',
        ignore_images: 'False',
        imdb_id: '',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["The Grey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 64%; User: 5.9',
        genre: 'Drama, Thriller, Action, Adventure',
        release_year: '2012',
        notable_actor: 'Liam Neeson',
        director: 'Joe Carnahan',
        ignore_images: 'False',
        imdb_id: '',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["Her"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 91%; User: 8.5',
        genre: 'Romance, Science Fiction, Drama',
        release_year: '2013',
        notable_actor: 'Joaquin Phoenix',
        director: 'Spike Jonze',
        ignore_images: 'False',
        imdb_id: '',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Rocky III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 57%; User: 7.5',
        genre: 'Drama',
        release_year: '1982',
        notable_actor: 'Sylvester Stallone',
        director: 'Sylvester Stallone',
        ignore_images: 'False',
        imdb_id: '',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Ghostbusters"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 50%; User: 2.7',
        genre: 'Action, Fantasy, Comedy',
        release_year: '2016',
        notable_actor: 'Melissa McCarthy',
        director: 'Paul Feig',
        ignore_images: 'False',
        imdb_id: '',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Idiocracy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 66%; User: 7.2',
        genre: 'Comedy, Science Fiction, Adventure',
        release_year: '2006',
        notable_actor: 'Luke Wilson',
        director: 'Mike Judge',
        ignore_images: 'False',
        imdb_id: '',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["Ghost"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 52%; User: 7.2',
        genre: 'Fantasy, Drama, Thriller, Mystery, Romance',
        release_year: '1990',
        notable_actor: 'Patrick Swayze',
        director: 'Jerry Zucker',
        ignore_images: 'False',
        imdb_id: '',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Scream"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 66%; User: 8.2',
        genre: 'Horror, Mystery, Thriller',
        release_year: '1996',
        notable_actor: 'Neve Campbell',
        director: 'Wes Craven',
        ignore_images: 'False',
        imdb_id: '',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["Edward Scissorhands"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 74%; User: 8.3',
        genre: 'Fantasy, Drama, Romance',
        release_year: '1990',
        notable_actor: 'Johnny Depp',
        director: 'Tim Burton',
        ignore_images: 'False',
        imdb_id: '',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Total Recall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 60%; User: 7.9',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '1990',
        notable_actor: 'Arnold Schwarzenegger',
        director: 'Paul Verhoeven',
        ignore_images: 'False',
        imdb_id: '',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["You Don't Mess with the Zohan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 54%; User: 5.4',
        genre: 'Comedy, Action',
        release_year: '2008',
        notable_actor: 'Adam Sandler',
        director: 'Dennis Dugan',
        ignore_images: 'False',
        imdb_id: '',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Police Academy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 41%; User: 7.2',
        genre: 'Comedy, Crime',
        release_year: '1984',
        notable_actor: 'Steve Guttenberg',
        director: 'Hugh Wilson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Sherlock Holmes: A Game of Shadows"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 7.4',
        genre: 'Adventure, Action, Crime, Mystery',
        release_year: '2011',
        notable_actor: 'Robert Downey Jr.',
        director: 'Guy Ritchie',
        ignore_images: 'False',
        imdb_id: '',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["The Woman King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 76%; User: 3.0',
        genre: 'Action, Drama, History',
        release_year: '2022',
        notable_actor: 'Viola Davis',
        director: 'Gina Prince-Bythewood',
        ignore_images: 'False',
        imdb_id: '',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["Angels & Demons"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 6.4',
        genre: 'Thriller, Mystery',
        release_year: '2009',
        notable_actor: 'Tom Hanks',
        director: 'Ron Howard',
        ignore_images: 'False',
        imdb_id: '',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["Doctor Strange in the Multiverse of Madness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 60%; User: 6.0',
        genre: 'Fantasy, Action, Adventure',
        release_year: '2022',
        notable_actor: 'Benedict Cumberbatch',
        director: 'Sam Raimi',
        ignore_images: 'False',
        imdb_id: '',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Mutant Ninja Turtles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 51%; User: 7.9',
        genre: 'Science Fiction, Action, Adventure, Comedy, Family',
        release_year: '1990',
        notable_actor: 'Judith Hoag',
        director: 'Steve Barron',
        ignore_images: 'False',
        imdb_id: '',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Blade"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 47%; User: 7.1',
        genre: 'Horror, Action',
        release_year: '1998',
        notable_actor: 'Wesley Snipes',
        director: 'Stephen Norrington',
        ignore_images: 'False',
        imdb_id: '',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["John Wick: Chapter 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 82%; User: 8.1',
        genre: 'Action, Thriller, Crime',
        release_year: '2017',
        notable_actor: 'Keanu Reeves',
        director: 'Chad Stahelski',
        ignore_images: 'False',
        imdb_id: '',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["The Italian Job"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 7.1',
        genre: 'Action, Crime, Comedy, Thriller',
        release_year: '2003',
        notable_actor: 'Donald Sutherland',
        director: 'F. Gary Gray',
        ignore_images: 'False',
        imdb_id: '',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["The Batman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 72%; User: 7.9',
        genre: 'Crime, Mystery, Thriller',
        release_year: '2022',
        notable_actor: 'Robert Pattinson',
        director: 'Matt Reeves',
        ignore_images: 'False',
        imdb_id: '',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["Twilight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 56%; User: 4.1',
        genre: 'Fantasy, Drama, Romance',
        release_year: '2008',
        notable_actor: 'Kristen Stewart',
        director: 'Catherine Hardwicke',
        ignore_images: 'False',
        imdb_id: '',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Togo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 69%; User: 8.1',
        genre: 'Adventure, Family',
        release_year: '2019',
        notable_actor: 'Willem Dafoe',
        director: 'Ericson Core',
        ignore_images: 'False',
        imdb_id: '',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["The Revenant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 76%; User: 8.0',
        genre: 'Western, Drama, Adventure',
        release_year: '2015',
        notable_actor: 'Leonardo DiCaprio',
        director: 'Alejandro G. Iñárritu',
        ignore_images: 'False',
        imdb_id: '',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["A Man Called Otto"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 51%; User: 6.7',
        genre: 'Comedy, Drama',
        release_year: '2022',
        notable_actor: 'Tom Hanks',
        director: 'Marc Forster',
        ignore_images: 'False',
        imdb_id: '',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Beasts: The Crimes of Grindelwald"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 52%; User: 5.9',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2018',
        notable_actor: 'Eddie Redmayne',
        director: 'David Yates',
        ignore_images: 'False',
        imdb_id: '',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Avengers: Infinity War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 8.7',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2018',
        notable_actor: 'Robert Downey Jr.',
        director: 'Anthony Russo, Joe Russo',
        ignore_images: 'False',
        imdb_id: '',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Mad Max Beyond Thunderdome", "Mad Max 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 71%; User: 6.2',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '1985',
        notable_actor: 'Mel Gibson',
        director: 'George Miller, George Ogilvie',
        ignore_images: 'False',
        imdb_id: '',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["The Fox and the Hound"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 65%; User: 7.6',
        genre: 'Adventure, Animation, Drama, Family',
        release_year: '1981',
        notable_actor: 'Mickey Rooney',
        director: 'Ted Berman, Richard Rich, Art Stevens, David Hand, Wolfgang Reitherman',
        ignore_images: 'False',
        imdb_id: '',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Independence Day"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 7.0',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '1996',
        notable_actor: 'Will Smith',
        director: 'Roland Emmerich',
        ignore_images: 'False',
        imdb_id: '',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["Dawn of the Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 7.3',
        genre: 'Horror, Action',
        release_year: '2004',
        notable_actor: 'Sarah Polley',
        director: 'Zack Snyder',
        ignore_images: 'False',
        imdb_id: '',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["Superman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 82%; User: 7.9',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '1978',
        notable_actor: 'Christopher Reeve',
        director: 'Richard Donner',
        ignore_images: 'False',
        imdb_id: '',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["Pitch Perfect 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 63%; User: 6.0',
        genre: 'Comedy, Music',
        release_year: '2015',
        notable_actor: 'Anna Kendrick',
        director: 'Elizabeth Banks',
        ignore_images: 'False',
        imdb_id: '',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["Watchmen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 56%; User: 8.4',
        genre: 'Action, Mystery, Science Fiction',
        release_year: '2009',
        notable_actor: 'Jackie Earle Haley',
        director: 'Zack Snyder',
        ignore_images: 'False',
        imdb_id: '',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["King Richard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 76%; User: 6.6',
        genre: 'Drama, History',
        release_year: '2021',
        notable_actor: 'Will Smith',
        director: 'Reinaldo Marcus Green',
        ignore_images: 'False',
        imdb_id: '',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["Lone Survivor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 60%; User: 7.5',
        genre: 'War, Action, Drama, History',
        release_year: '2013',
        notable_actor: 'Mark Wahlberg',
        director: 'Peter Berg',
        ignore_images: 'False',
        imdb_id: '',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["Ex Machina"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 7.9',
        genre: 'Drama, Science Fiction',
        release_year: '2015',
        notable_actor: 'Alicia Vikander',
        director: 'Alex Garland',
        ignore_images: 'False',
        imdb_id: '',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["The Whale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 60%; User: 7.4',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: 'Brendan Fraser',
        director: 'Darren Aronofsky',
        ignore_images: 'False',
        imdb_id: '',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible II"],
        affiliate_links: [],
        franchise: 'Mission: Impossible',
        metacritic_score: 'Critic: 59%; User: 5.8',
        genre: 'Adventure, Action, Thriller',
        release_year: '2000',
        notable_actor: 'Tom Cruise',
        director: 'John Woo',
        ignore_images: 'False',
        imdb_id: '',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["First Blood", "First Blood (Rambo I)"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 61%; User: 8.2',
        genre: 'Action, Adventure, Thriller, War',
        release_year: '1982',
        notable_actor: 'Sylvester Stallone',
        director: 'Ted Kotcheff',
        ignore_images: 'False',
        imdb_id: '',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Uncharted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 45%; User: 6.1',
        genre: 'Action, Adventure',
        release_year: '2022',
        notable_actor: 'Tom Holland',
        director: 'Ruben Fleischer',
        ignore_images: 'False',
        imdb_id: '',
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["V for Vendetta"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 62%; User: 8.4',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2006',
        notable_actor: 'Hugo Weaving',
        director: 'James McTeigue',
        ignore_images: 'False',
        imdb_id: '',
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["Stuart Little 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 66%; User: 6.6',
        genre: 'Family, Adventure, Animation, Comedy',
        release_year: '2002',
        notable_actor: 'Michael J. Fox',
        director: 'Rob Minkoff',
        ignore_images: 'False',
        imdb_id: '',
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["Nanny McPhee"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 6.2',
        genre: 'Fantasy, Comedy, Family',
        release_year: '2005',
        notable_actor: 'Emma Thompson',
        director: 'Kirk Jones',
        ignore_images: 'False',
        imdb_id: '',
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["Nacho Libre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 52%; User: 6.8',
        genre: 'Comedy, Family',
        release_year: '2006',
        notable_actor: 'Jack Black',
        director: 'Jared Hess',
        ignore_images: 'False',
        imdb_id: '',
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["The Chronicles of Narnia: The Lion, the Witch and the Wardrobe"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 75%; User: 7.5',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2005',
        notable_actor: 'Tilda Swinton',
        director: 'Andrew Adamson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["Warcraft"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 32%; User: 8.1',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: 'Travis Fimmel',
        director: 'Duncan Jones',
        ignore_images: 'False',
        imdb_id: '',
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["Shrek"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 84%; User: 8.6',
        genre: 'Animation, Comedy, Fantasy, Adventure, Family',
        release_year: '2001',
        notable_actor: 'Mike Myers',
        director: 'Andrew Adamson, Vicky Jenson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["The Greatest Showman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 7.5',
        genre: 'Drama',
        release_year: '2017',
        notable_actor: 'Hugh Jackman',
        director: 'Michael Gracey',
        ignore_images: 'False',
        imdb_id: '',
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["Lethal Weapon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 7.8',
        genre: 'Adventure, Action, Comedy, Thriller, Crime',
        release_year: '1987',
        notable_actor: 'Mel Gibson',
        director: 'Richard Donner',
        ignore_images: 'False',
        imdb_id: '',
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["Conan the Barbarian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 36%; User: 5.0',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2011',
        notable_actor: 'Jason Momoa',
        director: 'Marcus Nispel',
        ignore_images: 'False',
        imdb_id: '',
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["The Hunchback of Notre Dame"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 74%; User: 7.8',
        genre: 'Drama, Animation, Family',
        release_year: '1996',
        notable_actor: 'Demi Moore',
        director: 'Gary Trousdale, Kirk Wise',
        ignore_images: 'False',
        imdb_id: '',
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["1917"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 8.4',
        genre: 'War, Drama, History',
        release_year: '2019',
        notable_actor: 'Dean-Charles Chapman',
        director: 'Sam Mendes',
        ignore_images: 'False',
        imdb_id: '',
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["Blade Runner"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 84%; User: 8.6',
        genre: 'Science Fiction, Drama, Thriller',
        release_year: '1982',
        notable_actor: 'Harrison Ford',
        director: 'Ridley Scott',
        ignore_images: 'False',
        imdb_id: '',
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["National Treasure: Book of Secrets"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 6.4',
        genre: 'Action, Adventure, Mystery, Thriller',
        release_year: '2007',
        notable_actor: 'Nicolas Cage',
        director: 'Jon Turteltaub',
        ignore_images: 'False',
        imdb_id: '',
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["Hacksaw Ridge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 71%; User: 8.3',
        genre: 'Drama, History, War',
        release_year: '2016',
        notable_actor: 'Andrew Garfield',
        director: 'Mel Gibson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["Traffic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 86%; User: 8.0',
        genre: 'Thriller, Drama, Crime',
        release_year: '2000',
        notable_actor: 'Michael Douglas',
        director: 'Steven Soderbergh',
        ignore_images: 'False',
        imdb_id: '',
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["RoboCop"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 70%; User: 8.3',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1987',
        notable_actor: 'Peter Weller',
        director: 'Paul Verhoeven',
        ignore_images: 'False',
        imdb_id: '',
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["Taxi Driver"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 94%; User: 8.7',
        genre: 'Crime, Drama',
        release_year: '1976',
        notable_actor: 'Robert De Niro',
        director: 'Martin Scorsese',
        ignore_images: 'False',
        imdb_id: '',
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["Indiana Jones and the Temple of Doom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 57%; User: 7.7',
        genre: 'Adventure, Action',
        release_year: '1984',
        notable_actor: 'Harrison Ford',
        director: 'Steven Spielberg',
        ignore_images: 'False',
        imdb_id: '',
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["Bullet Train"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 49%; User: 7.2',
        genre: 'Action, Comedy, Thriller',
        release_year: '2022',
        notable_actor: 'Brad Pitt',
        director: 'David Leitch',
        ignore_images: 'False',
        imdb_id: '',
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla vs. Kong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 6.8',
        genre: 'Action, Fantasy, Science Fiction',
        release_year: '2021',
        notable_actor: 'Alexander Skarsgård',
        director: 'Adam Wingard',
        ignore_images: 'False',
        imdb_id: '',
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Sorcerer's Stone", "Harry Potter and the Philosopher's Stone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 65%; User: 7.9',
        genre: 'Adventure, Fantasy',
        release_year: '2001',
        notable_actor: 'Daniel Radcliffe',
        director: 'Chris Columbus',
        ignore_images: 'False',
        imdb_id: '',
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["Happy Feet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 77%; User: 6.6',
        genre: 'Animation, Comedy, Family',
        release_year: '2006',
        notable_actor: 'Elijah Wood',
        director: 'George Miller, Warren Coleman',
        ignore_images: 'False',
        imdb_id: '',
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["The Ring"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 57%; User: 7.2',
        genre: 'Horror, Mystery',
        release_year: '2002',
        notable_actor: 'Naomi Watts',
        director: 'Gore Verbinski',
        ignore_images: 'False',
        imdb_id: '',
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["Dark Phoenix", "X-Men: Dark Phoenix"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 43%; User: 4.9',
        genre: 'Fantasy, Science Fiction, Action, Adventure',
        release_year: '2019',
        notable_actor: 'James McAvoy',
        director: 'Simon Kinberg',
        ignore_images: 'False',
        imdb_id: '',
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["Cruella"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 7.2',
        genre: 'Comedy, Crime',
        release_year: '2021',
        notable_actor: 'Emma Stone',
        director: 'Craig Gillespie',
        ignore_images: 'False',
        imdb_id: '',
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["Ace Ventura: Pet Detective"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 37%; User: 7.0',
        genre: 'Comedy, Adventure',
        release_year: '1994',
        notable_actor: 'Jim Carrey',
        director: 'Tom Shadyac',
        ignore_images: 'False',
        imdb_id: '',
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["The Lost City"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 60%; User: 6.0',
        genre: 'Action, Adventure, Comedy',
        release_year: '2022',
        notable_actor: 'Sandra Bullock',
        director: 'Aaron Nee, Adam Nee',
        ignore_images: 'False',
        imdb_id: '',
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["The Polar Express"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 61%; User: 6.9',
        genre: 'Animation, Family, Adventure, Fantasy, Comedy',
        release_year: '2004',
        notable_actor: 'Tom Hanks',
        director: 'Robert Zemeckis',
        ignore_images: 'False',
        imdb_id: '',
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["The Outsiders"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 45%; User: 7.2',
        genre: 'Crime, Drama',
        release_year: '1983',
        notable_actor: 'C. Thomas Howell',
        director: 'Francis Ford Coppola',
        ignore_images: 'False',
        imdb_id: '',
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Beasts: The Secrets of Dumbledore"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 47%; User: 5.9',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2022',
        notable_actor: 'Eddie Redmayne',
        director: 'David Yates',
        ignore_images: 'False',
        imdb_id: '',
    },
    '108': {
        content: '',
        submitted_by: '',
        answers: ["Dune", "Dune: Part One"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 74%; User: 7.9',
        genre: 'Science Fiction, Adventure',
        release_year: '2021',
        notable_actor: 'Timothée Chalamet',
        director: 'Denis Villeneuve',
        ignore_images: 'False',
        imdb_id: '',
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["Zack Snyder's Justice League", "Justice League"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 54%; User: 8.7',
        genre: 'Action, Adventure, Fantasy, Science Fiction',
        release_year: '2021',
        notable_actor: 'Henry Cavill',
        director: 'Zack Snyder',
        ignore_images: 'False',
        imdb_id: '',
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Evolution"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 36%; User: 6.9',
        genre: 'Fantasy, Action, Thriller',
        release_year: '2006',
        notable_actor: 'Kate Beckinsale',
        director: 'Len Wiseman',
        ignore_images: 'False',
        imdb_id: '',
    },
    '111': {
        content: '',
        submitted_by: '',
        answers: ["Minions"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 56%; User: 5.2',
        genre: 'Family, Animation, Adventure, Comedy',
        release_year: '2015',
        notable_actor: 'Sandra Bullock',
        director: 'Kyle Balda, Pierre Coffin',
        ignore_images: 'False',
        imdb_id: '',
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["The A-Team"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 47%; User: 6.7',
        genre: 'Thriller, Action, Adventure, Comedy, Crime',
        release_year: '2010',
        notable_actor: 'Liam Neeson',
        director: 'Joe Carnahan',
        ignore_images: 'False',
        imdb_id: '',
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["The Princess Diaries 2: Royal Engagement"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 43%; User: 5.8',
        genre: 'Comedy, Drama, Family, Romance',
        release_year: '2004',
        notable_actor: 'Anne Hathaway',
        director: 'Garry Marshall',
        ignore_images: 'False',
        imdb_id: '',
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["District 9"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 81%; User: 8.3',
        genre: 'Science Fiction',
        release_year: '2009',
        notable_actor: 'Sharlto Copley',
        director: 'Neill Blomkamp',
        ignore_images: 'False',
        imdb_id: '',
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["Total Recall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 43%; User: 5.7',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2012',
        notable_actor: 'Colin Farrell',
        director: 'Len Wiseman',
        ignore_images: 'False',
        imdb_id: '',
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["Sin City: A Dame to Kill For", "Sin City"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 46%; User: 6.4',
        genre: 'Crime, Action, Thriller',
        release_year: '2014',
        notable_actor: 'Mickey Rourke',
        director: 'Frank Miller, Robert Rodriguez',
        ignore_images: 'False',
        imdb_id: '',
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["Coraline"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 80%; User: 8.1',
        genre: 'Animation, Family, Fantasy',
        release_year: '2009',
        notable_actor: 'Dakota Fanning',
        director: 'Henry Selick',
        ignore_images: 'False',
        imdb_id: '',
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["Glass Onion: A Knives Out Mystery", "Glass Onion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 81%; User: 6.1',
        genre: 'Comedy, Crime, Mystery',
        release_year: '2022',
        notable_actor: 'Daniel Craig',
        director: 'Rian Johnson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["Fifty Shades of Grey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 46%; User: 3.2',
        genre: 'Drama, Romance, Thriller',
        release_year: '2015',
        notable_actor: 'Dakota Johnson',
        director: 'Sam Taylor-Johnson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["The Prince of Egypt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 64%; User: 8.3',
        genre: 'Adventure, Animation, Drama, Family',
        release_year: '1998',
        notable_actor: 'Val Kilmer',
        director: 'Brenda Chapman, Steve Hickner, Simon Wells',
        ignore_images: 'False',
        imdb_id: '',
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["Ocean's Eleven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 74%; User: 8/0',
        genre: 'Thriller, Crime',
        release_year: '2001',
        notable_actor: 'George Clooney',
        director: 'Steven Soderbergh',
        ignore_images: 'False',
        imdb_id: '',
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Half-Blood Prince"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 7.7',
        genre: 'Adventure, Fantasy',
        release_year: '2009',
        notable_actor: 'Daniel Radcliffe',
        director: 'David Yates',
        ignore_images: 'False',
        imdb_id: '',
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["Kingdom of Heaven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 63%; User: 7.6',
        genre: 'Drama, Action, Adventure, History, War',
        release_year: '2005',
        notable_actor: 'Orlando Bloom',
        director: 'Ridley Scott',
        ignore_images: 'False',
        imdb_id: '',
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["The Social Network"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 95%; User: 8.2',
        genre: 'Drama',
        release_year: '2010',
        notable_actor: 'Jesse Eisenberg',
        director: 'David Fincher',
        ignore_images: 'False',
        imdb_id: '',
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["Elysium"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 61%; User: 6.7',
        genre: 'Science Fiction, Action, Drama, Thriller',
        release_year: '2013',
        notable_actor: 'Matt Damon',
        director: 'Neill Blomkamp',
        ignore_images: 'False',
        imdb_id: '',
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["Batman Returns"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 7.6',
        genre: 'Action, Fantasy',
        release_year: '1992',
        notable_actor: 'Michael Keaton',
        director: 'Tim Burton',
        ignore_images: 'False',
        imdb_id: '',
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["The Magnificent Seven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 74%; User: 7.3',
        genre: 'Western, Action, Adventure',
        release_year: '1960',
        notable_actor: 'Yul Brynner',
        director: 'John Sturges',
        ignore_images: 'False',
        imdb_id: '',
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["Diary of a Wimpy Kid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 50%; User: 5.4',
        genre: 'Animation, Comedy, Family',
        release_year: '2021',
        notable_actor: 'Cyrus Arnold',
        director: 'Gino Nichele, Swinton O. Scott III',
        ignore_images: 'False',
        imdb_id: '',
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["Akira"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 8.4',
        genre: 'Animation, Science Fiction, Action',
        release_year: '1988',
        notable_actor: 'Mitsuo Iwata',
        director: 'Katsuhiro Ôtomo',
        ignore_images: 'False',
        imdb_id: '',
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["King Arthur: Legend of the Sword"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 41%; User: 7.2',
        genre: 'Action, Drama, Fantasy',
        release_year: '2017',
        notable_actor: 'Charlief Hunnam',
        director: 'Guy Ritchie',
        ignore_images: 'False',
        imdb_id: '',
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["The Prestige"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 66%; User: 8.7',
        genre: 'Drama, Mystery, Science Fiction',
        release_year: '2006',
        notable_actor: 'Christian Bale',
        director: 'Christopher Nolan',
        ignore_images: 'False',
        imdb_id: '',
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["Meet the Robinsons"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 61%; User: 6.8',
        genre: 'Animation, Comedy, Family',
        release_year: '2007',
        notable_actor: 'Daniel Hansen',
        director: 'Stephen J. Anderson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["Bird Box"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 51%; User: 5.9',
        genre: 'Thriller, Drama, Science Fiction',
        release_year: '2018',
        notable_actor: 'Sandra Bullock',
        director: 'Susanne Bier',
        ignore_images: 'False',
        imdb_id: '',
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["The Grand Budapest Hotel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 88%; User: 8.4',
        genre: 'Comedy, Drama',
        release_year: '2014',
        notable_actor: 'Ralph Fiennes',
        director: 'Wes Anderson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["Pretty Woman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 51%; User: 7.3',
        genre: 'Comedy, Romance',
        release_year: '1990',
        notable_actor: 'Richard Gere',
        director: 'Garry Marshall',
        ignore_images: 'False',
        imdb_id: '',
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["Public Enemies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 70%; User: 6.8',
        genre: 'Action, Crime',
        release_year: '2009',
        notable_actor: 'Christian Bale',
        director: 'Michael Mann',
        ignore_images: 'False',
        imdb_id: '',
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["The Wolf of Wall Street"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 75%; User: 8.1',
        genre: 'Crime, Drama, Comedy',
        release_year: '2013',
        notable_actor: 'Leonardo DiCaprio',
        director: 'Martin Scorsese',
        ignore_images: 'False',
        imdb_id: '',
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Chamber of Secrets"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 63%; User: 7.7',
        genre: 'Adventure, Fantasy',
        release_year: '2002',
        notable_actor: 'Daniel Radcliffe',
        director: 'Chris Columbus',
        ignore_images: 'False',
        imdb_id: '',
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["Monsters, Inc."],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 79%; User: 8.6',
        genre: 'Animation, Comedy, Family',
        release_year: '2001',
        notable_actor: 'Billy Crystal',
        director: 'Pete Docter, David Silverman, Lee Unkrich',
        ignore_images: 'False',
        imdb_id: '',
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["Man on Fire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 47%; User: 7.4',
        genre: 'Action, Drama, Thriller',
        release_year: '2004',
        notable_actor: 'Denzel Washington',
        director: 'Tony Scott',
        ignore_images: 'False',
        imdb_id: '',
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["The Evil Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 71%; User: 7.8',
        genre: 'Horror',
        release_year: '1981',
        notable_actor: 'Bruce Campbell',
        director: 'Sam Raimi',
        ignore_images: 'False',
        imdb_id: '',
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Tarzan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 44%; User: 6.0',
        genre: 'Fantasy, Action, Adventure',
        release_year: '2016',
        notable_actor: 'Alexander Skarsgård',
        director: 'David Yates',
        ignore_images: 'False',
        imdb_id: '',
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["Tangled"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 71%; User: 8.1',
        genre: 'Animation, Family',
        release_year: '2010',
        notable_actor: 'Mandy Moore',
        director: 'Nathan Greno, Byron Howard',
        ignore_images: 'False',
        imdb_id: '',
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["Interstellar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 74%; User: 8.7',
        genre: 'Adventure, Drama, Science Fiction',
        release_year: '2014',
        notable_actor: 'Matthew McConaughey',
        director: 'Christopher Nolan',
        ignore_images: 'False',
        imdb_id: '',
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["Airplane!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 7.8',
        genre: 'Comedy',
        release_year: '1980',
        notable_actor: 'Robert Hays',
        director: 'Jim Abrahams, David Zucker, Jerry Zucker',
        ignore_images: 'False',
        imdb_id: '',
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["The Little Mermaid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 88%; User: 7.4',
        genre: 'Animation, Family, Fantasy',
        release_year: '1989',
        notable_actor: 'Jodi Benson',
        director: 'Ron Clements, John Musker',
        ignore_images: 'False',
        imdb_id: '',
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Prisoner of Azkaban"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 82%; User: 8.4',
        genre: 'Adventure, Fantasy',
        release_year: '2004',
        notable_actor: 'Daniel Radcliffe',
        director: 'Alfonso Cuarón',
        ignore_images: 'False',
        imdb_id: '',
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["Prisoners"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 70%; User: 8.2',
        genre: 'Drama, Thriller, Crime',
        release_year: '2013',
        notable_actor: 'Hugh Jackman',
        director: 'Denis Villeneuve',
        ignore_images: 'False',
        imdb_id: '',
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["Nope"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 77%; User: 6.2',
        genre: 'Horror, Mystery, Science Fiction',
        release_year: '2022',
        notable_actor: 'Daniel Kaluuya',
        director: 'Jordan Peele',
        ignore_images: 'False',
        imdb_id: '',
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["Monstrous"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 52%; User: 5.0',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2022',
        notable_actor: 'Christina Ricci',
        director: 'Chris Sivertson',
        ignore_images: 'False',
        imdb_id: '',
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["On the Line"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 34%; User: 5.5',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: 'Lance Bass',
        director: 'Eric Bross',
        ignore_images: 'False',
        imdb_id: '',
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["National Lampoon's Vacation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 55%; User: 7.7',
        genre: 'Comedy, Adventure',
        release_year: '1983',
        notable_actor: 'Chevy Chase',
        director: 'Harold Ramis',
        ignore_images: 'False',
        imdb_id: '',
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["Sicario: Day of the Soldado", "Sicario"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 61%; User: 7.0',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2018',
        notable_actor: 'Benicio Del Toro',
        director: 'Stefano Sollima',
        ignore_images: 'False',
        imdb_id: '',
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["Bruce Almighty"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 46%; User: 7.1',
        genre: 'Fantasy, Comedy',
        release_year: '2003',
        notable_actor: 'Jim Carrey',
        director: 'Tom Shadyac',
        ignore_images: 'False',
        imdb_id: '',
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["Eyes Wide Shut"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 69%; User: 8.0',
        genre: 'Drama, Thriller, Mystery',
        release_year: '1999',
        notable_actor: 'Tom Cruise',
        director: 'Stanley Kubrick',
        ignore_images: 'False',
        imdb_id: '',
    },
    '156': {
        content: 'With his reputation in tatters after the painfully unsuccessful delivery of a distinguished Japanese client, the former triple-A protection agent, Michael Bryce, is now reduced to a mere second-class bodyguard for hire, two years after the disgraceful incident. Under those circumstances, Bryce would do anything to prove his worth, and, before long, he accepts an offer from Interpol to escort the international assassin, Darius Kincaid, from Manchester to the Hague. The task seems simple: Bryce needs to transport him from point A to point B; nevertheless, the trip to the Netherlands is long and hazardous, and Kincaid--as the only one with the guts and enough hard evidence to testify against a tyrannical Belarusian dictator--is an obvious target. Undoubtedly, it\'s a tough job, as the mismatched duo will have to put aside their grudges, and race against the clock in a non-stop concerto for bullets. Can the hitman\'s bodyguard carry through the most important mission in his career?',
        submitted_by: '',
        answers: ["The Hitman's Bodyguard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '47% Metacritic; 6.9 IMDb',
        genre: 'Action, Comedy',
        release_year: '2017',
        notable_actor: 'Ryan Reynolds',
        director: 'Patrick Hughes',
        ignore_images: 'False',
        imdb_id: 'tt1959563',
    },
    '157': {
        content: 'In Canada, a writer visits the Indian storyteller Pi Patel and asks him to tell his life story. Pi tells the story of his childhood in Pondicherry, India, and the origin of his nickname. One day, his father, a zoo owner, explains that the municipality is no longer supporting the zoo and he has hence decided to move to Canada, where the animals the family owns would also be sold. They board on a Japanese cargo ship with the animals and out of the blue, there is a storm, followed by a shipwrecking. Pi survives in a lifeboat with a zebra, an orangutan, a hyena and a male Bengal tiger nicknamed Richard Parker. They are adrift in the Pacific Ocean, with aggressive hyena and Richard Parker getting hungry. Pi needs to find a way to survive.',
        submitted_by: '',
        answers: ["Life of Pi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '79% Metacritic; 7.9 IMDb',
        genre: 'Adventure, Drama',
        release_year: '2012',
        notable_actor: 'Suraj Sharma',
        director: 'Ang Lee',
        ignore_images: 'False',
        imdb_id: 'tt0454876',
    },
    '158': {
        content: 'Years after Adonis Creed made a name for himself under Rocky Balboa\'s mentorship, the young boxer becomes the Heavyweight Champion of the World. While life is good with that victory and his marriage to Bianca, trouble comes to Philadelphia when Ivan Drago, the Russian boxer who killed Adonis\' father, Apollo, arrives with his son, Viktor, to challenge Adonis. Against Rocky\'s advice, Adonis accepts the challenge without his mentor\'s participation and pays the price in a punishing bout he wins only by a technicality. Now injured and demoralized, Adonis cannot bring himself to back into the game, leaving his spirit and title in jeopardy. Together, Adonis\' family and Rocky must find a way to rekindle Adonis\' fighting spirit to face the future in whatever choice he makes. Meanwhile, the Drago family have its own troubles trying to regain the respect in their homeland that they lost at Rocky\'s hands as they wonder whether is it truly worth it.',
        submitted_by: '',
        answers: ["Creed II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '66% Metacritic; 7.1 IMDb',
        genre: 'Drama',
        release_year: '2018',
        notable_actor: 'Michael B. Jordan',
        director: 'Steven Caple Jr.',
        ignore_images: 'False',
        imdb_id: 'tt6343314',
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["The Northman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Northman, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["Guardians of the Galaxy Vol. 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 67%; User: 7.8',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2017',
        notable_actor: 'Chris Pratt',
        director: 'James Gunn',
        ignore_images: 'False',
        imdb_id: '',
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Comedy',
        release_year: '1995',
        notable_actor: '',
        director: 'Toy Story, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["Sinister"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2012',
        notable_actor: '',
        director: 'Sinister, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["King Kong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy',
        release_year: '1976',
        notable_actor: '',
        director: 'King Kong, 1976 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["Babylon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Babylon, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["Luca"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy, Adventure, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Luca, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["Halloween"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Halloween, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["A Clockwork Orange"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Science Fiction, Drama',
        release_year: '1971',
        notable_actor: 'tbd',
        director: 'A Clockwork Orange, 1971 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["The Da Vinci Code"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Thriller, Mystery',
        release_year: '2006',
        notable_actor: 'bsd',
        director: 'The Da Vinci Code, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["Ella Enchanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Comedy',
        release_year: '2004',
        notable_actor: '',
        director: 'Ella Enchanted, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["The Suicide Squad"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'The Suicide Squad, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["Charlie's Angels: Full Throttle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy',
        release_year: '2003',
        notable_actor: '',
        director: 'Charlie\'s Angels: Full Throttle, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games: Mockingjay - Part 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Hunger Games: Mockingjay - Part 1, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["Morbius"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Morbius, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Drama',
        release_year: '1994',
        notable_actor: '',
        director: 'The Lion King, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["No Country for Old Men"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'dsf',
        genre: 'Crime, Drama, Thriller',
        release_year: '2007',
        notable_actor: 'sdf',
        director: 'No Country for Old Men, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["Shrek 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy, Adventure, Romance',
        release_year: '2004',
        notable_actor: '',
        director: 'Shrek 2, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '177': {
        content: '',
        submitted_by: '',
        answers: ["The Tomorrow War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'The Tomorrow War, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '178': {
        content: '',
        submitted_by: '',
        answers: ["Ratatouille"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2007',
        notable_actor: '',
        director: 'Ratatouille, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '179': {
        content: '',
        submitted_by: '',
        answers: ["The Godfather"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1972',
        notable_actor: '',
        director: 'The Godfather, 1972 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '180': {
        content: '',
        submitted_by: '',
        answers: ["The Prot\u00e9g\u00e9"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Protégé, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '181': {
        content: '',
        submitted_by: '',
        answers: ["Coco"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Music, Comedy, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Coco, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '182': {
        content: '',
        submitted_by: '',
        answers: ["Justice League: Doom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Animation, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Justice League: Doom, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '183': {
        content: '',
        submitted_by: '',
        answers: ["Tomb Raider"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2018',
        notable_actor: 'tbd',
        director: 'Tomb Raider, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '184': {
        content: '',
        submitted_by: '',
        answers: ["Eternals"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Eternals, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '185': {
        content: '',
        submitted_by: '',
        answers: ["Avengers: Endgame"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Avengers: Endgame, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '186': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: The Curse of the Black Pearl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2003',
        notable_actor: '',
        director: 'Pirates of the Caribbean: The Curse of the Black Pearl, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '187': {
        content: '',
        submitted_by: '',
        answers: ["Enola Holmes 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Mystery, Comedy, Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'Enola Holmes 2, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '188': {
        content: '',
        submitted_by: '',
        answers: ["Mean Girls"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2004',
        notable_actor: '',
        director: 'Mean Girls, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '189': {
        content: '',
        submitted_by: '',
        answers: ["The Croods: A New Age"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy, Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'The Croods: A New Age, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '190': {
        content: '',
        submitted_by: '',
        answers: ["The Conjuring: The Devil Made Me Do It"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Conjuring: The Devil Made Me Do It, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '191': {
        content: '',
        submitted_by: '',
        answers: ["Tetris"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, History, Drama',
        release_year: '2023',
        notable_actor: '',
        director: 'Tetris, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '192': {
        content: '',
        submitted_by: '',
        answers: ["Resident Evil: Welcome to Raccoon City"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Resident Evil: Welcome to Raccoon City, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '193': {
        content: '',
        submitted_by: '',
        answers: ["E.T. the Extra-Terrestrial"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Family, Fantasy',
        release_year: '1982',
        notable_actor: '',
        director: 'E.T. the Extra-Terrestrial, 1982 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '194': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: On Stranger Tides"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2011',
        notable_actor: '',
        director: 'Pirates of the Caribbean: On Stranger Tides, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '195': {
        content: '',
        submitted_by: '',
        answers: ["Pain & Gain"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'Pain & Gain, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '196': {
        content: '',
        submitted_by: '',
        answers: ["Three Steps Above Heaven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2010',
        notable_actor: '',
        director: 'Three Steps Above Heaven, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '197': {
        content: '',
        submitted_by: '',
        answers: ["The Maze Runner"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Science Fiction, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Maze Runner, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '198': {
        content: '',
        submitted_by: '',
        answers: ["Goosebumps"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Horror, Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Goosebumps, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '199': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1977',
        notable_actor: '',
        director: 'Star Wars, 1977 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '200': {
        content: '',
        submitted_by: '',
        answers: ["Deadpool"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'Deadpool, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '201': {
        content: '',
        submitted_by: '',
        answers: ["Big Hero 6"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Animation, Action, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Big Hero 6, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '202': {
        content: '',
        submitted_by: '',
        answers: ["Me Before You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2016',
        notable_actor: '',
        director: 'Me Before You, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '203': {
        content: '',
        submitted_by: '',
        answers: ["How to Train Your Dragon: The Hidden World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'How to Train Your Dragon: The Hidden World, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '204': {
        content: '',
        submitted_by: '',
        answers: ["Deep Impact"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Science Fiction',
        release_year: '1998',
        notable_actor: '',
        director: 'Deep Impact, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '205': {
        content: '',
        submitted_by: '',
        answers: ["The Amazing Spider-Man 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Amazing Spider-Man 2, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '206': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Boots: The Three Diablos"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2012',
        notable_actor: '',
        director: 'Puss in Boots: The Three Diablos, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '207': {
        content: '',
        submitted_by: '',
        answers: ["Peninsula"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Adventure, Action, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Peninsula, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '208': {
        content: '',
        submitted_by: '',
        answers: ["The Sea Beast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Sea Beast, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '209': {
        content: '',
        submitted_by: '',
        answers: ["Jack Reacher"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'Jack Reacher, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '210': {
        content: '',
        submitted_by: '',
        answers: ["Shazam!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Shazam!, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '211': {
        content: '',
        submitted_by: '',
        answers: ["Gabriel's Inferno: Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance',
        release_year: '2020',
        notable_actor: '',
        director: 'Gabriel\'s Inferno: Part II, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '212': {
        content: '',
        submitted_by: '',
        answers: ["The Princess and the Frog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Family, Animation',
        release_year: '2009',
        notable_actor: '',
        director: 'The Princess and the Frog, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '213': {
        content: '',
        submitted_by: '',
        answers: ["Real Steel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Drama',
        release_year: '2011',
        notable_actor: '',
        director: 'Real Steel, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '214': {
        content: '',
        submitted_by: '',
        answers: ["After We Collided"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'After We Collided, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '215': {
        content: '',
        submitted_by: '',
        answers: ["The Matrix Resurrections"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'The Matrix Resurrections, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '216': {
        content: '',
        submitted_by: '',
        answers: ["The Pale Blue Eye"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'The Pale Blue Eye, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '217': {
        content: '',
        submitted_by: '',
        answers: ["The Equalizer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Crime',
        release_year: '2014',
        notable_actor: '',
        director: 'The Equalizer, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '218': {
        content: '',
        submitted_by: '',
        answers: ["Iron Man 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'Iron Man 2, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '219': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man: Into the Spider-Verse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Spider-Man: Into the Spider-Verse, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '220': {
        content: '',
        submitted_by: '',
        answers: ["Free Guy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Free Guy, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '221': {
        content: '',
        submitted_by: '',
        answers: ["Triangle of Sadness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Triangle of Sadness, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '222': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '2002',
        notable_actor: '',
        director: 'Ice Age, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '223': {
        content: '',
        submitted_by: '',
        answers: ["Red Notice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Red Notice, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '224': {
        content: '',
        submitted_by: '',
        answers: ["San Andreas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'San Andreas, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '225': {
        content: '',
        submitted_by: '',
        answers: ["The Conjuring"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'The Conjuring, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '226': {
        content: '',
        submitted_by: '',
        answers: ["A Dog's Purpose"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Fantasy, Family, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'A Dog\'s Purpose, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '227': {
        content: '',
        submitted_by: '',
        answers: ["I, Robot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction',
        release_year: '2004',
        notable_actor: '',
        director: 'I, Robot, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '228': {
        content: '',
        submitted_by: '',
        answers: ["Pearl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Pearl, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '229': {
        content: '',
        submitted_by: '',
        answers: ["Deadpool 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Deadpool 2, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '230': {
        content: '',
        submitted_by: '',
        answers: ["Air"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2023',
        notable_actor: '',
        director: 'Air, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '231': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: At World's End"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2007',
        notable_actor: '',
        director: 'Pirates of the Caribbean: At World\'s End, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '232': {
        content: '',
        submitted_by: '',
        answers: ["Boyka: Undisputed IV"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'Boyka: Undisputed IV, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '233': {
        content: '',
        submitted_by: '',
        answers: ["Last Seen Alive"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Last Seen Alive, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '234': {
        content: '',
        submitted_by: '',
        answers: ["Avengers: Age of Ultron"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Avengers: Age of Ultron, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '235': {
        content: '',
        submitted_by: '',
        answers: ["Wrath of Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Wrath of Man, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '236': {
        content: '',
        submitted_by: '',
        answers: ["The Interview"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Interview, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '237': {
        content: '',
        submitted_by: '',
        answers: ["The Bad Guys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Crime, Comedy, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'The Bad Guys, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '238': {
        content: '',
        submitted_by: '',
        answers: ["The King's Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller, War, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'The King\'s Man, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '239': {
        content: '',
        submitted_by: '',
        answers: ["Cars 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family',
        release_year: '2017',
        notable_actor: '',
        director: 'Cars 3, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '240': {
        content: '',
        submitted_by: '',
        answers: ["Clifford the Big Red Dog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Comedy, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Clifford the Big Red Dog, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '241': {
        content: '',
        submitted_by: '',
        answers: ["Grimcutty"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Grimcutty, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '242': {
        content: '',
        submitted_by: '',
        answers: ["A Quiet Place"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'A Quiet Place, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '243': {
        content: '',
        submitted_by: '',
        answers: ["Hidden Figures"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2016',
        notable_actor: '',
        director: 'Hidden Figures, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '244': {
        content: '',
        submitted_by: '',
        answers: ["Transformers: The Last Knight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Transformers: The Last Knight, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '245': {
        content: '',
        submitted_by: '',
        answers: ["Magic Mike's Last Dance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2023',
        notable_actor: '',
        director: 'Magic Mike\'s Last Dance, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '246': {
        content: '',
        submitted_by: '',
        answers: ["You People"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2023',
        notable_actor: '',
        director: 'You People, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '247': {
        content: '',
        submitted_by: '',
        answers: ["Slumberland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Slumberland, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '248': {
        content: '',
        submitted_by: '',
        answers: ["Fury"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Drama, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'Fury, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '249': {
        content: '',
        submitted_by: '',
        answers: ["A Walk in the Clouds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '1995',
        notable_actor: '',
        director: 'A Walk in the Clouds, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '250': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action',
        release_year: '2002',
        notable_actor: '',
        director: 'Spider-Man, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '251': {
        content: '',
        submitted_by: '',
        answers: ["Grown Ups 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'Grown Ups 2, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '252': {
        content: '',
        submitted_by: '',
        answers: ["A Bronx Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1993',
        notable_actor: '',
        director: 'A Bronx Tale, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '253': {
        content: '',
        submitted_by: '',
        answers: ["The Amazing Spider-Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'The Amazing Spider-Man, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '254': {
        content: '',
        submitted_by: '',
        answers: ["The Angry Birds Movie 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Adventure, Family',
        release_year: '2019',
        notable_actor: '',
        director: 'The Angry Birds Movie 2, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '255': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man: Homecoming"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'Spider-Man: Homecoming, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '256': {
        content: '',
        submitted_by: '',
        answers: ["The Platform"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'The Platform, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '257': {
        content: '',
        submitted_by: '',
        answers: ["Hitman: Agent 47"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Hitman: Agent 47, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '258': {
        content: '',
        submitted_by: '',
        answers: ["Spirited Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy',
        release_year: '2001',
        notable_actor: '',
        director: 'Spirited Away, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '259': {
        content: '',
        submitted_by: '',
        answers: ["Monster High: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Comedy, Adventure, Music, TV Movie',
        release_year: '2022',
        notable_actor: '',
        director: 'Monster High: The Movie, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '260': {
        content: '',
        submitted_by: '',
        answers: ["Apocalypse Now"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '1979',
        notable_actor: '',
        director: 'Apocalypse Now, 1979 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '261': {
        content: '',
        submitted_by: '',
        answers: ["The Gray Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Gray Man, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '262': {
        content: '',
        submitted_by: '',
        answers: ["Everything Everywhere All at Once"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Everything Everywhere All at Once, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '263': {
        content: '',
        submitted_by: '',
        answers: ["As Above, So Below"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'As Above, So Below, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '264': {
        content: '',
        submitted_by: '',
        answers: ["Don't Hang Up"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Don\'t Hang Up, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '265': {
        content: '',
        submitted_by: '',
        answers: ["Lou"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Lou, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '266': {
        content: '',
        submitted_by: '',
        answers: ["Mulan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure',
        release_year: '1998',
        notable_actor: '',
        director: 'Mulan, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '267': {
        content: '',
        submitted_by: '',
        answers: ["The Shawshank Redemption"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1994',
        notable_actor: '',
        director: 'The Shawshank Redemption, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '268': {
        content: '',
        submitted_by: '',
        answers: ["Ri\u00a2hie Ri\u00a2h"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family',
        release_year: '1994',
        notable_actor: '',
        director: 'Ri¢hie Ri¢h, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '269': {
        content: '',
        submitted_by: '',
        answers: ["Cars 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'Cars 2, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '270': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story 4"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Toy Story 4, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '271': {
        content: '',
        submitted_by: '',
        answers: ["Sonic the Hedgehog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Comedy, Family',
        release_year: '2020',
        notable_actor: '',
        director: 'Sonic the Hedgehog, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '272': {
        content: '',
        submitted_by: '',
        answers: ["PAW Patrol: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'PAW Patrol: The Movie, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '273': {
        content: '',
        submitted_by: '',
        answers: ["Shrek Forever After"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Fantasy, Animation, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'Shrek Forever After, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '274': {
        content: '',
        submitted_by: '',
        answers: ["Good Will Hunting"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1997',
        notable_actor: '',
        director: 'Good Will Hunting, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '275': {
        content: '',
        submitted_by: '',
        answers: ["Geostorm"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Geostorm, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '276': {
        content: '',
        submitted_by: '',
        answers: ["Frozen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'Frozen, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '277': {
        content: '',
        submitted_by: '',
        answers: ["Kung Fu Panda"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Family, Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Kung Fu Panda, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '278': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '1999',
        notable_actor: '',
        director: 'Toy Story 2, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '279': {
        content: '',
        submitted_by: '',
        answers: ["The Lego Batman Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy, Family',
        release_year: '2017',
        notable_actor: '',
        director: 'The Lego Batman Movie, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '280': {
        content: '',
        submitted_by: '',
        answers: ["The Nightmare Before Christmas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Family',
        release_year: '1993',
        notable_actor: '',
        director: 'The Nightmare Before Christmas, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '281': {
        content: '',
        submitted_by: '',
        answers: ["Once Upon a Time in America"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1984',
        notable_actor: '',
        director: 'Once Upon a Time in America, 1984 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '282': {
        content: '',
        submitted_by: '',
        answers: ["Ghostbusters: Afterlife"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Ghostbusters: Afterlife, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '283': {
        content: '',
        submitted_by: '',
        answers: ["Shrek the Third"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Animation, Comedy, Family',
        release_year: '2007',
        notable_actor: '',
        director: 'Shrek the Third, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '284': {
        content: '',
        submitted_by: '',
        answers: ["The Hobbit: The Battle of the Five Armies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Hobbit: The Battle of the Five Armies, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '285': {
        content: '',
        submitted_by: '',
        answers: ["Halloween Ends"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Halloween Ends, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '286': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: Dead Man's Chest"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2006',
        notable_actor: '',
        director: 'Pirates of the Caribbean: Dead Man\'s Chest, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '287': {
        content: '',
        submitted_by: '',
        answers: ["Blacklight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Blacklight, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '288': {
        content: '',
        submitted_by: '',
        answers: ["Black Widow"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Black Widow, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '289': {
        content: '',
        submitted_by: '',
        answers: ["Brothers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, War',
        release_year: '2009',
        notable_actor: '',
        director: 'Brothers, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '290': {
        content: '',
        submitted_by: '',
        answers: ["Up"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '2009',
        notable_actor: '',
        director: 'Up, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '291': {
        content: '',
        submitted_by: '',
        answers: ["The Parent Trap"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family',
        release_year: '1998',
        notable_actor: '',
        director: 'The Parent Trap, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '292': {
        content: '',
        submitted_by: '',
        answers: ["American Sniper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'American Sniper, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '293': {
        content: '',
        submitted_by: '',
        answers: ["Ghost in the Shell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Science Fiction, Drama, Action',
        release_year: '2017',
        notable_actor: 'tbd',
        director: 'Ghost in the Shell, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '294': {
        content: '',
        submitted_by: '',
        answers: ["Frozen II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Frozen II, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '295': {
        content: '',
        submitted_by: '',
        answers: ["Watcher"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller, Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Watcher, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '296': {
        content: '',
        submitted_by: '',
        answers: ["Thor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Thor, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '297': {
        content: '',
        submitted_by: '',
        answers: ["Howl's Moving Castle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Adventure',
        release_year: '2004',
        notable_actor: '',
        director: 'Howl\'s Moving Castle, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '298': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games: Catching Fire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'The Hunger Games: Catching Fire, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '299': {
        content: '',
        submitted_by: '',
        answers: ["The Silence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Thriller, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'The Silence, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '300': {
        content: '',
        submitted_by: '',
        answers: ["The Requin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'The Requin, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '301': {
        content: '',
        submitted_by: '',
        answers: ["The Unbearable Weight of Massive Talent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'The Unbearable Weight of Massive Talent, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '302': {
        content: '',
        submitted_by: '',
        answers: ["Thor: Ragnarok"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy, Science Fiction, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Thor: Ragnarok, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '303': {
        content: '',
        submitted_by: '',
        answers: ["Space Jam: A New Legacy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Space Jam: A New Legacy, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '304': {
        content: '',
        submitted_by: '',
        answers: ["Batman: Death in the Family"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Batman: Death in the Family, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '305': {
        content: '',
        submitted_by: '',
        answers: ["Finding Nemo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2003',
        notable_actor: '',
        director: 'Finding Nemo, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '306': {
        content: '',
        submitted_by: '',
        answers: ["The Meg"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Horror',
        release_year: '2018',
        notable_actor: '',
        director: 'The Meg, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '307': {
        content: '',
        submitted_by: '',
        answers: ["The Exorcist"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1973',
        notable_actor: '',
        director: 'The Exorcist, 1973 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '308': {
        content: '',
        submitted_by: '',
        answers: ["Nobody"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Nobody, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '309': {
        content: '',
        submitted_by: '',
        answers: ["Grown Ups"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2010',
        notable_actor: '',
        director: 'Grown Ups, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '310': {
        content: '',
        submitted_by: '',
        answers: ["The Hangover"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2009',
        notable_actor: '',
        director: 'The Hangover, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '311': {
        content: '',
        submitted_by: '',
        answers: ["Bloodshot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2020',
        notable_actor: '',
        director: 'Bloodshot, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '312': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2000',
        notable_actor: '',
        director: 'Scary Movie, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '313': {
        content: '',
        submitted_by: '',
        answers: ["The Unholy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'The Unholy, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '314': {
        content: '',
        submitted_by: '',
        answers: ["Day Shift"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Horror, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Day Shift, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '315': {
        content: '',
        submitted_by: '',
        answers: ["Ready Player One"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Ready Player One, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '316': {
        content: '',
        submitted_by: '',
        answers: ["La La Land"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Comedy, Drama, Romance, Music',
        release_year: '2016',
        notable_actor: 'tbd',
        director: 'La La Land, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '317': {
        content: '',
        submitted_by: '',
        answers: ["Get Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller, Horror',
        release_year: '2017',
        notable_actor: '',
        director: 'Get Out, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '318': {
        content: '',
        submitted_by: '',
        answers: ["Monsters University"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2013',
        notable_actor: '',
        director: 'Monsters University, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '319': {
        content: '',
        submitted_by: '',
        answers: ["Jungle Cruise"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Jungle Cruise, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '320': {
        content: '',
        submitted_by: '',
        answers: ["The Menu"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Menu, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '321': {
        content: '',
        submitted_by: '',
        answers: ["Pinocchio"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'Pinocchio, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '322': {
        content: '',
        submitted_by: '',
        answers: ["World War Z"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Horror, Science Fiction, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'World War Z, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '323': {
        content: '',
        submitted_by: '',
        answers: ["The Princess"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action',
        release_year: '2022',
        notable_actor: '',
        director: 'The Princess, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '324': {
        content: '',
        submitted_by: '',
        answers: ["Pacific Rim"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2013',
        notable_actor: '',
        director: 'Pacific Rim, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '325': {
        content: '',
        submitted_by: '',
        answers: ["Kingsman: The Secret Service"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Comedy, Action, Adventure',
        release_year: '2014',
        notable_actor: '',
        director: 'Kingsman: The Secret Service, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '326': {
        content: '',
        submitted_by: '',
        answers: ["Piranha 3D"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2010',
        notable_actor: '',
        director: 'Piranha 3D, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '327': {
        content: '',
        submitted_by: '',
        answers: ["Scream 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2000',
        notable_actor: '',
        director: 'Scream 3, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '328': {
        content: '',
        submitted_by: '',
        answers: ["The Wind Rises"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Animation, War, Romance',
        release_year: '2013',
        notable_actor: '',
        director: 'The Wind Rises, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '329': {
        content: '',
        submitted_by: '',
        answers: ["Decision to Leave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Mystery, Romance, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Decision to Leave, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '330': {
        content: '',
        submitted_by: '',
        answers: ["Aquaman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'Aquaman, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '331': {
        content: '',
        submitted_by: '',
        answers: ["Inside Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Drama, Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Inside Out, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '332': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: Eclipse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'The Twilight Saga: Eclipse, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '333': {
        content: '',
        submitted_by: '',
        answers: ["Finch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Finch, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '334': {
        content: '',
        submitted_by: '',
        answers: ["Shadow in the Cloud"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, War',
        release_year: '2020',
        notable_actor: '',
        director: 'Shadow in the Cloud, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '335': {
        content: '',
        submitted_by: '',
        answers: ["Mulan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Mulan, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '336': {
        content: '',
        submitted_by: '',
        answers: ["The Adam Project"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'The Adam Project, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '337': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla: King of the Monsters"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Godzilla: King of the Monsters, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '338': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Family, Animation',
        release_year: '2019',
        notable_actor: '',
        director: 'The Lion King, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '339': {
        content: '',
        submitted_by: '',
        answers: ["Batman v Superman: Dawn of Justice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Batman v Superman: Dawn of Justice, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '340': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Book: Trapped in an Epic Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Animation, Comedy, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Puss in Book: Trapped in an Epic Tale, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '341': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Fellowship of the Ring"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2001',
        notable_actor: '',
        director: 'The Lord of the Rings: The Fellowship of the Ring, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '342': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Animation, Romance',
        release_year: '1950',
        notable_actor: '',
        director: 'Cinderella, 1950 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '343': {
        content: '',
        submitted_by: '',
        answers: ["No Time to Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'No Time to Die, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '344': {
        content: '',
        submitted_by: '',
        answers: ["No Exit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'No Exit, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '345': {
        content: '',
        submitted_by: '',
        answers: ["RED"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Crime, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'RED, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '346': {
        content: '',
        submitted_by: '',
        answers: ["Paws of Fury: The Legend of Hank"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Comedy, Family, Western',
        release_year: '2022',
        notable_actor: '',
        director: 'Paws of Fury: The Legend of Hank, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '347': {
        content: '',
        submitted_by: '',
        answers: ["Scarface"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '1983',
        notable_actor: '',
        director: 'Scarface, 1983 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '348': {
        content: '',
        submitted_by: '',
        answers: ["Infinite"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Infinite, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '349': {
        content: '',
        submitted_by: '',
        answers: ["From Russia with Love"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Adventure',
        release_year: '1963',
        notable_actor: '',
        director: 'From Russia with Love, 1963 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '350': {
        content: '',
        submitted_by: '',
        answers: ["The Goonies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Family',
        release_year: '1985',
        notable_actor: '',
        director: 'The Goonies, 1985 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '351': {
        content: '',
        submitted_by: '',
        answers: ["Wreck-It Ralph"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Wreck-It Ralph, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '352': {
        content: '',
        submitted_by: '',
        answers: ["The SpongeBob Movie: Sponge on the Run"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Adventure, Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'The SpongeBob Movie: Sponge on the Run, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '353': {
        content: '',
        submitted_by: '',
        answers: ["Blended"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2014',
        notable_actor: '',
        director: 'Blended, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '354': {
        content: '',
        submitted_by: '',
        answers: ["The Good Dinosaur"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family',
        release_year: '2015',
        notable_actor: '',
        director: 'The Good Dinosaur, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '355': {
        content: '',
        submitted_by: '',
        answers: ["2012"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2009',
        notable_actor: '',
        director: '2012, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '356': {
        content: '',
        submitted_by: '',
        answers: ["Home on the Range"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'Home on the Range, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '357': {
        content: '',
        submitted_by: '',
        answers: ["Inside Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Inside Man, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '358': {
        content: '',
        submitted_by: '',
        answers: ["Through My Window"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Through My Window, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '359': {
        content: '',
        submitted_by: '',
        answers: ["Separation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Separation, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '360': {
        content: '',
        submitted_by: '',
        answers: ["Inception"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2010',
        notable_actor: '',
        director: 'Inception, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '361': {
        content: '',
        submitted_by: '',
        answers: ["Doctor Strange"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Doctor Strange, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '362': {
        content: '',
        submitted_by: '',
        answers: ["The Conjuring 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'The Conjuring 2, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '363': {
        content: '',
        submitted_by: '',
        answers: ["Charlie and the Chocolate Factory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Family, Fantasy',
        release_year: '2005',
        notable_actor: '',
        director: 'Charlie and the Chocolate Factory, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '364': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Legend of the NeverBeast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Tinker Bell and the Legend of the NeverBeast, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '365': {
        content: '',
        submitted_by: '',
        answers: ["The Nun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'The Nun, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '366': {
        content: '',
        submitted_by: '',
        answers: ["Madagascar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy',
        release_year: '2005',
        notable_actor: '',
        director: 'Madagascar, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '367': {
        content: '',
        submitted_by: '',
        answers: ["Ted 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy',
        release_year: '2015',
        notable_actor: '',
        director: 'Ted 2, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '368': {
        content: '',
        submitted_by: '',
        answers: ["Zootopia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'Zootopia, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '369': {
        content: '',
        submitted_by: '',
        answers: ["The Equalizer 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2018',
        notable_actor: '',
        director: 'The Equalizer 2, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '370': {
        content: '',
        submitted_by: '',
        answers: ["Gunpowder Milkshake"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Gunpowder Milkshake, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '371': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy',
        release_year: '2010',
        notable_actor: '',
        director: 'Toy Story 3, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '372': {
        content: '',
        submitted_by: '',
        answers: ["Annabelle Comes Home"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Annabelle Comes Home, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '373': {
        content: '',
        submitted_by: '',
        answers: ["Terrifier"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Terrifier, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '374': {
        content: '',
        submitted_by: '',
        answers: ["Dawn of the Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Drama, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Dawn of the Planet of the Apes, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '375': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man: Far From Home"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Spider-Man: Far From Home, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '376': {
        content: '',
        submitted_by: '',
        answers: ["The Mummy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '1999',
        notable_actor: '',
        director: 'The Mummy, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '377': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: Dead Men Tell No Tales"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Pirates of the Caribbean: Dead Men Tell No Tales, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '378': {
        content: '',
        submitted_by: '',
        answers: ["Corpse Bride"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Fantasy, Animation',
        release_year: '2005',
        notable_actor: '',
        director: 'Corpse Bride, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '379': {
        content: '',
        submitted_by: '',
        answers: ["The Addams Family 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Fantasy, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'The Addams Family 2, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '380': {
        content: '',
        submitted_by: '',
        answers: ["Brave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Action, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'Brave, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '381': {
        content: '',
        submitted_by: '',
        answers: ["Terminator Genisys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Terminator Genisys, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '382': {
        content: '',
        submitted_by: '',
        answers: ["Neon Genesis Evangelion: The End of Evangelion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Action',
        release_year: '1997',
        notable_actor: '',
        director: 'Neon Genesis Evangelion: The End of Evangelion, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '383': {
        content: '',
        submitted_by: '',
        answers: ["Desire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'Desire, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '384': {
        content: '',
        submitted_by: '',
        answers: ["The Contractor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Contractor, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '385': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: Dawn of the Dinosaurs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '2009',
        notable_actor: '',
        director: 'Ice Age: Dawn of the Dinosaurs, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '386': {
        content: '',
        submitted_by: '',
        answers: ["Your Place or Mine"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2023',
        notable_actor: '',
        director: 'Your Place or Mine, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '387': {
        content: '',
        submitted_by: '',
        answers: ["The Shack"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'The Shack, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '388': {
        content: '',
        submitted_by: '',
        answers: ["El Infierno"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime, Comedy',
        release_year: '2010',
        notable_actor: '',
        director: 'El Infierno, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '389': {
        content: '',
        submitted_by: '',
        answers: ["Captain America: Civil War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Captain America: Civil War, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '390': {
        content: '',
        submitted_by: '',
        answers: ["Space Sweepers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Space Sweepers, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '391': {
        content: '',
        submitted_by: '',
        answers: ["Top Gun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama, Action',
        release_year: '1986',
        notable_actor: '',
        director: 'Top Gun, 1986 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '392': {
        content: '',
        submitted_by: '',
        answers: ["Silverado"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '1985',
        notable_actor: '',
        director: 'Silverado, 1985 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '393': {
        content: '',
        submitted_by: '',
        answers: ["Rampage"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Rampage, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '394': {
        content: '',
        submitted_by: '',
        answers: ["Tom Clancy's Without Remorse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, War',
        release_year: '2021',
        notable_actor: '',
        director: 'Tom Clancy\'s Without Remorse, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '395': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Mutant Ninja Turtles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Teenage Mutant Ninja Turtles, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '396': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Boots"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy, Animation, Romance, Adventure, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Puss in Boots, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '397': {
        content: '',
        submitted_by: '',
        answers: ["Pitch Perfect"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Music, Romance',
        release_year: '2012',
        notable_actor: '',
        director: 'Pitch Perfect, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '398': {
        content: '',
        submitted_by: '',
        answers: ["Ant-Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Ant-Man, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '399': {
        content: '',
        submitted_by: '',
        answers: ["Blade Runner 2049"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'Blade Runner 2049, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '400': {
        content: '',
        submitted_by: '',
        answers: ["Red Corner"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '1997',
        notable_actor: '',
        director: 'Red Corner, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '401': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 2: Dead End"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2007',
        notable_actor: '',
        director: 'Wrong Turn 2: Dead End, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '402': {
        content: '',
        submitted_by: '',
        answers: ["Kung Fu Panda 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Comedy, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Kung Fu Panda 3, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '403': {
        content: '',
        submitted_by: '',
        answers: ["Venom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Venom, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '404': {
        content: '',
        submitted_by: '',
        answers: ["Snow White and the Seven Dwarfs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Family',
        release_year: '1937',
        notable_actor: '',
        director: 'Snow White and the Seven Dwarfs, 1937 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '405': {
        content: '',
        submitted_by: '',
        answers: ["The Green Mile"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Crime',
        release_year: '1999',
        notable_actor: '',
        director: 'The Green Mile, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '406': {
        content: '',
        submitted_by: '',
        answers: ["Sing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Music',
        release_year: '2016',
        notable_actor: '',
        director: 'Sing, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '407': {
        content: '',
        submitted_by: '',
        answers: ["Penguins of Madagascar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Penguins of Madagascar, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '408': {
        content: '',
        submitted_by: '',
        answers: ["The Boy in the Striped Pyjamas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Drama, History',
        release_year: '2008',
        notable_actor: '',
        director: 'The Boy in the Striped Pyjamas, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '409': {
        content: '',
        submitted_by: '',
        answers: ["Apocalypto"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History',
        release_year: '2006',
        notable_actor: '',
        director: 'Apocalypto, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '410': {
        content: '',
        submitted_by: '',
        answers: ["Secret of the Wings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Secret of the Wings, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '411': {
        content: '',
        submitted_by: '',
        answers: ["Source Code"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Science Fiction, Mystery',
        release_year: '2011',
        notable_actor: '',
        director: 'Source Code, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '412': {
        content: '',
        submitted_by: '',
        answers: ["Ant-Man and the Wasp"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Ant-Man and the Wasp, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '413': {
        content: '',
        submitted_by: '',
        answers: ["The Hobbit: An Unexpected Journey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'The Hobbit: An Unexpected Journey, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '414': {
        content: '',
        submitted_by: '',
        answers: ["Soul"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'Soul, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '415': {
        content: '',
        submitted_by: '',
        answers: ["Choose or Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Choose or Die, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '416': {
        content: '',
        submitted_by: '',
        answers: ["Elvis"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Music, History',
        release_year: '2022',
        notable_actor: '',
        director: 'Elvis, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '417': {
        content: '',
        submitted_by: '',
        answers: ["Knowing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Knowing, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '418': {
        content: '',
        submitted_by: '',
        answers: ["Constantine"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Horror',
        release_year: '2005',
        notable_actor: '',
        director: 'Constantine, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '419': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 3: Left for Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Wrong Turn 3: Left for Dead, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '420': {
        content: '',
        submitted_by: '',
        answers: ["Incredibles 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Family',
        release_year: '2018',
        notable_actor: '',
        director: 'Incredibles 2, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '421': {
        content: '',
        submitted_by: '',
        answers: ["The Emperor's New Groove"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family, Fantasy',
        release_year: '2000',
        notable_actor: '',
        director: 'The Emperor\'s New Groove, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '422': {
        content: '',
        submitted_by: '',
        answers: ["How to Train Your Dragon 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure, Animation, Comedy, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'How to Train Your Dragon 2, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '423': {
        content: '',
        submitted_by: '',
        answers: ["Scrooge: A Christmas Carol"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Scrooge: A Christmas Carol, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '424': {
        content: '',
        submitted_by: '',
        answers: ["Jumanji: The Next Level"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Jumanji: The Next Level, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '425': {
        content: '',
        submitted_by: '',
        answers: ["Hercules"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure, Comedy, Romance',
        release_year: '1997',
        notable_actor: '',
        director: 'Hercules, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '426': {
        content: '',
        submitted_by: '',
        answers: ["Jurassic World: Fallen Kingdom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Jurassic World: Fallen Kingdom, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '427': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Fantasy, Family, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Cinderella, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '428': {
        content: '',
        submitted_by: '',
        answers: ["The Strays"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2023',
        notable_actor: '',
        director: 'The Strays, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '429': {
        content: '',
        submitted_by: '',
        answers: ["Miss Peregrine's Home for Peculiar Children"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Family, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'Miss Peregrine\'s Home for Peculiar Children, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '430': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Return of the King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2003',
        notable_actor: '',
        director: 'The Lord of the Rings: The Return of the King, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '431': {
        content: '',
        submitted_by: '',
        answers: ["Tarzan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Drama',
        release_year: '1999',
        notable_actor: '',
        director: 'Tarzan, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '432': {
        content: '',
        submitted_by: '',
        answers: ["Taken 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'Taken 3, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '433': {
        content: '',
        submitted_by: '',
        answers: ["The Incredibles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'The Incredibles, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '434': {
        content: '',
        submitted_by: '',
        answers: ["Luther: The Fallen Sun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2023',
        notable_actor: '',
        director: 'Luther: The Fallen Sun, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '435': {
        content: '',
        submitted_by: '',
        answers: ["Meet the Spartans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Meet the Spartans, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '436': {
        content: '',
        submitted_by: '',
        answers: ["Caligula"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, Romance',
        release_year: '1979',
        notable_actor: '',
        director: 'Caligula, 1979 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '437': {
        content: '',
        submitted_by: '',
        answers: ["Ralph Breaks the Internet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Ralph Breaks the Internet, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '438': {
        content: '',
        submitted_by: '',
        answers: ["Jiu Jitsu"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Jiu Jitsu, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '439': {
        content: '',
        submitted_by: '',
        answers: ["Pulp Fiction"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime',
        release_year: '1994',
        notable_actor: '',
        director: 'Pulp Fiction, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '440': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: The Rise of Skywalker"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Star Wars: The Rise of Skywalker, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '441': {
        content: '',
        submitted_by: '',
        answers: ["Percy Jackson & the Olympians: The Lightning Thief"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'Percy Jackson & the Olympians: The Lightning Thief, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '442': {
        content: '',
        submitted_by: '',
        answers: ["Fight Club"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Comedy',
        release_year: '1999',
        notable_actor: '',
        director: 'Fight Club, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '443': {
        content: '',
        submitted_by: '',
        answers: ["Parasite"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Thriller, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Parasite, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '444': {
        content: '',
        submitted_by: '',
        answers: ["The Gangster, the Cop, the Devil"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'The Gangster, the Cop, the Devil, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '445': {
        content: '',
        submitted_by: '',
        answers: ["War of the Worlds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Thriller, Science Fiction',
        release_year: '2005',
        notable_actor: '',
        director: 'War of the Worlds, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '446': {
        content: '',
        submitted_by: '',
        answers: ["Texas Chainsaw Massacre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Texas Chainsaw Massacre, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '447': {
        content: '',
        submitted_by: '',
        answers: ["Deathstroke: Knights & Dragons - The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Science Fiction',
        release_year: '2020',
        notable_actor: '',
        director: 'Deathstroke: Knights & Dragons - The Movie, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '448': {
        content: '',
        submitted_by: '',
        answers: ["Beau Is Afraid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Fantasy',
        release_year: '2023',
        notable_actor: '',
        director: 'Beau Is Afraid, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '449': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: Breaking Dawn - Part 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Romance',
        release_year: '2012',
        notable_actor: '',
        director: 'The Twilight Saga: Breaking Dawn - Part 2, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '450': {
        content: '',
        submitted_by: '',
        answers: ["Kingsman: The Golden Circle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Kingsman: The Golden Circle, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '451': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Pirate Fairy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Tinker Bell and the Pirate Fairy, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '452': {
        content: '',
        submitted_by: '',
        answers: ["Justice League"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Justice League, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '453': {
        content: '',
        submitted_by: '',
        answers: ["It Chapter Two"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'It Chapter Two, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '454': {
        content: '',
        submitted_by: '',
        answers: ["The Dark Knight Rises"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'The Dark Knight Rises, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '455': {
        content: '',
        submitted_by: '',
        answers: ["Yu-Gi-Oh! The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Action, Science Fiction',
        release_year: '2004',
        notable_actor: '',
        director: 'Yu-Gi-Oh! The Movie, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '456': {
        content: '',
        submitted_by: '',
        answers: ["Roald Dahl's The Witches"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Comedy, Family, Horror',
        release_year: '2020',
        notable_actor: '',
        director: 'Roald Dahl\'s The Witches, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '457': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 5"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'Scary Movie 5, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '458': {
        content: '',
        submitted_by: '',
        answers: ["Ambulance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action',
        release_year: '2022',
        notable_actor: '',
        director: 'Ambulance, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '459': {
        content: '',
        submitted_by: '',
        answers: ["Life in a Year"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2020',
        notable_actor: '',
        director: 'Life in a Year, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '460': {
        content: '',
        submitted_by: '',
        answers: ["The Basketball Diaries"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1995',
        notable_actor: '',
        director: 'The Basketball Diaries, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '461': {
        content: '',
        submitted_by: '',
        answers: ["WALL\u00b7E"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Science Fiction',
        release_year: '2008',
        notable_actor: '',
        director: 'WALL·E, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '462': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: The Last Jedi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Star Wars: The Last Jedi, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '463': {
        content: '',
        submitted_by: '',
        answers: ["The Forever Purge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Forever Purge, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '464': {
        content: '',
        submitted_by: '',
        answers: ["The Addams Family"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy',
        release_year: '1991',
        notable_actor: '',
        director: 'The Addams Family, 1991 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '465': {
        content: '',
        submitted_by: '',
        answers: ["Back to the Future"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Science Fiction',
        release_year: '1985',
        notable_actor: '',
        director: 'Back to the Future, 1985 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '466': {
        content: '',
        submitted_by: '',
        answers: ["Madagascar: Escape 2 Africa"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Madagascar: Escape 2 Africa, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '467': {
        content: '',
        submitted_by: '',
        answers: ["The Wandering Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'The Wandering Earth, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '468': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games: Mockingjay - Part 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'The Hunger Games: Mockingjay - Part 2, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '469': {
        content: '',
        submitted_by: '',
        answers: ["Men"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Men, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '470': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: The Force Awakens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction, Fantasy',
        release_year: '2015',
        notable_actor: '',
        director: 'Star Wars: The Force Awakens, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '471': {
        content: '',
        submitted_by: '',
        answers: ["Boruto: Naruto the Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Boruto: Naruto the Movie, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '472': {
        content: '',
        submitted_by: '',
        answers: ["Don't Breathe 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Don\'t Breathe 2, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '473': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2007',
        notable_actor: '',
        director: 'The Simpsons Movie, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '474': {
        content: '',
        submitted_by: '',
        answers: ["Hellboy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Fantasy, Action',
        release_year: '2004',
        notable_actor: 'tbd',
        director: 'Hellboy, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '475': {
        content: '',
        submitted_by: '',
        answers: ["Thor: The Dark World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'Thor: The Dark World, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '476': {
        content: '',
        submitted_by: '',
        answers: ["The Autopsy of Jane Doe"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'The Autopsy of Jane Doe, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '477': {
        content: '',
        submitted_by: '',
        answers: ["Maleficent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Action, Family, Romance',
        release_year: '2014',
        notable_actor: '',
        director: 'Maleficent, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '478': {
        content: '',
        submitted_by: '',
        answers: ["The Devil Wears Prada"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2006',
        notable_actor: '',
        director: 'The Devil Wears Prada, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '479': {
        content: '',
        submitted_by: '',
        answers: ["Project X"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2012',
        notable_actor: '',
        director: 'Project X, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '480': {
        content: '',
        submitted_by: '',
        answers: ["Spirit Untamed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Western',
        release_year: '2021',
        notable_actor: '',
        director: 'Spirit Untamed, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '481': {
        content: '',
        submitted_by: '',
        answers: ["Power Rangers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Power Rangers, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '482': {
        content: '',
        submitted_by: '',
        answers: ["Enchanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy, Romance',
        release_year: '2007',
        notable_actor: '',
        director: 'Enchanted, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '483': {
        content: '',
        submitted_by: '',
        answers: ["Iron Man 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'Iron Man 3, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '484': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Wrong Turn, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '485': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King II: Simba's Pride"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Action',
        release_year: '1998',
        notable_actor: '',
        director: 'The Lion King II: Simba\'s Pride, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '486': {
        content: '',
        submitted_by: '',
        answers: ["It"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'It, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '487': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Two Towers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2002',
        notable_actor: '',
        director: 'The Lord of the Rings: The Two Towers, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '488': {
        content: '',
        submitted_by: '',
        answers: ["Tom & Jerry"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Animation',
        release_year: '2021',
        notable_actor: '',
        director: 'Tom & Jerry, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '489': {
        content: '',
        submitted_by: '',
        answers: ["Road to Ninja: Naruto the Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'Road to Ninja: Naruto the Movie, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '490': {
        content: '',
        submitted_by: '',
        answers: ["Terminator: Dark Fate"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Terminator: Dark Fate, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '491': {
        content: '',
        submitted_by: '',
        answers: ["Three Thousand Years of Longing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Three Thousand Years of Longing, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '492': {
        content: '',
        submitted_by: '',
        answers: ["Heat"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '1995',
        notable_actor: '',
        director: 'Heat, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '493': {
        content: '',
        submitted_by: '',
        answers: ["Rio"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Rio, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '494': {
        content: '',
        submitted_by: '',
        answers: ["Go!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family',
        release_year: '2020',
        notable_actor: '',
        director: 'Go!, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '495': {
        content: '',
        submitted_by: '',
        answers: ["Journey 2: The Mysterious Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Journey 2: The Mysterious Island, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '496': {
        content: '',
        submitted_by: '',
        answers: ["National Treasure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Mystery',
        release_year: '2004',
        notable_actor: '',
        director: 'National Treasure, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '497': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: Breaking Dawn - Part 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'The Twilight Saga: Breaking Dawn - Part 1, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '498': {
        content: '',
        submitted_by: '',
        answers: ["Train to Busan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Adventure, Action',
        release_year: '2016',
        notable_actor: '',
        director: 'Train to Busan, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '499': {
        content: '',
        submitted_by: '',
        answers: ["Snake Eyes: G.I. Joe Origins"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Snake Eyes: G.I. Joe Origins, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '500': {
        content: '',
        submitted_by: '',
        answers: ["Malignant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Malignant, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '501': {
        content: '',
        submitted_by: '',
        answers: ["Despicable Me 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2013',
        notable_actor: '',
        director: 'Despicable Me 2, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '502': {
        content: '',
        submitted_by: '',
        answers: ["Despicable Me"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Crime',
        release_year: '2010',
        notable_actor: '',
        director: 'Despicable Me, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '503': {
        content: '',
        submitted_by: '',
        answers: ["Jack Reacher: Never Go Back"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Jack Reacher: Never Go Back, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '504': {
        content: '',
        submitted_by: '',
        answers: ["Pok\u00e9mon Detective Pikachu"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Pokémon Detective Pikachu, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '505': {
        content: '',
        submitted_by: '',
        answers: ["The House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Comedy, Horror, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'The House, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '506': {
        content: '',
        submitted_by: '',
        answers: ["Pacific Rim: Uprising"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Science Fiction, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Pacific Rim: Uprising, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '507': {
        content: '',
        submitted_by: '',
        answers: ["Alien"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction',
        release_year: '1979',
        notable_actor: '',
        director: 'Alien, 1979 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '508': {
        content: '',
        submitted_by: '',
        answers: ["Kung Fu Panda 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Kung Fu Panda 2, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '509': {
        content: '',
        submitted_by: '',
        answers: ["Predator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure, Thriller',
        release_year: '1987',
        notable_actor: '',
        director: 'Predator, 1987 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '510': {
        content: '',
        submitted_by: '',
        answers: ["Rambo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2008',
        notable_actor: '',
        director: 'Rambo, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '511': {
        content: '',
        submitted_by: '',
        answers: ["The Outfit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'The Outfit, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '512': {
        content: '',
        submitted_by: '',
        answers: ["Saving Private Ryan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, War',
        release_year: '1998',
        notable_actor: '',
        director: 'Saving Private Ryan, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '513': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Godzilla, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '514': {
        content: '',
        submitted_by: '',
        answers: ["Beauty and the Beast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Family, Animation, Fantasy',
        release_year: '1991',
        notable_actor: '',
        director: 'Beauty and the Beast, 1991 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '515': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure',
        release_year: '2007',
        notable_actor: '',
        director: 'Spider-Man 3, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '516': {
        content: '',
        submitted_by: '',
        answers: ["The Proposal"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance, Drama',
        release_year: '2009',
        notable_actor: '',
        director: 'The Proposal, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '517': {
        content: '',
        submitted_by: '',
        answers: ["Addams Family Values"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy',
        release_year: '1993',
        notable_actor: '',
        director: 'Addams Family Values, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '518': {
        content: '',
        submitted_by: '',
        answers: ["The Hangover Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'The Hangover Part II, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '519': {
        content: '',
        submitted_by: '',
        answers: ["Annabelle: Creation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Annabelle: Creation, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '520': {
        content: '',
        submitted_by: '',
        answers: ["A Bug's Life"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '1998',
        notable_actor: '',
        director: 'A Bug\'s Life, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '521': {
        content: '',
        submitted_by: '',
        answers: ["Man of Steel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'Man of Steel, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '522': {
        content: '',
        submitted_by: '',
        answers: ["Beauty and the Beast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Romance',
        release_year: '2017',
        notable_actor: '',
        director: 'Beauty and the Beast, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '523': {
        content: '',
        submitted_by: '',
        answers: ["Alien: Covenant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Horror, Mystery',
        release_year: '2017',
        notable_actor: '',
        director: 'Alien: Covenant, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '524': {
        content: '',
        submitted_by: '',
        answers: ["Hustle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Hustle, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '525': {
        content: '',
        submitted_by: '',
        answers: ["The Witch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy, Horror, Mystery',
        release_year: '2015',
        notable_actor: '',
        director: 'The Witch, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '526': {
        content: '',
        submitted_by: '',
        answers: ["Cars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family',
        release_year: '2006',
        notable_actor: '',
        director: 'Cars, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '527': {
        content: '',
        submitted_by: '',
        answers: ["Gabriel's Inferno: Part III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'Gabriel\'s Inferno: Part III, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '528': {
        content: '',
        submitted_by: '',
        answers: ["Rocky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '1976',
        notable_actor: '',
        director: 'Rocky, 1976 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '529': {
        content: '',
        submitted_by: '',
        answers: ["Terminator 2: Judgment Day"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1991',
        notable_actor: '',
        director: 'Terminator 2: Judgment Day, 1991 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '530': {
        content: '',
        submitted_by: '',
        answers: ["The Help"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2011',
        notable_actor: '',
        director: 'The Help, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '531': {
        content: '',
        submitted_by: '',
        answers: ["Death on the Nile"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Drama, Crime, Thriller, Action, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Death on the Nile, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '532': {
        content: '',
        submitted_by: '',
        answers: ["Hereditary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Hereditary, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '533': {
        content: '',
        submitted_by: '',
        answers: ["A Haunted House 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2014',
        notable_actor: '',
        director: 'A Haunted House 2, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '534': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2001',
        notable_actor: '',
        director: 'Scary Movie 2, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '535': {
        content: '',
        submitted_by: '',
        answers: ["Paranormal Activity: The Marked Ones"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Paranormal Activity: The Marked Ones, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '536': {
        content: '',
        submitted_by: '',
        answers: ["Emancipation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Emancipation, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '537': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons in Plusaversary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'The Simpsons in Plusaversary, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '538': {
        content: '',
        submitted_by: '',
        answers: ["Alita: Battle Angel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'Alita: Battle Angel, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '539': {
        content: '',
        submitted_by: '',
        answers: ["We Have a Ghost"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy, Family, Adventure',
        release_year: '2023',
        notable_actor: '',
        director: 'We Have a Ghost, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '540': {
        content: '',
        submitted_by: '',
        answers: ["Alice in Wonderland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Adventure',
        release_year: '2010',
        notable_actor: '',
        director: 'Alice in Wonderland, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '541': {
        content: '',
        submitted_by: '',
        answers: ["The Croods"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy, Comedy, Action',
        release_year: '2013',
        notable_actor: '',
        director: 'The Croods, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '542': {
        content: '',
        submitted_by: '',
        answers: ["The Incredible Hulk"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2008',
        notable_actor: '',
        director: 'The Incredible Hulk, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '543': {
        content: '',
        submitted_by: '',
        answers: ["Never Back Down"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2008',
        notable_actor: '',
        director: 'Never Back Down, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '544': {
        content: '',
        submitted_by: '',
        answers: ["Spiderhead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Spiderhead, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '545': {
        content: '',
        submitted_by: '',
        answers: ["Abraham Lincoln: Vampire Hunter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Horror',
        release_year: '2012',
        notable_actor: '',
        director: 'Abraham Lincoln: Vampire Hunter, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '546': {
        content: '',
        submitted_by: '',
        answers: ["Ninja Assassin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Ninja Assassin, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '547': {
        content: '',
        submitted_by: '',
        answers: ["Boston Strangler"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Crime',
        release_year: '2023',
        notable_actor: '',
        director: 'Boston Strangler, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '548': {
        content: '',
        submitted_by: '',
        answers: ["My Neighbor Totoro"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Family',
        release_year: '1988',
        notable_actor: '',
        director: 'My Neighbor Totoro, 1988 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '549': {
        content: '',
        submitted_by: '',
        answers: ["Kimi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Kimi, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '550': {
        content: '',
        submitted_by: '',
        answers: ["Nightmare Alley"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Nightmare Alley, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '551': {
        content: '',
        submitted_by: '',
        answers: ["Below Her Mouth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2017',
        notable_actor: '',
        director: 'Below Her Mouth, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '552': {
        content: '',
        submitted_by: '',
        answers: ["A Good Day to Die Hard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'A Good Day to Die Hard, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '553': {
        content: '',
        submitted_by: '',
        answers: ["Umma"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'Umma, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '554': {
        content: '',
        submitted_by: '',
        answers: ["Monster Pets: A Hotel Transylvania Short"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Monster Pets: A Hotel Transylvania Short, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '555': {
        content: '',
        submitted_by: '',
        answers: ["The Jungle Book"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure',
        release_year: '1967',
        notable_actor: '',
        director: 'The Jungle Book, 1967 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '556': {
        content: '',
        submitted_by: '',
        answers: ["Maleficent: Mistress of Evil"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Adventure, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Maleficent: Mistress of Evil, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '557': {
        content: '',
        submitted_by: '',
        answers: ["White Chicks"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime',
        release_year: '2004',
        notable_actor: '',
        director: 'White Chicks, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '558': {
        content: '',
        submitted_by: '',
        answers: ["Tom and Jerry Cowboy Up!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Western',
        release_year: '2022',
        notable_actor: '',
        director: 'Tom and Jerry Cowboy Up!, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '559': {
        content: '',
        submitted_by: '',
        answers: ["Forrest Gump"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '1994',
        notable_actor: '',
        director: 'Forrest Gump, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '560': {
        content: '',
        submitted_by: '',
        answers: ["Wendell & Wild"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Wendell & Wild, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '561': {
        content: '',
        submitted_by: '',
        answers: ["Ron's Gone Wrong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Family, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Ron\'s Gone Wrong, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '562': {
        content: '',
        submitted_by: '',
        answers: ["Captain Marvel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Captain Marvel, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '563': {
        content: '',
        submitted_by: '',
        answers: ["Split"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Split, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '564': {
        content: '',
        submitted_by: '',
        answers: ["Coherence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'Coherence, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '565': {
        content: '',
        submitted_by: '',
        answers: ["Superbad"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2007',
        notable_actor: '',
        director: 'Superbad, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '566': {
        content: '',
        submitted_by: '',
        answers: ["Mad Max: Fury Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Mad Max: Fury Road, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '567': {
        content: '',
        submitted_by: '',
        answers: ["Pixels"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Pixels, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '568': {
        content: '',
        submitted_by: '',
        answers: ["Taken"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2008',
        notable_actor: '',
        director: 'Taken, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '569': {
        content: '',
        submitted_by: '',
        answers: ["Wonder Woman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Wonder Woman, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '570': {
        content: '',
        submitted_by: '',
        answers: ["The Terminator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1984',
        notable_actor: '',
        director: 'The Terminator, 1984 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '571': {
        content: '',
        submitted_by: '',
        answers: ["American Wedding"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2003',
        notable_actor: '',
        director: 'American Wedding, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '572': {
        content: '',
        submitted_by: '',
        answers: ["Peter Pan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family',
        release_year: '2003',
        notable_actor: '',
        director: 'Peter Pan, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '573': {
        content: '',
        submitted_by: '',
        answers: ["Poker Face"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Poker Face, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '574': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: Beta House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2007',
        notable_actor: '',
        director: 'American Pie Presents: Beta House, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '575': {
        content: '',
        submitted_by: '',
        answers: ["Vendetta"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2022',
        notable_actor: '',
        director: 'Vendetta, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '576': {
        content: '',
        submitted_by: '',
        answers: ["Birds of Prey (and the Fantabulous Emancipation of One Harley Quinn)"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime',
        release_year: '2020',
        notable_actor: '',
        director: 'Birds of Prey (and the Fantabulous Emancipation of One Harley Quinn), 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '577': {
        content: '',
        submitted_by: '',
        answers: ["The Karate Kid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Drama, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'The Karate Kid, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '578': {
        content: '',
        submitted_by: '',
        answers: ["Lilo & Stitch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2002',
        notable_actor: '',
        director: 'Lilo & Stitch, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '579': {
        content: '',
        submitted_by: '',
        answers: ["In Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'In Time, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '580': {
        content: '',
        submitted_by: '',
        answers: ["Baywatch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Action, Crime',
        release_year: '2017',
        notable_actor: '',
        director: 'Baywatch, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '581': {
        content: '',
        submitted_by: '',
        answers: ["Rambo: Last Blood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Rambo: Last Blood, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '582': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: Continental Drift"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Adventure, Family',
        release_year: '2012',
        notable_actor: '',
        director: 'Ice Age: Continental Drift, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '583': {
        content: '',
        submitted_by: '',
        answers: ["Ted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'Ted, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '584': {
        content: '',
        submitted_by: '',
        answers: ["Garfield"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'Garfield, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '585': {
        content: '',
        submitted_by: '',
        answers: ["The Secret Life of Pets 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2019',
        notable_actor: '',
        director: 'The Secret Life of Pets 2, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '586': {
        content: '',
        submitted_by: '',
        answers: ["Legion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'Legion, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '587': {
        content: '',
        submitted_by: '',
        answers: ["Lucy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Lucy, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '588': {
        content: '',
        submitted_by: '',
        answers: ["The Hobbit: The Desolation of Smaug"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Action',
        release_year: '2013',
        notable_actor: '',
        director: 'The Hobbit: The Desolation of Smaug, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '589': {
        content: '',
        submitted_by: '',
        answers: ["Whiplash"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Music',
        release_year: '2014',
        notable_actor: '',
        director: 'Whiplash, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '590': {
        content: '',
        submitted_by: '',
        answers: ["No Manches Frida 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2019',
        notable_actor: '',
        director: 'No Manches Frida 2, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '591': {
        content: '',
        submitted_by: '',
        answers: ["Halloween Kills"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Halloween Kills, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '592': {
        content: '',
        submitted_by: '',
        answers: ["The Intern"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'The Intern, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '593': {
        content: '',
        submitted_by: '',
        answers: ["The Vault"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'The Vault, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '594': {
        content: '',
        submitted_by: '',
        answers: ["The Kissing Booth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2018',
        notable_actor: '',
        director: 'The Kissing Booth, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '595': {
        content: '',
        submitted_by: '',
        answers: ["The Expendables 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Expendables 3, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '596': {
        content: '',
        submitted_by: '',
        answers: ["Black Site"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Black Site, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '597': {
        content: '',
        submitted_by: '',
        answers: ["Hitman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2007',
        notable_actor: '',
        director: 'Hitman, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '598': {
        content: '',
        submitted_by: '',
        answers: ["Scooby-Doo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Adventure, Comedy',
        release_year: '2002',
        notable_actor: '',
        director: 'Scooby-Doo, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '599': {
        content: '',
        submitted_by: '',
        answers: ["Hancock"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action',
        release_year: '2008',
        notable_actor: '',
        director: 'Hancock, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '600': {
        content: '',
        submitted_by: '',
        answers: ["Schindler's List"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, War',
        release_year: '1993',
        notable_actor: '',
        director: 'Schindler\'s List, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '601': {
        content: '',
        submitted_by: '',
        answers: ["Don't Worry Darling"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Don\'t Worry Darling, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '602': {
        content: '',
        submitted_by: '',
        answers: ["John Carter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'John Carter, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '603': {
        content: '',
        submitted_by: '',
        answers: ["Friday the 13th"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2009',
        notable_actor: '',
        director: 'Friday the 13th, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '604': {
        content: '',
        submitted_by: '',
        answers: ["My Hero Academia: Two Heroes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'My Hero Academia: Two Heroes, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '605': {
        content: '',
        submitted_by: '',
        answers: ["Tomorrowland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Mystery, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Tomorrowland, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '606': {
        content: '',
        submitted_by: '',
        answers: ["Sal\u00f2, or the 120 Days of Sodom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '1976',
        notable_actor: '',
        director: 'Salò, or the 120 Days of Sodom, 1976 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '607': {
        content: '',
        submitted_by: '',
        answers: ["Countdown"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Countdown, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '608': {
        content: '',
        submitted_by: '',
        answers: ["I Want You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2012',
        notable_actor: '',
        director: 'I Want You, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '609': {
        content: '',
        submitted_by: '',
        answers: ["Down"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Down, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '610': {
        content: '',
        submitted_by: '',
        answers: ["War for the Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, War',
        release_year: '2017',
        notable_actor: '',
        director: 'War for the Planet of the Apes, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '611': {
        content: '',
        submitted_by: '',
        answers: ["American Pie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '1999',
        notable_actor: '',
        director: 'American Pie, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '612': {
        content: '',
        submitted_by: '',
        answers: ["Black Panther"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Black Panther, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '613': {
        content: '',
        submitted_by: '',
        answers: ["A Silent Voice: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'A Silent Voice: The Movie, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '614': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: The Book of Love"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2009',
        notable_actor: '',
        director: 'American Pie Presents: The Book of Love, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '615': {
        content: '',
        submitted_by: '',
        answers: ["The Godfather Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1974',
        notable_actor: '',
        director: 'The Godfather Part II, 1974 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '616': {
        content: '',
        submitted_by: '',
        answers: ["Black Water: Abyss"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Adventure',
        release_year: '2020',
        notable_actor: '',
        director: 'Black Water: Abyss, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '617': {
        content: '',
        submitted_by: '',
        answers: ["The Desperate Hour"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Desperate Hour, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '618': {
        content: '',
        submitted_by: '',
        answers: ["Madagascar 3: Europe's Most Wanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Madagascar 3: Europe\'s Most Wanted, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '619': {
        content: '',
        submitted_by: '',
        answers: ["A Whisker Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Romance, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'A Whisker Away, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '620': {
        content: '',
        submitted_by: '',
        answers: ["Tom and Jerry: The Fast and the Furry"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Action, Adventure',
        release_year: '2005',
        notable_actor: '',
        director: 'Tom and Jerry: The Fast and the Furry, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '621': {
        content: '',
        submitted_by: '',
        answers: ["Purple Hearts"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Purple Hearts, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '622': {
        content: '',
        submitted_by: '',
        answers: ["Glass"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Glass, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '623': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: New Moon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Romance',
        release_year: '2009',
        notable_actor: '',
        director: 'The Twilight Saga: New Moon, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '624': {
        content: '',
        submitted_by: '',
        answers: ["Peter Pan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '1953',
        notable_actor: '',
        director: 'Peter Pan, 1953 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '625': {
        content: '',
        submitted_by: '',
        answers: ["Ponyo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Family',
        release_year: '2008',
        notable_actor: '',
        director: 'Ponyo, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '626': {
        content: '',
        submitted_by: '',
        answers: ["My Hero Academia: Heroes Rising"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'My Hero Academia: Heroes Rising, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '627': {
        content: '',
        submitted_by: '',
        answers: ["Bad Boys for Life"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Crime',
        release_year: '2020',
        notable_actor: '',
        director: 'Bad Boys for Life, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '628': {
        content: '',
        submitted_by: '',
        answers: ["The Remaining"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2014',
        notable_actor: '',
        director: 'The Remaining, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '629': {
        content: '',
        submitted_by: '',
        answers: ["Justice League: The Flashpoint Paradox"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Animation, Action, Adventure',
        release_year: '2013',
        notable_actor: '',
        director: 'Justice League: The Flashpoint Paradox, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '630': {
        content: '',
        submitted_by: '',
        answers: ["Just Go with It"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'Just Go with It, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '631': {
        content: '',
        submitted_by: '',
        answers: ["The Good, the Bad and the Ugly"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '1966',
        notable_actor: '',
        director: 'The Good, the Bad and the Ugly, 1966 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '632': {
        content: '',
        submitted_by: '',
        answers: ["Charm City Kings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'Charm City Kings, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '633': {
        content: '',
        submitted_by: '',
        answers: ["Living"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Living, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '634': {
        content: '',
        submitted_by: '',
        answers: ["Titane"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Titane, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '635': {
        content: '',
        submitted_by: '',
        answers: ["The SpongeBob SquarePants Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Fantasy, Adventure',
        release_year: '2004',
        notable_actor: '',
        director: 'The SpongeBob SquarePants Movie, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '636': {
        content: '',
        submitted_by: '',
        answers: ["The Lodge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'The Lodge, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '637': {
        content: '',
        submitted_by: '',
        answers: ["Chappie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Chappie, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '638': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 5: Bloodlines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'Wrong Turn 5: Bloodlines, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '639': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: Girls' Rules"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'American Pie Presents: Girls\' Rules, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '640': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: The Meltdown"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Adventure',
        release_year: '2006',
        notable_actor: '',
        director: 'Ice Age: The Meltdown, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '641': {
        content: '',
        submitted_by: '',
        answers: ["Rocky IV"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1985',
        notable_actor: '',
        director: 'Rocky IV, 1985 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '642': {
        content: '',
        submitted_by: '',
        answers: ["Dinosaur"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation',
        release_year: '2000',
        notable_actor: '',
        director: 'Dinosaur, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '643': {
        content: '',
        submitted_by: '',
        answers: ["No Manches Frida"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'No Manches Frida, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '644': {
        content: '',
        submitted_by: '',
        answers: ["It Boy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'It Boy, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '645': {
        content: '',
        submitted_by: '',
        answers: ["Old"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Old, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '646': {
        content: '',
        submitted_by: '',
        answers: ["Gold"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Gold, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '647': {
        content: '',
        submitted_by: '',
        answers: ["The Stranger"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Stranger, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '648': {
        content: '',
        submitted_by: '',
        answers: ["The Notebook"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2004',
        notable_actor: '',
        director: 'The Notebook, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '649': {
        content: '',
        submitted_by: '',
        answers: ["Vivarium"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller, Mystery, Horror',
        release_year: '2019',
        notable_actor: '',
        director: 'Vivarium, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '650': {
        content: '',
        submitted_by: '',
        answers: ["Redcon-1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Horror, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Redcon-1, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '651': {
        content: '',
        submitted_by: '',
        answers: ["Brother Bear"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family',
        release_year: '2003',
        notable_actor: '',
        director: 'Brother Bear, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '652': {
        content: '',
        submitted_by: '',
        answers: ["The Valet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'The Valet, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '653': {
        content: '',
        submitted_by: '',
        answers: ["Murder Mystery"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Murder Mystery, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '654': {
        content: '',
        submitted_by: '',
        answers: ["Inglourious Basterds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, War',
        release_year: '2009',
        notable_actor: '',
        director: 'Inglourious Basterds, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '655': {
        content: '',
        submitted_by: '',
        answers: ["Chickenhare and the Hamster of Darkness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Chickenhare and the Hamster of Darkness, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '656': {
        content: '',
        submitted_by: '',
        answers: ["Annapolis"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2006',
        notable_actor: '',
        director: 'Annapolis, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '657': {
        content: '',
        submitted_by: '',
        answers: ["Magnum Opus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Science Fiction, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Magnum Opus, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '658': {
        content: '',
        submitted_by: '',
        answers: ["Bolt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Bolt, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '659': {
        content: '',
        submitted_by: '',
        answers: ["Weathering with You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Fantasy, Romance',
        release_year: '2019',
        notable_actor: '',
        director: 'Weathering with You, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '660': {
        content: '',
        submitted_by: '',
        answers: ["I Am Legend"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Thriller',
        release_year: '2007',
        notable_actor: '',
        director: 'I Am Legend, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '661': {
        content: '',
        submitted_by: '',
        answers: ["Exodus: Gods and Kings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'Exodus: Gods and Kings, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '662': {
        content: '',
        submitted_by: '',
        answers: ["Justice League Dark: Apokolips War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Science Fiction',
        release_year: '2020',
        notable_actor: '',
        director: 'Justice League Dark: Apokolips War, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '663': {
        content: '',
        submitted_by: '',
        answers: ["Army of Thieves"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Army of Thieves, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '664': {
        content: '',
        submitted_by: '',
        answers: ["Batman Begins"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '2005',
        notable_actor: '',
        director: 'Batman Begins, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '665': {
        content: '',
        submitted_by: '',
        answers: ["Fireheart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'Fireheart, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '666': {
        content: '',
        submitted_by: '',
        answers: ["The Deep End of the Ocean"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '1999',
        notable_actor: '',
        director: 'The Deep End of the Ocean, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '667': {
        content: '',
        submitted_by: '',
        answers: ["The Hangover Part III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'The Hangover Part III, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '668': {
        content: '',
        submitted_by: '',
        answers: ["Tenet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2020',
        notable_actor: '',
        director: 'Tenet, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '669': {
        content: '',
        submitted_by: '',
        answers: ["The Passion of the Christ"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2004',
        notable_actor: '',
        director: 'The Passion of the Christ, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '670': {
        content: '',
        submitted_by: '',
        answers: ["Logan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Logan, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '671': {
        content: '',
        submitted_by: '',
        answers: ["American Psycho"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Crime',
        release_year: '2000',
        notable_actor: '',
        director: 'American Psycho, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '672': {
        content: '',
        submitted_by: '',
        answers: ["The Sadness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Sadness, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '673': {
        content: '',
        submitted_by: '',
        answers: ["Rise of the Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Drama, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Rise of the Planet of the Apes, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '674': {
        content: '',
        submitted_by: '',
        answers: ["Hotel Transylvania"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'Hotel Transylvania, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '675': {
        content: '',
        submitted_by: '',
        answers: ["Heaven & Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'History, War, Action, Drama',
        release_year: '1993',
        notable_actor: '',
        director: 'Heaven & Earth, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '676': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Lost Treasure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '2009',
        notable_actor: '',
        director: 'Tinker Bell and the Lost Treasure, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '677': {
        content: '',
        submitted_by: '',
        answers: ["The Age of Adaline"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Fantasy, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'The Age of Adaline, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '678': {
        content: '',
        submitted_by: '',
        answers: ["Child's Play"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1988',
        notable_actor: '',
        director: 'Child\'s Play, 1988 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '679': {
        content: '',
        submitted_by: '',
        answers: ["The First Purge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'The First Purge, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '680': {
        content: '',
        submitted_by: '',
        answers: ["Meet Joe Black"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Romance',
        release_year: '1998',
        notable_actor: '',
        director: 'Meet Joe Black, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '681': {
        content: '',
        submitted_by: '',
        answers: ["The Mummy Returns"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2001',
        notable_actor: '',
        director: 'The Mummy Returns, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '682': {
        content: '',
        submitted_by: '',
        answers: ["Miracles from Heaven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'Miracles from Heaven, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '683': {
        content: '',
        submitted_by: '',
        answers: ["The Truman Show"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '1998',
        notable_actor: '',
        director: 'The Truman Show, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '684': {
        content: '',
        submitted_by: '',
        answers: ["Clash of the Titans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2010',
        notable_actor: '',
        director: 'Clash of the Titans, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '685': {
        content: '',
        submitted_by: '',
        answers: ["Captain America: The First Avenger"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Captain America: The First Avenger, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '686': {
        content: '',
        submitted_by: '',
        answers: ["Trolls World Tour"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Fantasy, Adventure, Music',
        release_year: '2020',
        notable_actor: '',
        director: 'Trolls World Tour, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '687': {
        content: '',
        submitted_by: '',
        answers: ["Men in Black"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Science Fiction',
        release_year: '1997',
        notable_actor: '',
        director: 'Men in Black, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '688': {
        content: '',
        submitted_by: '',
        answers: ["Catch Me If You Can"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '2002',
        notable_actor: '',
        director: 'Catch Me If You Can, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '689': {
        content: '',
        submitted_by: '',
        answers: ["Paranormal Activity: Next of Kin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Paranormal Activity: Next of Kin, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '690': {
        content: '',
        submitted_by: '',
        answers: ["Star Trek Beyond"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Star Trek Beyond, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '691': {
        content: '',
        submitted_by: '',
        answers: ["Kong: Skull Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Kong: Skull Island, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '692': {
        content: '',
        submitted_by: '',
        answers: ["Extraction"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Extraction, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '693': {
        content: '',
        submitted_by: '',
        answers: ["Tombstone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western, Action',
        release_year: '1993',
        notable_actor: '',
        director: 'Tombstone, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '694': {
        content: '',
        submitted_by: '',
        answers: ["Shut In"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Shut In, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '695': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 4: Bloody Beginnings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Wrong Turn 4: Bloody Beginnings, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '696': {
        content: '',
        submitted_by: '',
        answers: ["One Hundred and One Dalmatians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '1961',
        notable_actor: '',
        director: 'One Hundred and One Dalmatians, 1961 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '697': {
        content: '',
        submitted_by: '',
        answers: ["A Haunted House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2013',
        notable_actor: '',
        director: 'A Haunted House, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '698': {
        content: '',
        submitted_by: '',
        answers: ["Prometheus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Mystery',
        release_year: '2012',
        notable_actor: '',
        director: 'Prometheus, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '699': {
        content: '',
        submitted_by: '',
        answers: ["Bambi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Family',
        release_year: '1942',
        notable_actor: '',
        director: 'Bambi, 1942 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '700': {
        content: '',
        submitted_by: '',
        answers: ["Never Back Down: No Surrender"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2016',
        notable_actor: '',
        director: 'Never Back Down: No Surrender, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '701': {
        content: '',
        submitted_by: '',
        answers: ["Spartacus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'History, War, Drama, Adventure',
        release_year: '1960',
        notable_actor: '',
        director: 'Spartacus, 1960 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '702': {
        content: '',
        submitted_by: '',
        answers: ["Overcomer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Overcomer, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '703': {
        content: '',
        submitted_by: '',
        answers: ["Jeepers Creepers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2001',
        notable_actor: '',
        director: 'Jeepers Creepers, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '704': {
        content: '',
        submitted_by: '',
        answers: ["Call Me by Your Name"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'Call Me by Your Name, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '705': {
        content: '',
        submitted_by: '',
        answers: ["Shutter Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Mystery',
        release_year: '2010',
        notable_actor: '',
        director: 'Shutter Island, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '706': {
        content: '',
        submitted_by: '',
        answers: ["The Innkeepers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'The Innkeepers, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '707': {
        content: '',
        submitted_by: '',
        answers: ["Jumanji: Welcome to the Jungle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Comedy, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Jumanji: Welcome to the Jungle, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '708': {
        content: '',
        submitted_by: '',
        answers: ["Taken 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'Taken 2, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '709': {
        content: '',
        submitted_by: '',
        answers: ["Perfume: The Story of a Murderer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Fantasy, Drama',
        release_year: '2006',
        notable_actor: '',
        director: 'Perfume: The Story of a Murderer, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '710': {
        content: '',
        submitted_by: '',
        answers: ["Gladiator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Adventure',
        release_year: '2000',
        notable_actor: '',
        director: 'Gladiator, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '711': {
        content: '',
        submitted_by: '',
        answers: ["Five Feet Apart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Five Feet Apart, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '712': {
        content: '',
        submitted_by: '',
        answers: ["Megamind"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy, Family, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'Megamind, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '713': {
        content: '',
        submitted_by: '',
        answers: ["The Expendables"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Adventure, Action',
        release_year: '2010',
        notable_actor: '',
        director: 'The Expendables, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '714': {
        content: '',
        submitted_by: '',
        answers: ["CHiPS"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2017',
        notable_actor: '',
        director: 'CHiPS, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '715': {
        content: '',
        submitted_by: '',
        answers: ["Annabelle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2014',
        notable_actor: '',
        director: 'Annabelle, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '716': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Beasts and Where to Find Them"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Fantastic Beasts and Where to Find Them, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '717': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'The Hunger Games, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '718': {
        content: '',
        submitted_by: '',
        answers: ["Lady and the Tramp"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Romance',
        release_year: '1955',
        notable_actor: '',
        director: 'Lady and the Tramp, 1955 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '719': {
        content: '',
        submitted_by: '',
        answers: ["The Marksman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'The Marksman, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '720': {
        content: '',
        submitted_by: '',
        answers: ["Jojo Rabbit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, War, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Jojo Rabbit, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '721': {
        content: '',
        submitted_by: '',
        answers: ["Sleeping Beauty"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Romance, Family',
        release_year: '1959',
        notable_actor: '',
        director: 'Sleeping Beauty, 1959 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '722': {
        content: '',
        submitted_by: '',
        answers: ["How the Grinch Stole Christmas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Fantasy',
        release_year: '2000',
        notable_actor: '',
        director: 'How the Grinch Stole Christmas, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '723': {
        content: '',
        submitted_by: '',
        answers: ["Predators"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Predators, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '724': {
        content: '',
        submitted_by: '',
        answers: ["The Town"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'The Town, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '725': {
        content: '',
        submitted_by: '',
        answers: ["Terminator Salvation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Terminator Salvation, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '726': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible - Fallout"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Mission: Impossible - Fallout, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '727': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2003',
        notable_actor: '',
        director: 'Wrong Turn, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '728': {
        content: '',
        submitted_by: '',
        answers: ["How to Train Your Dragon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Animation, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'How to Train Your Dragon, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '729': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '2008',
        notable_actor: '',
        director: 'Tinker Bell, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '730': {
        content: '',
        submitted_by: '',
        answers: ["Rango"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Western, Adventure',
        release_year: '2011',
        notable_actor: '',
        director: 'Rango, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '731': {
        content: '',
        submitted_by: '',
        answers: ["American Reunion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2012',
        notable_actor: '',
        director: 'American Reunion, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '732': {
        content: '',
        submitted_by: '',
        answers: ["Hansel & Gretel: Witch Hunters"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Horror, Action',
        release_year: '2013',
        notable_actor: '',
        director: 'Hansel & Gretel: Witch Hunters, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '733': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 4"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2006',
        notable_actor: '',
        director: 'Scary Movie 4, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '734': {
        content: '',
        submitted_by: '',
        answers: ["The Last Airbender"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'The Last Airbender, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '735': {
        content: '',
        submitted_by: '',
        answers: ["Lolita"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1997',
        notable_actor: '',
        director: 'Lolita, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '736': {
        content: '',
        submitted_by: '',
        answers: ["Back to the Outback"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Back to the Outback, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '737': {
        content: '',
        submitted_by: '',
        answers: ["The Little Guy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'The Little Guy, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '738': {
        content: '',
        submitted_by: '',
        answers: ["Rise of the Guardians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Action, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Rise of the Guardians, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '739': {
        content: '',
        submitted_by: '',
        answers: ["Project Almanac"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Project Almanac, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '740': {
        content: '',
        submitted_by: '',
        answers: ["Coach Carter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2005',
        notable_actor: '',
        director: 'Coach Carter, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '741': {
        content: '',
        submitted_by: '',
        answers: ["Kick-Ass"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime',
        release_year: '2010',
        notable_actor: '',
        director: 'Kick-Ass, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '742': {
        content: '',
        submitted_by: '',
        answers: ["Insidious"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Insidious, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '743': {
        content: '',
        submitted_by: '',
        answers: ["Ghost Rider: Spirit of Vengeance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Ghost Rider: Spirit of Vengeance, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '744': {
        content: '',
        submitted_by: '',
        answers: ["Army of the Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Army of the Dead, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '745': {
        content: '',
        submitted_by: '',
        answers: ["The Devil's Advocate"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery, Thriller, Horror',
        release_year: '1997',
        notable_actor: '',
        director: 'The Devil\'s Advocate, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '746': {
        content: '',
        submitted_by: '',
        answers: ["Green Book"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2018',
        notable_actor: '',
        director: 'Green Book, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '747': {
        content: '',
        submitted_by: '',
        answers: ["X-Men: Apocalypse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'X-Men: Apocalypse, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '748': {
        content: '',
        submitted_by: '',
        answers: ["Warrior"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Warrior, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '749': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2003',
        notable_actor: '',
        director: 'Scary Movie 3, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '750': {
        content: '',
        submitted_by: '',
        answers: ["Epic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'Epic, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '751': {
        content: '',
        submitted_by: '',
        answers: ["Legend"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Legend, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '752': {
        content: '',
        submitted_by: '',
        answers: ["Life Is Beautiful"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '1997',
        notable_actor: '',
        director: 'Life Is Beautiful, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '753': {
        content: '',
        submitted_by: '',
        answers: ["The Great Wall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'The Great Wall, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '754': {
        content: '',
        submitted_by: '',
        answers: ["Aliens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1986',
        notable_actor: '',
        director: 'Aliens, 1986 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '755': {
        content: '',
        submitted_by: '',
        answers: ["Matilda"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy',
        release_year: '1996',
        notable_actor: '',
        director: 'Matilda, 1996 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '756': {
        content: '',
        submitted_by: '',
        answers: ["Hercules"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2014',
        notable_actor: '',
        director: 'Hercules, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '757': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2000',
        notable_actor: '',
        director: 'Final Destination, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '758': {
        content: '',
        submitted_by: '',
        answers: ["War Horse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Drama, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'War Horse, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '759': {
        content: '',
        submitted_by: '',
        answers: ["The Angry Birds Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'The Angry Birds Movie, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '760': {
        content: '',
        submitted_by: '',
        answers: ["Van Helsing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Adventure, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'Van Helsing, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '761': {
        content: '',
        submitted_by: '',
        answers: ["Pok\u00e9mon the Movie: I Choose You!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Pokémon the Movie: I Choose You!, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '762': {
        content: '',
        submitted_by: '',
        answers: ["Insurgent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Insurgent, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '763': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: Collision Course"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Comedy, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Ice Age: Collision Course, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '764': {
        content: '',
        submitted_by: '',
        answers: ["The Predator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'The Predator, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '765': {
        content: '',
        submitted_by: '',
        answers: ["TRON: Legacy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'TRON: Legacy, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '766': {
        content: '',
        submitted_by: '',
        answers: ["The Deep House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'The Deep House, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '767': {
        content: '',
        submitted_by: '',
        answers: ["Lady Chatterley's Lover"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Lady Chatterley\'s Lover, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '768': {
        content: '',
        submitted_by: '',
        answers: ["Host"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2020',
        notable_actor: '',
        director: 'Host, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '769': {
        content: '',
        submitted_by: '',
        answers: ["RV"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy',
        release_year: '2006',
        notable_actor: '',
        director: 'RV, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '770': {
        content: '',
        submitted_by: '',
        answers: ["Maze Runner: The Scorch Trials"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Maze Runner: The Scorch Trials, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '771': {
        content: '',
        submitted_by: '',
        answers: ["Season of the Witch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Season of the Witch, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '772': {
        content: '',
        submitted_by: '',
        answers: ["Pinocchio"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1940',
        notable_actor: '',
        director: 'Pinocchio, 1940 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '773': {
        content: '',
        submitted_by: '',
        answers: ["The Fabelmans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'The Fabelmans, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '774': {
        content: '',
        submitted_by: '',
        answers: ["Maze Runner: The Death Cure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Maze Runner: The Death Cure, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '775': {
        content: '',
        submitted_by: '',
        answers: ["The Fifth Element"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action, Thriller, Science Fiction',
        release_year: '1997',
        notable_actor: '',
        director: 'The Fifth Element, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '776': {
        content: '',
        submitted_by: '',
        answers: ["Bumblebee"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Bumblebee, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '777': {
        content: '',
        submitted_by: '',
        answers: ["Need for Speed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Need for Speed, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '778': {
        content: '',
        submitted_by: '',
        answers: ["Mechanic: Resurrection"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Mechanic: Resurrection, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '779': {
        content: '',
        submitted_by: '',
        answers: ["G.I. Joe: The Rise of Cobra"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '2009',
        notable_actor: '',
        director: 'G.I. Joe: The Rise of Cobra, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '780': {
        content: '',
        submitted_by: '',
        answers: ["Undisputed II: Last Man Standing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Undisputed II: Last Man Standing, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '781': {
        content: '',
        submitted_by: '',
        answers: ["Django Unchained"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Western',
        release_year: '2012',
        notable_actor: '',
        director: 'Django Unchained, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '782': {
        content: '',
        submitted_by: '',
        answers: ["Now You See Me"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'Now You See Me, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '783': {
        content: '',
        submitted_by: '',
        answers: ["Nymphomaniac: Vol. II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '2013',
        notable_actor: '',
        director: 'Nymphomaniac: Vol. II, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '784': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: The Naked Mile"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2006',
        notable_actor: '',
        director: 'American Pie Presents: The Naked Mile, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '785': {
        content: '',
        submitted_by: '',
        answers: ["The 355"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The 355, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '786': {
        content: '',
        submitted_by: '',
        answers: ["Alice Through the Looking Glass"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Alice Through the Looking Glass, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '787': {
        content: '',
        submitted_by: '',
        answers: ["The Lorax"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2012',
        notable_actor: '',
        director: 'The Lorax, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '788': {
        content: '',
        submitted_by: '',
        answers: ["The Intouchables"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'The Intouchables, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '789': {
        content: '',
        submitted_by: '',
        answers: ["Sharper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Drama',
        release_year: '2023',
        notable_actor: '',
        director: 'Sharper, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '790': {
        content: '',
        submitted_by: '',
        answers: ["Spiral: From the Book of Saw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Crime, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'Spiral: From the Book of Saw, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '791': {
        content: '',
        submitted_by: '',
        answers: ["Transformers: Beginnings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Thriller, Science Fiction',
        release_year: '2006',
        notable_actor: '',
        director: 'Transformers: Beginnings, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '792': {
        content: '',
        submitted_by: '',
        answers: ["Riddick"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Riddick, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '793': {
        content: '',
        submitted_by: '',
        answers: ["Groot's Pursuit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Groot\'s Pursuit, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '794': {
        content: '',
        submitted_by: '',
        answers: ["Knock Knock"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'Knock Knock, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '795': {
        content: '',
        submitted_by: '',
        answers: ["The Blind Side"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2009',
        notable_actor: '',
        director: 'The Blind Side, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '796': {
        content: '',
        submitted_by: '',
        answers: ["Night at the Museum: Secret of the Tomb"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Fantasy, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Night at the Museum: Secret of the Tomb, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '797': {
        content: '',
        submitted_by: '',
        answers: ["That's My Boy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '2012',
        notable_actor: '',
        director: 'That\'s My Boy, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '798': {
        content: '',
        submitted_by: '',
        answers: ["Alice in Wonderland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '1951',
        notable_actor: '',
        director: 'Alice in Wonderland, 1951 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '799': {
        content: '',
        submitted_by: '',
        answers: ["Trolls"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Adventure, Comedy, Music',
        release_year: '2016',
        notable_actor: '',
        director: 'Trolls, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '800': {
        content: '',
        submitted_by: '',
        answers: ["Valerian and the City of a Thousand Planets"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction, Action',
        release_year: '2017',
        notable_actor: '',
        director: 'Valerian and the City of a Thousand Planets, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '801': {
        content: '',
        submitted_by: '',
        answers: ["Divergent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Divergent, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '802': {
        content: '',
        submitted_by: '',
        answers: ["Raiders of the Lost Ark"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action',
        release_year: '1981',
        notable_actor: '',
        director: 'Raiders of the Lost Ark, 1981 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '803': {
        content: '',
        submitted_by: '',
        answers: ["Glory Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2006',
        notable_actor: '',
        director: 'Glory Road, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '804': {
        content: '',
        submitted_by: '',
        answers: ["Ava"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Adventure, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Ava, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '805': {
        content: '',
        submitted_by: '',
        answers: ["The Curse of La Llorona"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'The Curse of La Llorona, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '806': {
        content: '',
        submitted_by: '',
        answers: ["Norbit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2007',
        notable_actor: '',
        director: 'Norbit, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '807': {
        content: '',
        submitted_by: '',
        answers: ["Ford v Ferrari"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, History',
        release_year: '2019',
        notable_actor: '',
        director: 'Ford v Ferrari, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '808': {
        content: '',
        submitted_by: '',
        answers: ["The Little Mermaid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Fantasy, Romance, Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'The Little Mermaid, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '809': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Blood Wars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Thriller, Horror',
        release_year: '2016',
        notable_actor: '',
        director: 'Underworld: Blood Wars, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '810': {
        content: '',
        submitted_by: '',
        answers: ["The Purge: Anarchy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Purge: Anarchy, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '811': {
        content: '',
        submitted_by: '',
        answers: ["The Magnificent Seven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Western',
        release_year: '2016',
        notable_actor: '',
        director: 'The Magnificent Seven, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '812': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Episode I - The Phantom Menace"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1999',
        notable_actor: '',
        director: 'Star Wars: Episode I - The Phantom Menace, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '813': {
        content: '',
        submitted_by: '',
        answers: ["Brightburn"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction, Thriller, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Brightburn, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '814': {
        content: '',
        submitted_by: '',
        answers: ["Titanic II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Titanic II, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '815': {
        content: '',
        submitted_by: '',
        answers: ["The NeverEnding Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family, Drama',
        release_year: '1984',
        notable_actor: '',
        director: 'The NeverEnding Story, 1984 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '816': {
        content: '',
        submitted_by: '',
        answers: ["Rio 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Rio 2, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '817': {
        content: '',
        submitted_by: '',
        answers: ["Conan the Barbarian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '1982',
        notable_actor: '',
        director: 'Conan the Barbarian, 1982 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '818': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella II: Dreams Come True"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Romance, Fantasy',
        release_year: '2002',
        notable_actor: '',
        director: 'Cinderella II: Dreams Come True, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '819': {
        content: '',
        submitted_by: '',
        answers: ["Independence Day: Resurgence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Independence Day: Resurgence, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '820': {
        content: '',
        submitted_by: '',
        answers: ["Battle: Los Angeles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Battle: Los Angeles, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '821': {
        content: '',
        submitted_by: '',
        answers: ["Werewolf by Night"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Horror, TV Movie',
        release_year: '2022',
        notable_actor: '',
        director: 'Werewolf by Night, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '822': {
        content: '',
        submitted_by: '',
        answers: ["Straight Outta Nowhere: Scooby-Doo! Meets Courage the Cowardly Dog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Mystery, Family, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Straight Outta Nowhere: Scooby-Doo! Meets Courage the Cowardly Dog, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '823': {
        content: '',
        submitted_by: '',
        answers: ["Rush Hour 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2001',
        notable_actor: '',
        director: 'Rush Hour 2, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '824': {
        content: '',
        submitted_by: '',
        answers: ["Revolutionary Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2008',
        notable_actor: '',
        director: 'Revolutionary Road, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '825': {
        content: '',
        submitted_by: '',
        answers: ["Greenland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'Greenland, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '826': {
        content: '',
        submitted_by: '',
        answers: ["One Shot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'One Shot, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '827': {
        content: '',
        submitted_by: '',
        answers: ["Men in Black 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Men in Black 3, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '828': {
        content: '',
        submitted_by: '',
        answers: ["I'm Not Ashamed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'I\'m Not Ashamed, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '829': {
        content: '',
        submitted_by: '',
        answers: ["The Mask"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy, Crime, Fantasy',
        release_year: '1994',
        notable_actor: '',
        director: 'The Mask, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '830': {
        content: '',
        submitted_by: '',
        answers: ["Battleship"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Adventure, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Battleship, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '831': {
        content: '',
        submitted_by: '',
        answers: ["Hotel Transylvania 3: Summer Vacation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'Hotel Transylvania 3: Summer Vacation, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '832': {
        content: '',
        submitted_by: '',
        answers: ["Just a Breath Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Just a Breath Away, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '833': {
        content: '',
        submitted_by: '',
        answers: ["The Jungle Book"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Drama, Fantasy, Animation',
        release_year: '2016',
        notable_actor: '',
        director: 'The Jungle Book, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '834': {
        content: '',
        submitted_by: '',
        answers: ["Jaws"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Adventure',
        release_year: '1975',
        notable_actor: '',
        director: 'Jaws, 1975 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '835': {
        content: '',
        submitted_by: '',
        answers: ["Green Lantern"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Green Lantern, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '836': {
        content: '',
        submitted_by: '',
        answers: ["Midsommar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Midsommar, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '837': {
        content: '',
        submitted_by: '',
        answers: ["Shooter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Mystery, Thriller, Crime',
        release_year: '2007',
        notable_actor: '',
        director: 'Shooter, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '838': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King 1\u00bd"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'The Lion King 1½, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '839': {
        content: '',
        submitted_by: '',
        answers: ["Mad Max"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'asfda',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '1979',
        notable_actor: 'fsadf',
        director: 'Mad Max, 1979 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '840': {
        content: '',
        submitted_by: '',
        answers: ["The Road to El Dorado"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy, Fantasy',
        release_year: '2000',
        notable_actor: '',
        director: 'The Road to El Dorado, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '841': {
        content: '',
        submitted_by: '',
        answers: ["Deep Water"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Deep Water, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '842': {
        content: '',
        submitted_by: '',
        answers: ["T\u00c1R"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Music',
        release_year: '2022',
        notable_actor: '',
        director: 'TÁR, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '843': {
        content: '',
        submitted_by: '',
        answers: ["Injustice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Fantasy, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Injustice, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '844': {
        content: '',
        submitted_by: '',
        answers: ["Sicario"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Sicario, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '845': {
        content: '',
        submitted_by: '',
        answers: ["The Wizard of Oz"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family',
        release_year: '1939',
        notable_actor: '',
        director: 'The Wizard of Oz, 1939 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '846': {
        content: '',
        submitted_by: '',
        answers: ["American Pie 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2001',
        notable_actor: '',
        director: 'American Pie 2, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '847': {
        content: '',
        submitted_by: '',
        answers: ["Rambo: First Blood Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller, War',
        release_year: '1985',
        notable_actor: '',
        director: 'Rambo: First Blood Part II, 1985 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '848': {
        content: '',
        submitted_by: '',
        answers: ["Vacation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Vacation, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '849': {
        content: '',
        submitted_by: '',
        answers: ["Home"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy, Animation, Science Fiction, Family',
        release_year: '2015',
        notable_actor: '',
        director: 'Home, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '850': {
        content: '',
        submitted_by: '',
        answers: ["Ghost Rider"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Fantasy',
        release_year: '2007',
        notable_actor: '',
        director: 'Ghost Rider, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '851': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible - Ghost Protocol"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Adventure',
        release_year: '2011',
        notable_actor: '',
        director: 'Mission: Impossible - Ghost Protocol, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '852': {
        content: '',
        submitted_by: '',
        answers: ["Barbarian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Barbarian, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '853': {
        content: '',
        submitted_by: '',
        answers: ["The Grinch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'The Grinch, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '854': {
        content: '',
        submitted_by: '',
        answers: ["Unhinged"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Unhinged, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '855': {
        content: '',
        submitted_by: '',
        answers: ["Crank: High Voltage"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2009',
        notable_actor: '',
        director: 'Crank: High Voltage, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '856': {
        content: '',
        submitted_by: '',
        answers: ["The Cellar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'The Cellar, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '857': {
        content: '',
        submitted_by: '',
        answers: ["Ben-Hur"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History',
        release_year: '1959',
        notable_actor: '',
        director: 'Ben-Hur, 1959 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '858': {
        content: '',
        submitted_by: '',
        answers: ["V/H/S/99"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'V/H/S/99, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '859': {
        content: '',
        submitted_by: '',
        answers: ["Knives Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Knives Out, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '860': {
        content: '',
        submitted_by: '',
        answers: ["I Spit on Your Grave III: Vengeance is Mine"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'I Spit on Your Grave III: Vengeance is Mine, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '861': {
        content: '',
        submitted_by: '',
        answers: ["Rush Hour"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '1998',
        notable_actor: '',
        director: 'Rush Hour, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '862': {
        content: '',
        submitted_by: '',
        answers: ["The Aristocats"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '1970',
        notable_actor: '',
        director: 'The Aristocats, 1970 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '863': {
        content: '',
        submitted_by: '',
        answers: ["Mama"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2013',
        notable_actor: '',
        director: 'Mama, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '864': {
        content: '',
        submitted_by: '',
        answers: ["Seed of Chucky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror, Thriller',
        release_year: '2004',
        notable_actor: '',
        director: 'Seed of Chucky, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '865': {
        content: '',
        submitted_by: '',
        answers: ["Bride of Chucky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '1998',
        notable_actor: '',
        director: 'Bride of Chucky, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '866': {
        content: '',
        submitted_by: '',
        answers: ["The Chronicles of Narnia: Prince Caspian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2008',
        notable_actor: '',
        director: 'The Chronicles of Narnia: Prince Caspian, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '867': {
        content: '',
        submitted_by: '',
        answers: ["Perfect Blue"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Thriller',
        release_year: '1997',
        notable_actor: '',
        director: 'Perfect Blue, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '868': {
        content: '',
        submitted_by: '',
        answers: ["King Kong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Action',
        release_year: '2005',
        notable_actor: '',
        director: 'King Kong, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '869': {
        content: '',
        submitted_by: '',
        answers: ["GoodFellas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1990',
        notable_actor: '',
        director: 'GoodFellas, 1990 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '870': {
        content: '',
        submitted_by: '',
        answers: ["Batman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Crime',
        release_year: '1989',
        notable_actor: '',
        director: 'Batman, 1989 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '871': {
        content: '',
        submitted_by: '',
        answers: ["Beasts of No Nation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '2015',
        notable_actor: '',
        director: 'Beasts of No Nation, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '872': {
        content: '',
        submitted_by: '',
        answers: ["Bad Boys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Comedy, Crime, Thriller',
        release_year: '1995',
        notable_actor: 'tbd',
        director: 'Bad Boys, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '873': {
        content: '',
        submitted_by: '',
        answers: ["Alvin and the Chipmunks"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy, Animation',
        release_year: '2007',
        notable_actor: '',
        director: 'Alvin and the Chipmunks, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '874': {
        content: '',
        submitted_by: '',
        answers: ["The Martian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'The Martian, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '875': {
        content: '',
        submitted_by: '',
        answers: ["Prince of Persia: The Sands of Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2010',
        notable_actor: '',
        director: 'Prince of Persia: The Sands of Time, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '876': {
        content: '',
        submitted_by: '',
        answers: ["Pride & Prejudice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2005',
        notable_actor: '',
        director: 'Pride & Prejudice, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '877': {
        content: '',
        submitted_by: '',
        answers: ["Indiana Jones and the Last Crusade"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action',
        release_year: '1989',
        notable_actor: '',
        director: 'Indiana Jones and the Last Crusade, 1989 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '878': {
        content: '',
        submitted_by: '',
        answers: ["Aftersun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Aftersun, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '879': {
        content: '',
        submitted_by: '',
        answers: ["Dumbo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1941',
        notable_actor: '',
        director: 'Dumbo, 1941 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '880': {
        content: '',
        submitted_by: '',
        answers: ["Predator 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '1990',
        notable_actor: '',
        director: 'Predator 2, 1990 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '881': {
        content: '',
        submitted_by: '',
        answers: ["(500) Days of Summer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2009',
        notable_actor: '',
        director: '(500) Days of Summer, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '882': {
        content: '',
        submitted_by: '',
        answers: ["Vivo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Vivo, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '883': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Episode III - Revenge of the Sith"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2005',
        notable_actor: '',
        director: 'Star Wars: Episode III - Revenge of the Sith, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '884': {
        content: '',
        submitted_by: '',
        answers: ["Death Race"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2008',
        notable_actor: '',
        director: 'Death Race, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '885': {
        content: '',
        submitted_by: '',
        answers: ["God's Not Dead: A Light in Darkness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'God\'s Not Dead: A Light in Darkness, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '886': {
        content: '',
        submitted_by: '',
        answers: ["Terminator 3: Rise of the Machines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2003',
        notable_actor: '',
        director: 'Terminator 3: Rise of the Machines, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '887': {
        content: '',
        submitted_by: '',
        answers: ["Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Drama, Action',
        release_year: '1968',
        notable_actor: '',
        director: 'Planet of the Apes, 1968 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '888': {
        content: '',
        submitted_by: '',
        answers: ["Paranormal Activity"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2007',
        notable_actor: '',
        director: 'Paranormal Activity, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '889': {
        content: '',
        submitted_by: '',
        answers: ["13 Hours: The Secret Soldiers of Benghazi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Action, History, Drama',
        release_year: '2016',
        notable_actor: '',
        director: '13 Hours: The Secret Soldiers of Benghazi, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '890': {
        content: '',
        submitted_by: '',
        answers: ["Sausage Party"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Sausage Party, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '891': {
        content: '',
        submitted_by: '',
        answers: ["The Vatican Tapes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'The Vatican Tapes, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '892': {
        content: '',
        submitted_by: '',
        answers: ["Malena"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2000',
        notable_actor: '',
        director: 'Malena, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '893': {
        content: '',
        submitted_by: '',
        answers: ["I Spit on Your Grave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Horror',
        release_year: '2010',
        notable_actor: '',
        director: 'I Spit on Your Grave, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '894': {
        content: '',
        submitted_by: '',
        answers: ["Little Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime',
        release_year: '2006',
        notable_actor: '',
        director: 'Little Man, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '895': {
        content: '',
        submitted_by: '',
        answers: ["The Last Mercenary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'The Last Mercenary, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '896': {
        content: '',
        submitted_by: '',
        answers: ["Scouts Guide to the Zombie Apocalypse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'Scouts Guide to the Zombie Apocalypse, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '897': {
        content: '',
        submitted_by: '',
        answers: ["Sex and the City"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2008',
        notable_actor: '',
        director: 'Sex and the City, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '898': {
        content: '',
        submitted_by: '',
        answers: ["Die Hard: With a Vengeance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '1995',
        notable_actor: '',
        director: 'Die Hard: With a Vengeance, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '899': {
        content: '',
        submitted_by: '',
        answers: ["Ciao Alberto"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Ciao Alberto, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '900': {
        content: '',
        submitted_by: '',
        answers: ["The Banshees of Inisherin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Banshees of Inisherin, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '901': {
        content: '',
        submitted_by: '',
        answers: ["Bound by Honor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Drama, Thriller',
        release_year: '1993',
        notable_actor: '',
        director: 'Bound by Honor, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '902': {
        content: '',
        submitted_by: '',
        answers: ["The Hills Have Eyes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'The Hills Have Eyes, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '903': {
        content: '',
        submitted_by: '',
        answers: ["101 Dalmatians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy',
        release_year: '1996',
        notable_actor: '',
        director: '101 Dalmatians, 1996 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '904': {
        content: 'With the untimely death of his beloved wife still bitter in his mouth, John Wick, the expert former assassin, receives one final gift from her--a precious keepsake to help John find a new meaning in life now that she is gone. But when the arrogant Russian mob prince, Iosef Tarasov, and his men pay Wick a rather unwelcome visit to rob him of his prized 1969 Mustang and his wife\'s present, the legendary hitman will be forced to unearth his meticulously concealed identity. Blind with revenge, John will immediately unleash a carefully orchestrated maelstrom of destruction against the sophisticated kingpin, Viggo Tarasov, and his family, who are fully aware of his lethal capacity. Now, only blood can quench the boogeyman\'s thirst for retribution.',
        submitted_by: '',
        answers: ["John Wick"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '68% Metacritic; 7.4 IMDb',
        genre: 'Action, Thriller',
        release_year: '2014',
        notable_actor: 'Keanu Reeves',
        director: 'Chad Stahelski',
        ignore_images: 'False',
        imdb_id: 'tt2911666',
    },
    '905': {
        content: '',
        submitted_by: '',
        answers: ["Major Grom: Plague Doctor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Major Grom: Plague Doctor, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '906': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, War',
        release_year: '2007',
        notable_actor: '',
        director: '300, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '907': {
        content: '',
        submitted_by: '',
        answers: ["Mr. Harrigan's Phone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Mr. Harrigan\'s Phone, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '908': {
        content: '',
        submitted_by: '',
        answers: ["Lionheart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '1990',
        notable_actor: '',
        director: 'Lionheart, 1990 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '909': {
        content: '',
        submitted_by: '',
        answers: ["School of Rock"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Comedy, Music, Family',
        release_year: '2003',
        notable_actor: 'tbd',
        director: 'School of Rock, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '910': {
        content: '',
        submitted_by: '',
        answers: ["Central Intelligence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'Central Intelligence, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '911': {
        content: '',
        submitted_by: '',
        answers: ["The Transporter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Crime, Thriller',
        release_year: '2002',
        notable_actor: 'tdbd',
        director: 'The Transporter, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '912': {
        content: '',
        submitted_by: '',
        answers: ["Dune"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '1984',
        notable_actor: '',
        director: 'Dune, 1984 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '913': {
        content: '',
        submitted_by: '',
        answers: ["Olympus Has Fallen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Olympus Has Fallen, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '914': {
        content: '',
        submitted_by: '',
        answers: ["Jupiter Ascending"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Fantasy, Action',
        release_year: '2015',
        notable_actor: '',
        director: 'Jupiter Ascending, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '915': {
        content: '',
        submitted_by: '',
        answers: ["Trolls Holiday in Harmony"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Trolls Holiday in Harmony, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '916': {
        content: '',
        submitted_by: '',
        answers: ["Monster House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2006',
        notable_actor: '',
        director: 'Monster House, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '917': {
        content: '',
        submitted_by: '',
        answers: ["Troy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, History, War, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'Troy, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '918': {
        content: '',
        submitted_by: '',
        answers: ["Saw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Crime',
        release_year: '2004',
        notable_actor: '',
        director: 'Saw, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '919': {
        content: '',
        submitted_by: '',
        answers: ["Jeepers Creepers 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Jeepers Creepers 3, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '920': {
        content: '',
        submitted_by: '',
        answers: ["Nightcrawler"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Nightcrawler, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '921': {
        content: '',
        submitted_by: '',
        answers: ["Con Air"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '1997',
        notable_actor: '',
        director: 'Con Air, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '922': {
        content: '',
        submitted_by: '',
        answers: ["AINBO: Spirit of the Amazon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'AINBO: Spirit of the Amazon, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '923': {
        content: '',
        submitted_by: '',
        answers: ["Drive"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Crime',
        release_year: '2011',
        notable_actor: '',
        director: 'Drive, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '924': {
        content: '',
        submitted_by: '',
        answers: ["The Greatest Beer Run Ever"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy, War, History',
        release_year: '2022',
        notable_actor: '',
        director: 'The Greatest Beer Run Ever, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '925': {
        content: '',
        submitted_by: '',
        answers: ["Fear Street: 1994"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Fear Street: 1994, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '926': {
        content: '',
        submitted_by: '',
        answers: ["The Father"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'The Father, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '927': {
        content: '',
        submitted_by: '',
        answers: ["Hard Target"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime, Thriller',
        release_year: '1993',
        notable_actor: '',
        director: 'Hard Target, 1993 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '928': {
        content: '',
        submitted_by: '',
        answers: ["Ferdinand"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Ferdinand, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '929': {
        content: '',
        submitted_by: '',
        answers: ["The Last Duel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History',
        release_year: '2021',
        notable_actor: '',
        director: 'The Last Duel, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '930': {
        content: '',
        submitted_by: '',
        answers: ["Die Hard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '1988',
        notable_actor: '',
        director: 'Die Hard, 1988 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '931': {
        content: '',
        submitted_by: '',
        answers: ["Guardians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Fantasy, Science Fiction, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Guardians, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '932': {
        content: '',
        submitted_by: '',
        answers: ["Outside the Wire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Outside the Wire, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '933': {
        content: '',
        submitted_by: '',
        answers: ["Cult of Chucky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Cult of Chucky, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '934': {
        content: '',
        submitted_by: '',
        answers: ["Descendants 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, TV Movie, Music, Adventure, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Descendants 3, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '935': {
        content: '',
        submitted_by: '',
        answers: ["Clean"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'Clean, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '936': {
        content: '',
        submitted_by: '',
        answers: ["The Iron Giant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Science Fiction, Drama',
        release_year: '1999',
        notable_actor: '',
        director: 'The Iron Giant, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '937': {
        content: '',
        submitted_by: '',
        answers: ["Home Sweet Home Alone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Home Sweet Home Alone, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '938': {
        content: '',
        submitted_by: '',
        answers: ["The Loud House Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Adventure, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'The Loud House Movie, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '939': {
        content: '',
        submitted_by: '',
        answers: ["Cats & Dogs 3: Paws Unite"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Cats & Dogs 3: Paws Unite, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '940': {
        content: '',
        submitted_by: '',
        answers: ["Hot Girls Wanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2015',
        notable_actor: '',
        director: 'Hot Girls Wanted, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '941': {
        content: '',
        submitted_by: '',
        answers: ["Predestination"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Predestination, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '942': {
        content: '',
        submitted_by: '',
        answers: ["Monsters vs Aliens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Science Fiction',
        release_year: '2009',
        notable_actor: '',
        director: 'Monsters vs Aliens, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '943': {
        content: '',
        submitted_by: '',
        answers: ["The Invisible Guest"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'The Invisible Guest, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '944': {
        content: '',
        submitted_by: '',
        answers: ["Cloudy with a Chance of Meatballs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2009',
        notable_actor: '',
        director: 'Cloudy with a Chance of Meatballs, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '945': {
        content: '',
        submitted_by: '',
        answers: ["The Punisher"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '2004',
        notable_actor: '',
        director: 'The Punisher, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '946': {
        content: '',
        submitted_by: '',
        answers: ["Psycho"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Thriller',
        release_year: '1960',
        notable_actor: '',
        director: 'Psycho, 1960 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '947': {
        content: '',
        submitted_by: '',
        answers: ["Jeepers Creepers 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2003',
        notable_actor: '',
        director: 'Jeepers Creepers 2, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '948': {
        content: '',
        submitted_by: '',
        answers: ["Southpaw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Southpaw, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '949': {
        content: '',
        submitted_by: '',
        answers: ["Time Trap"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Time Trap, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '950': {
        content: '',
        submitted_by: '',
        answers: ["Hachi: A Dog's Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Family',
        release_year: '2009',
        notable_actor: '',
        director: 'Hachi: A Dog\'s Tale, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '951': {
        content: '',
        submitted_by: '',
        answers: ["A Dog's Journey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'A Dog\'s Journey, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '952': {
        content: '',
        submitted_by: '',
        answers: ["Dead Silence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2007',
        notable_actor: '',
        director: 'Dead Silence, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '953': {
        content: '',
        submitted_by: '',
        answers: ["Underworld"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Thriller',
        release_year: '2003',
        notable_actor: '',
        director: 'Underworld, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '954': {
        content: '',
        submitted_by: '',
        answers: ["The Book of Life"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Book of Life, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '955': {
        content: '',
        submitted_by: '',
        answers: ["An Interview with God"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '2018',
        notable_actor: '',
        director: 'An Interview with God, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '956': {
        content: '',
        submitted_by: '',
        answers: ["Black Crab"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Black Crab, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '957': {
        content: '',
        submitted_by: '',
        answers: ["X-Men: Days of Future Past"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'X-Men: Days of Future Past, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '958': {
        content: '',
        submitted_by: '',
        answers: ["Suicide Squad"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Suicide Squad, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '959': {
        content: '',
        submitted_by: '',
        answers: ["Red Shoes and the Seven Dwarfs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Romance, Family, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Red Shoes and the Seven Dwarfs, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '960': {
        content: '',
        submitted_by: '',
        answers: ["Arrival"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'Arrival, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '961': {
        content: '',
        submitted_by: '',
        answers: ["Kickboxer: Retaliation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'Kickboxer: Retaliation, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '962': {
        content: '',
        submitted_by: '',
        answers: ["The Godfather Part III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '1990',
        notable_actor: '',
        director: 'The Godfather Part III, 1990 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '963': {
        content: '',
        submitted_by: '',
        answers: ["Love & Other Drugs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'Love & Other Drugs, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '964': {
        content: '',
        submitted_by: '',
        answers: ["Honor Society"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Honor Society, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '965': {
        content: '',
        submitted_by: '',
        answers: ["Peter Rabbit 2: The Runaway"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Adventure, Animation, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Peter Rabbit 2: The Runaway, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '966': {
        content: '',
        submitted_by: '',
        answers: ["Solo: A Star Wars Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Solo: A Star Wars Story, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '967': {
        content: '',
        submitted_by: '',
        answers: ["Once Upon a Snowman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Once Upon a Snowman, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '968': {
        content: '',
        submitted_by: '',
        answers: ["Teen Wolf"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy, Romance',
        release_year: '1985',
        notable_actor: '',
        director: 'Teen Wolf, 1985 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '969': {
        content: '',
        submitted_by: '',
        answers: ["A Monster Calls"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'A Monster Calls, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '970': {
        content: '',
        submitted_by: '',
        answers: ["The Son"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'The Son, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '971': {
        content: '',
        submitted_by: '',
        answers: ["Primer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Thriller',
        release_year: '2004',
        notable_actor: '',
        director: 'Primer, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '972': {
        content: '',
        submitted_by: '',
        answers: ["No Strings Attached"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'No Strings Attached, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '973': {
        content: '',
        submitted_by: '',
        answers: ["Me Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Me Time, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '974': {
        content: '',
        submitted_by: '',
        answers: ["Room in Rome"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'Room in Rome, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '975': {
        content: '',
        submitted_by: '',
        answers: ["Resident Evil: Damnation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Horror, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'Resident Evil: Damnation, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '976': {
        content: '',
        submitted_by: '',
        answers: ["Looper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Looper, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '977': {
        content: '',
        submitted_by: '',
        answers: ["Castle in the Sky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Animation, Action, Family, Romance',
        release_year: '1986',
        notable_actor: '',
        director: 'Castle in the Sky, 1986 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '978': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '1996',
        notable_actor: '',
        director: 'Mission: Impossible, 1996 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '979': {
        content: '',
        submitted_by: '',
        answers: ["Cannibal Holocaust"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1980',
        notable_actor: '',
        director: 'Cannibal Holocaust, 1980 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '980': {
        content: '',
        submitted_by: '',
        answers: ["Eternal Sunshine of the Spotless Mind"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Romance',
        release_year: '2004',
        notable_actor: '',
        director: 'Eternal Sunshine of the Spotless Mind, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '981': {
        content: '',
        submitted_by: '',
        answers: ["Robin Hood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Drama',
        release_year: '2010',
        notable_actor: '',
        director: 'Robin Hood, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '982': {
        content: '',
        submitted_by: '',
        answers: ["The Addams Family"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'The Addams Family, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '983': {
        content: '',
        submitted_by: '',
        answers: ["Bram Stoker's Dracula"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Horror',
        release_year: '1992',
        notable_actor: '',
        director: 'Bram Stoker\'s Dracula, 1992 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '984': {
        content: '',
        submitted_by: '',
        answers: ["Descendants"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Music, Adventure, Comedy, Fantasy, TV Movie',
        release_year: '2015',
        notable_actor: '',
        director: 'Descendants, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '985': {
        content: '',
        submitted_by: '',
        answers: ["Pocahontas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Romance',
        release_year: '1995',
        notable_actor: '',
        director: 'Pocahontas, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '986': {
        content: '',
        submitted_by: '',
        answers: ["Gone Girl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller, Drama',
        release_year: '2014',
        notable_actor: '',
        director: 'Gone Girl, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '987': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons: The Good, the Bart, and the Loki"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'The Simpsons: The Good, the Bart, and the Loki, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '988': {
        content: '',
        submitted_by: '',
        answers: ["Vesper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Vesper, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '989': {
        content: '',
        submitted_by: '',
        answers: ["Angel Has Fallen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Angel Has Fallen, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '990': {
        content: '',
        submitted_by: '',
        answers: ["Carriers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Horror, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Carriers, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '991': {
        content: '',
        submitted_by: '',
        answers: ["The Imitation Game"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'History, Drama, Thriller, War',
        release_year: '2014',
        notable_actor: '',
        director: 'The Imitation Game, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '992': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible - Rogue Nation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Mission: Impossible - Rogue Nation, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '993': {
        content: '',
        submitted_by: '',
        answers: ["Along with the Gods: The Two Worlds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Along with the Gods: The Two Worlds, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '994': {
        content: '',
        submitted_by: '',
        answers: ["Where the Crawdads Sing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Drama, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Where the Crawdads Sing, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '995': {
        content: '',
        submitted_by: '',
        answers: ["Wrath of the Titans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Wrath of the Titans, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '996': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Girl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Sweet Girl, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '997': {
        content: '',
        submitted_by: '',
        answers: ["The Ten Commandments"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '1956',
        notable_actor: '',
        director: 'The Ten Commandments, 1956 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '998': {
        content: '',
        submitted_by: '',
        answers: ["Empire of Light"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Empire of Light, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '999': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Great Fairy Rescue"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'Tinker Bell and the Great Fairy Rescue, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1000': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Awakening"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Horror',
        release_year: '2012',
        notable_actor: '',
        director: 'Underworld: Awakening, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1001': {
        content: '',
        submitted_by: '',
        answers: ["Redeeming Love"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama, History',
        release_year: '2022',
        notable_actor: '',
        director: 'Redeeming Love, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1002': {
        content: '',
        submitted_by: '',
        answers: ["The Exorcism of Emily Rose"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Horror, Thriller',
        release_year: '2005',
        notable_actor: '',
        director: 'The Exorcism of Emily Rose, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1003': {
        content: '',
        submitted_by: '',
        answers: ["End of the Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'End of the Road, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1004': {
        content: '',
        submitted_by: '',
        answers: ["Halloween"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '1978',
        notable_actor: '',
        director: 'Halloween, 1978 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1005': {
        content: '',
        submitted_by: '',
        answers: ["Rush Hour 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2007',
        notable_actor: '',
        director: 'Rush Hour 3, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1006': {
        content: '',
        submitted_by: '',
        answers: ["#Alive"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Adventure, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: '#Alive, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1007': {
        content: '',
        submitted_by: '',
        answers: ["Copshop"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Copshop, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1008': {
        content: '',
        submitted_by: '',
        answers: ["Brazen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Brazen, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1009': {
        content: '',
        submitted_by: '',
        answers: ["Baby's Day Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Adventure, Crime, Drama',
        release_year: '1994',
        notable_actor: '',
        director: 'Baby\'s Day Out, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1010': {
        content: '',
        submitted_by: '',
        answers: ["Speed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime',
        release_year: '1994',
        notable_actor: '',
        director: 'Speed, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1011': {
        content: '',
        submitted_by: '',
        answers: ["Frequency"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2000',
        notable_actor: '',
        director: 'Frequency, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1012': {
        content: '',
        submitted_by: '',
        answers: ["300: Rise of an Empire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, War',
        release_year: '2014',
        notable_actor: '',
        director: '300: Rise of an Empire, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1013': {
        content: '',
        submitted_by: '',
        answers: ["I Want to Eat Your Pancreas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Romance',
        release_year: '2018',
        notable_actor: '',
        director: 'I Want to Eat Your Pancreas, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1014': {
        content: '',
        submitted_by: '',
        answers: ["L\u00e9on: The Professional"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Action',
        release_year: '1994',
        notable_actor: '',
        director: 'Léon: The Professional, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1015': {
        content: '',
        submitted_by: '',
        answers: ["Back to the Future Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Science Fiction',
        release_year: '1989',
        notable_actor: '',
        director: 'Back to the Future Part II, 1989 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1016': {
        content: '',
        submitted_by: '',
        answers: ["Star Trek"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2009',
        notable_actor: '',
        director: 'Star Trek, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1017': {
        content: '',
        submitted_by: '',
        answers: ["The Sword in the Stone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1963',
        notable_actor: '',
        director: 'The Sword in the Stone, 1963 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1018': {
        content: '',
        submitted_by: '',
        answers: ["Dual"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Dual, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1019': {
        content: '',
        submitted_by: '',
        answers: ["Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Science Fiction, Action, Adventure',
        release_year: '2001',
        notable_actor: '',
        director: 'Planet of the Apes, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1020': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Mission: Impossible III, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1021': {
        content: '',
        submitted_by: '',
        answers: ["Hellboy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Horror, Action, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'Hellboy, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1022': {
        content: '',
        submitted_by: '',
        answers: ["Pet Sematary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama',
        release_year: '1989',
        notable_actor: '',
        director: 'Pet Sematary, 1989 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1023': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Episode II - Attack of the Clones"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: 'Star Wars: Episode II - Attack of the Clones, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1024': {
        content: '',
        submitted_by: '',
        answers: ["Redemption Day"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Redemption Day, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1025': {
        content: '',
        submitted_by: '',
        answers: ["Child's Play 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '1990',
        notable_actor: '',
        director: 'Child\'s Play 2, 1990 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1026': {
        content: '',
        submitted_by: '',
        answers: ["Anastasia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '1997',
        notable_actor: '',
        director: 'Anastasia, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1027': {
        content: '',
        submitted_by: '',
        answers: ["Insidious: The Last Key"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Insidious: The Last Key, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1028': {
        content: '',
        submitted_by: '',
        answers: ["101 Dalmatians II: Patch's London Adventure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Comedy, Animation',
        release_year: '2002',
        notable_actor: '',
        director: '101 Dalmatians II: Patch\'s London Adventure, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1029': {
        content: '',
        submitted_by: '',
        answers: ["The Texas Chainsaw Massacre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2003',
        notable_actor: '',
        director: 'The Texas Chainsaw Massacre, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1030': {
        content: '',
        submitted_by: '',
        answers: ["Virgin Territory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Comedy, Romance',
        release_year: '2007',
        notable_actor: '',
        director: 'Virgin Territory, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1031': {
        content: '',
        submitted_by: '',
        answers: ["Big"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Comedy, Romance, Family',
        release_year: '1988',
        notable_actor: '',
        director: 'Big, 1988 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1032': {
        content: '',
        submitted_by: '',
        answers: ["The Wonder"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'The Wonder, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1033': {
        content: '',
        submitted_by: '',
        answers: ["Once Upon a Time\u2026 in Hollywood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Once Upon a Time… in Hollywood, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1034': {
        content: '',
        submitted_by: '',
        answers: ["Shark Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'Shark Tale, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1035': {
        content: '',
        submitted_by: '',
        answers: ["Princess Mononoke"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Animation',
        release_year: '1997',
        notable_actor: '',
        director: 'Princess Mononoke, 1997 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1036': {
        content: '',
        submitted_by: '',
        answers: ["Gods of Egypt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Gods of Egypt, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1037': {
        content: '',
        submitted_by: '',
        answers: ["Dolittle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Adventure',
        release_year: '2020',
        notable_actor: '',
        director: 'Dolittle, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1038': {
        content: '',
        submitted_by: '',
        answers: ["Casino Royale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Casino Royale, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1039': {
        content: '',
        submitted_by: '',
        answers: ["Onward"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Onward, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1040': {
        content: '',
        submitted_by: '',
        answers: ["Edge of Tomorrow"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Edge of Tomorrow, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1041': {
        content: '',
        submitted_by: '',
        answers: ["Amsterdam"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Thriller, Mystery, History',
        release_year: '2022',
        notable_actor: '',
        director: 'Amsterdam, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1042': {
        content: '',
        submitted_by: '',
        answers: ["Bridge to Terabithia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Family',
        release_year: '2007',
        notable_actor: '',
        director: 'Bridge to Terabithia, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1043': {
        content: '',
        submitted_by: '',
        answers: ["Saw II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2005',
        notable_actor: '',
        director: 'Saw II, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1044': {
        content: '',
        submitted_by: '',
        answers: ["The Gentlemen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'The Gentlemen, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1045': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination 5"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2011',
        notable_actor: '',
        director: 'Final Destination 5, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1046': {
        content: '',
        submitted_by: '',
        answers: ["Mother/Android"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Mother/Android, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1047': {
        content: '',
        submitted_by: '',
        answers: ["Regular Show: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Science Fiction, TV Movie',
        release_year: '2015',
        notable_actor: '',
        director: 'Regular Show: The Movie, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1048': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2003',
        notable_actor: '',
        director: 'Final Destination 2, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1049': {
        content: '',
        submitted_by: '',
        answers: ["The Patriot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, War, Action',
        release_year: '2000',
        notable_actor: '',
        director: 'The Patriot, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1050': {
        content: '',
        submitted_by: '',
        answers: ["Interceptor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Interceptor, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1051': {
        content: '',
        submitted_by: '',
        answers: ["Apex"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Apex, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1052': {
        content: '',
        submitted_by: '',
        answers: ["Now You See Me 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Thriller, Action, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'Now You See Me 2, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1053': {
        content: '',
        submitted_by: '',
        answers: ["Fistful of Vengeance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Fistful of Vengeance, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1054': {
        content: '',
        submitted_by: '',
        answers: ["Fireproof"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2008',
        notable_actor: '',
        director: 'Fireproof, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1055': {
        content: '',
        submitted_by: '',
        answers: ["Hidden"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'Hidden, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1056': {
        content: '',
        submitted_by: '',
        answers: ["Den of Thieves"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2018',
        notable_actor: '',
        director: 'Den of Thieves, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1057': {
        content: '',
        submitted_by: '',
        answers: ["Chicken Run"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2000',
        notable_actor: '',
        director: 'Chicken Run, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1058': {
        content: '',
        submitted_by: '',
        answers: ["Snow White and the Huntsman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama',
        release_year: '2012',
        notable_actor: '',
        director: 'Snow White and the Huntsman, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1059': {
        content: '',
        submitted_by: '',
        answers: ["The Purge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Horror, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'The Purge, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1060': {
        content: '',
        submitted_by: '',
        answers: ["Missing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2023',
        notable_actor: '',
        director: 'Missing, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1061': {
        content: '',
        submitted_by: '',
        answers: ["Return of the Jedi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1983',
        notable_actor: '',
        director: 'Return of the Jedi, 1983 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1062': {
        content: '',
        submitted_by: '',
        answers: ["A Nightmare on Elm Street"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1984',
        notable_actor: '',
        director: 'A Nightmare on Elm Street, 1984 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1063': {
        content: '',
        submitted_by: '',
        answers: ["Rocky II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1979',
        notable_actor: '',
        director: 'Rocky II, 1979 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1064': {
        content: '',
        submitted_by: '',
        answers: ["2001: A Space Odyssey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Mystery, Adventure',
        release_year: '1968',
        notable_actor: '',
        director: '2001: A Space Odyssey, 1968 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1065': {
        content: '',
        submitted_by: '',
        answers: ["Despicable Me 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Animation, Comedy, Family, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Despicable Me 3, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1066': {
        content: '',
        submitted_by: '',
        answers: ["Full Metal Jacket"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '1987',
        notable_actor: '',
        director: 'Full Metal Jacket, 1987 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1067': {
        content: '',
        submitted_by: '',
        answers: ["Ticket to Paradise"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Ticket to Paradise, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1068': {
        content: '',
        submitted_by: '',
        answers: ["Friday the 13th"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1980',
        notable_actor: '',
        director: 'Friday the 13th, 1980 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1069': {
        content: '',
        submitted_by: '',
        answers: ["Wonder"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Family',
        release_year: '2017',
        notable_actor: '',
        director: 'Wonder, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1070': {
        content: '',
        submitted_by: '',
        answers: ["Jack the Giant Slayer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure, Family',
        release_year: '2013',
        notable_actor: '',
        director: 'Jack the Giant Slayer, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1071': {
        content: '',
        submitted_by: '',
        answers: ["Cowboys & Aliens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller, Western',
        release_year: '2011',
        notable_actor: '',
        director: 'Cowboys & Aliens, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1072': {
        content: '',
        submitted_by: '',
        answers: ["Hotel Transylvania 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2015',
        notable_actor: '',
        director: 'Hotel Transylvania 2, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1073': {
        content: '',
        submitted_by: '',
        answers: ["Army of Darkness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Horror, Comedy',
        release_year: '1992',
        notable_actor: '',
        director: 'Army of Darkness, 1992 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1074': {
        content: '',
        submitted_by: '',
        answers: ["Pan's Labyrinth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, War',
        release_year: '2006',
        notable_actor: '',
        director: 'Pan\'s Labyrinth, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1075': {
        content: '',
        submitted_by: '',
        answers: ["The Emoji Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 12%; User: 2.1',
        genre: 'Animation, Family, Comedy',
        release_year: '2017',
        notable_actor: 'T.J. Miller',
        director: 'Tony Leondis',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1076': {
        content: '',
        submitted_by: '',
        answers: ["The BFG"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'The BFG, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1077': {
        content: '',
        submitted_by: '',
        answers: ["The Dictator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2012',
        notable_actor: '',
        director: 'The Dictator, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1078': {
        content: '',
        submitted_by: '',
        answers: ["The Protector"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2005',
        notable_actor: '',
        director: 'The Protector, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1079': {
        content: '',
        submitted_by: '',
        answers: ["Zombieland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2009',
        notable_actor: '',
        director: 'Zombieland, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1080': {
        content: '',
        submitted_by: '',
        answers: ["What Happened to Monday"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller, Drama, Mystery, Action',
        release_year: '2017',
        notable_actor: '',
        director: 'What Happened to Monday, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1081': {
        content: '',
        submitted_by: '',
        answers: ["The Good Nurse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'The Good Nurse, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1082': {
        content: '',
        submitted_by: '',
        answers: ["Zombieland: Double Tap"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2019',
        notable_actor: '',
        director: 'Zombieland: Double Tap, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1083': {
        content: '',
        submitted_by: '',
        answers: ["R.I.P.D."],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Comedy, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'R.I.P.D., 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1084': {
        content: '',
        submitted_by: '',
        answers: ["Bones and All"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Bones and All, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1085': {
        content: '',
        submitted_by: '',
        answers: ["Finding Dory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Finding Dory, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1086': {
        content: '',
        submitted_by: '',
        answers: ["Kiki's Delivery Service"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '1989',
        notable_actor: '',
        director: 'Kiki\'s Delivery Service, 1989 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1087': {
        content: '',
        submitted_by: '',
        answers: ["Willy Wonka & the Chocolate Factory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Comedy',
        release_year: '1971',
        notable_actor: '',
        director: 'Willy Wonka & the Chocolate Factory, 1971 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1088': {
        content: '',
        submitted_by: '',
        answers: ["Captain America: The Winter Soldier"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Captain America: The Winter Soldier, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1089': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Rise of the Lycans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Underworld: Rise of the Lycans, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1090': {
        content: '',
        submitted_by: '',
        answers: ["Mortal Kombat Legends: Battle of the Realms"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Mortal Kombat Legends: Battle of the Realms, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1091': {
        content: '',
        submitted_by: '',
        answers: ["Spectre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Spectre, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1092': {
        content: '',
        submitted_by: '',
        answers: ["Antlers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'Antlers, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1093': {
        content: '',
        submitted_by: '',
        answers: ["Drag Me to Hell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Drag Me to Hell, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1094': {
        content: '',
        submitted_by: '',
        answers: ["Paul"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Paul, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1095': {
        content: '',
        submitted_by: '',
        answers: ["Deuce Bigalow: Male Gigolo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '1999',
        notable_actor: '',
        director: 'Deuce Bigalow: Male Gigolo, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1096': {
        content: '',
        submitted_by: '',
        answers: ["The Cat in the Hat"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Family, Fantasy',
        release_year: '2003',
        notable_actor: '',
        director: 'The Cat in the Hat, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1097': {
        content: '',
        submitted_by: '',
        answers: ["The Butterfly Effect"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2004',
        notable_actor: '',
        director: 'The Butterfly Effect, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1098': {
        content: '',
        submitted_by: '',
        answers: ["Death Race 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'Death Race 2, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1099': {
        content: '',
        submitted_by: '',
        answers: ["Mulan II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'Mulan II, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1100': {
        content: '',
        submitted_by: '',
        answers: ["Hostel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2005',
        notable_actor: '',
        director: 'Hostel, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1101': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Mutant Ninja Turtles: Out of the Shadows"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Family, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Teenage Mutant Ninja Turtles: Out of the Shadows, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1102': {
        content: '',
        submitted_by: '',
        answers: ["Happy Feet Two"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Happy Feet Two, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1103': {
        content: '',
        submitted_by: '',
        answers: ["Sherlock Holmes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime, Mystery',
        release_year: '2009',
        notable_actor: '',
        director: 'Sherlock Holmes, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1104': {
        content: '',
        submitted_by: '',
        answers: ["Salt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Salt, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1105': {
        content: '',
        submitted_by: '',
        answers: ["Upside-Down Magic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Family, TV Movie',
        release_year: '2020',
        notable_actor: '',
        director: 'Upside-Down Magic, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1106': {
        content: '',
        submitted_by: '',
        answers: ["Nobody Sleeps in the Woods Tonight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Nobody Sleeps in the Woods Tonight, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1107': {
        content: '',
        submitted_by: '',
        answers: ["Space Jam"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Family, Animation, Comedy, Science Fiction',
        release_year: '1996',
        notable_actor: 'tbd',
        director: 'Space Jam, 1996 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1108': {
        content: '',
        submitted_by: '',
        answers: ["Cherry"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '2021',
        notable_actor: '',
        director: 'Cherry, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1109': {
        content: '',
        submitted_by: '',
        answers: ["Thir13en Ghosts"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2001',
        notable_actor: '',
        director: 'Thir13en Ghosts, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1110': {
        content: '',
        submitted_by: '',
        answers: ["Ocean's Eight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Comedy, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Ocean\'s Eight, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1111': {
        content: '',
        submitted_by: '',
        answers: ["Odd Thomas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Odd Thomas, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1112': {
        content: '',
        submitted_by: '',
        answers: ["Journey to the Center of the Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure, Comedy, Family',
        release_year: '2008',
        notable_actor: '',
        director: 'Journey to the Center of the Earth, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1113': {
        content: '',
        submitted_by: '',
        answers: ["A Day to Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'A Day to Die, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1114': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '1998',
        notable_actor: '',
        director: 'Godzilla, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1115': {
        content: '',
        submitted_by: '',
        answers: ["Aliens vs Predator: Requiem"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Science Fiction, Thriller, Horror',
        release_year: '2007',
        notable_actor: '',
        director: 'Aliens vs Predator: Requiem, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1116': {
        content: '',
        submitted_by: '',
        answers: ["Beetlejuice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy',
        release_year: '1988',
        notable_actor: '',
        director: 'Beetlejuice, 1988 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1117': {
        content: '',
        submitted_by: '',
        answers: ["Blonde"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Blonde, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1118': {
        content: '',
        submitted_by: '',
        answers: ["The Lego Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Lego Movie, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1119': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2006',
        notable_actor: '',
        director: 'Final Destination 3, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1120': {
        content: '',
        submitted_by: '',
        answers: ["Cosmic Sin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Cosmic Sin, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1121': {
        content: '',
        submitted_by: '',
        answers: ["Colombiana"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime, Drama',
        release_year: '2011',
        notable_actor: '',
        director: 'Colombiana, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1122': {
        content: '',
        submitted_by: '',
        answers: ["The Pianist"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '2002',
        notable_actor: '',
        director: 'The Pianist, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1123': {
        content: '',
        submitted_by: '',
        answers: ["Woody Woodpecker"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Animation',
        release_year: '2017',
        notable_actor: '',
        director: 'Woody Woodpecker, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1124': {
        content: '',
        submitted_by: '',
        answers: ["Shaolin Soccer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy',
        release_year: '2001',
        notable_actor: '',
        director: 'Shaolin Soccer, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1125': {
        content: '',
        submitted_by: '',
        answers: ["Treasure Planet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Fantasy, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: 'Treasure Planet, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1126': {
        content: '',
        submitted_by: '',
        answers: ["Fifty Shades of Black"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'Fifty Shades of Black, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1127': {
        content: '',
        submitted_by: '',
        answers: ["We're the Millers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'We\'re the Millers, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1128': {
        content: '',
        submitted_by: '',
        answers: ["The Empire Strikes Back"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1980',
        notable_actor: '',
        director: 'The Empire Strikes Back, 1980 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1129': {
        content: '',
        submitted_by: '',
        answers: ["Limitless"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Limitless, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1130': {
        content: '',
        submitted_by: '',
        answers: ["Ghost Ship"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2002',
        notable_actor: '',
        director: 'Ghost Ship, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1131': {
        content: '',
        submitted_by: '',
        answers: ["Friends with Benefits"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'Friends with Benefits, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1132': {
        content: '',
        submitted_by: '',
        answers: ["Bohemian Rhapsody"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Music, Drama, History',
        release_year: '2018',
        notable_actor: '',
        director: 'Bohemian Rhapsody, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1133': {
        content: '',
        submitted_by: '',
        answers: ["Scott Pilgrim vs. the World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'Scott Pilgrim vs. the World, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1134': {
        content: '',
        submitted_by: '',
        answers: ["Heaven Is for Real"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2014',
        notable_actor: '',
        director: 'Heaven Is for Real, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1135': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella III: A Twist in Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Romance, Family, Fantasy',
        release_year: '2007',
        notable_actor: '',
        director: 'Cinderella III: A Twist in Time, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1136': {
        content: '',
        submitted_by: '',
        answers: ["Pearl Harbor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, War, History, Romance, Drama',
        release_year: '2001',
        notable_actor: '',
        director: 'Pearl Harbor, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1137': {
        content: '',
        submitted_by: '',
        answers: ["About Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'About Time, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1138': {
        content: '',
        submitted_by: '',
        answers: ["Fortress"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2021',
        notable_actor: '',
        director: 'Fortress, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1139': {
        content: '',
        submitted_by: '',
        answers: ["Brian Banks"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Brian Banks, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1140': {
        content: '',
        submitted_by: '',
        answers: ["The Tax Collector"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'The Tax Collector, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1141': {
        content: '',
        submitted_by: '',
        answers: ["Friday"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '1995',
        notable_actor: '',
        director: 'Friday, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1142': {
        content: '',
        submitted_by: '',
        answers: ["The Call of the Wild"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'The Call of the Wild, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1143': {
        content: '',
        submitted_by: '',
        answers: ["Mannequin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy, Romance',
        release_year: '1987',
        notable_actor: '',
        director: 'Mannequin, 1987 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1144': {
        content: '',
        submitted_by: '',
        answers: ["Black Hawk Down"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, History, War',
        release_year: '2001',
        notable_actor: 'tbd',
        director: 'Black Hawk Down, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1145': {
        content: '',
        submitted_by: '',
        answers: ["The In Between"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Science Fiction, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'The In Between, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1146': {
        content: '',
        submitted_by: '',
        answers: ["Upgrade"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Upgrade, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1147': {
        content: '',
        submitted_by: '',
        answers: ["Braveheart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History, War',
        release_year: '1995',
        notable_actor: '',
        director: 'Braveheart, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1148': {
        content: '',
        submitted_by: '',
        answers: ["Dumb and Dumber To"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Dumb and Dumber To, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1149': {
        content: '',
        submitted_by: '',
        answers: ["Vanquish"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Vanquish, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1150': {
        content: '',
        submitted_by: '',
        answers: ["Fractured"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Fractured, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1151': {
        content: '',
        submitted_by: '',
        answers: ["Joseph: King of Dreams"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation',
        release_year: '2000',
        notable_actor: '',
        director: 'Joseph: King of Dreams, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1152': {
        content: '',
        submitted_by: '',
        answers: ["6 Underground"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: '6 Underground, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1153': {
        content: '',
        submitted_by: '',
        answers: ["The Handmaiden"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Romance',
        release_year: '2016',
        notable_actor: '',
        director: 'The Handmaiden, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1154': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: Band Camp"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2005',
        notable_actor: '',
        director: 'American Pie Presents: Band Camp, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1155': {
        content: '',
        submitted_by: '',
        answers: ["Into the Wild"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama',
        release_year: '2007',
        notable_actor: '',
        director: 'Into the Wild, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1156': {
        content: '',
        submitted_by: '',
        answers: ["Jurassic Hunt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Jurassic Hunt, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1157': {
        content: '',
        submitted_by: '',
        answers: ["22 vs. Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Fantasy, Animation',
        release_year: '2021',
        notable_actor: '',
        director: '22 vs. Earth, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1158': {
        content: '',
        submitted_by: '',
        answers: ["Justice League Dark"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Justice League Dark, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1159': {
        content: '',
        submitted_by: '',
        answers: ["Freddy vs. Jason"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2003',
        notable_actor: '',
        director: 'Freddy vs. Jason, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1160': {
        content: '',
        submitted_by: '',
        answers: ["12 Angry Men"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1957',
        notable_actor: '',
        director: '12 Angry Men, 1957 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1161': {
        content: '',
        submitted_by: '',
        answers: ["The Matrix Reloaded"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '2003',
        notable_actor: '',
        director: 'The Matrix Reloaded, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1162': {
        content: '',
        submitted_by: '',
        answers: ["Fantasia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1940',
        notable_actor: '',
        director: 'Fantasia, 1940 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1163': {
        content: '',
        submitted_by: '',
        answers: ["Last Shift"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2014',
        notable_actor: '',
        director: 'Last Shift, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1164': {
        content: '',
        submitted_by: '',
        answers: ["The Human Centipede (First Sequence)"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2009',
        notable_actor: '',
        director: 'The Human Centipede (First Sequence), 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1165': {
        content: '',
        submitted_by: '',
        answers: ["Pamela, A Love Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2023',
        notable_actor: '',
        director: 'Pamela, A Love Story, 2023 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1166': {
        content: '',
        submitted_by: '',
        answers: ["SAS: Red Notice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'SAS: Red Notice, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1167': {
        content: '',
        submitted_by: '',
        answers: ["Indiana Jones and the Kingdom of the Crystal Skull"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action',
        release_year: '2008',
        notable_actor: '',
        director: 'Indiana Jones and the Kingdom of the Crystal Skull, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1168': {
        content: '',
        submitted_by: '',
        answers: ["28 Days Later"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: '28 Days Later, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1169': {
        content: 'Voldemort\'s (Ralph Fiennes\') power is growing stronger. He now has control over the Ministry of Magic and Hogwarts. Harry (Daniel Radcliffe), Ron (Rupert Grint), and Hermione (Emma Watson) decide to finish Dumbledore\'s (Sir Michael Gambon\'s) work and find the rest of the Horcruxes to defeat the Dark Lord. But little hope remains for the trio and the rest of the Wizarding World, so everything they do must go as planned.',
        submitted_by: '',
        answers: ["Harry Potter and the Deathly Hallows: Part 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '65% Metacritic; 7.7 IMDb',
        genre: 'Adventure, Fantasy',
        release_year: '2010',
        notable_actor: 'Daniel Radcliffe',
        director: 'David Yates',
        ignore_images: 'False',
        imdb_id: 'tt0926084',
    },
    '1170': {
        content: '',
        submitted_by: '',
        answers: ["Ghostland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Ghostland, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1171': {
        content: '',
        submitted_by: '',
        answers: ["Kubo and the Two Strings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Kubo and the Two Strings, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1172': {
        content: '',
        submitted_by: '',
        answers: ["Hunter Killer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Hunter Killer, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1173': {
        content: '',
        submitted_by: '',
        answers: ["American Graffiti"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '1973',
        notable_actor: '',
        director: 'American Graffiti, 1973 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1174': {
        content: '',
        submitted_by: '',
        answers: ["The Human Centipede 2 (Full Sequence)"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Crime',
        release_year: '2011',
        notable_actor: '',
        director: 'The Human Centipede 2 (Full Sequence), 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1175': {
        content: '',
        submitted_by: '',
        answers: ["His House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'His House, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1176': {
        content: '',
        submitted_by: '',
        answers: ["Orphan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2009',
        notable_actor: '',
        director: 'Orphan, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1177': {
        content: '',
        submitted_by: '',
        answers: ["The SpongeBob Movie: Sponge Out of Water"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Comedy, Fantasy, Animation',
        release_year: '2015',
        notable_actor: '',
        director: 'The SpongeBob Movie: Sponge Out of Water, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1178': {
        content: '',
        submitted_by: '',
        answers: ["Crawl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Crawl, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1179': {
        content: '',
        submitted_by: '',
        answers: ["Jason Bourne"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Thriller, Mystery',
        release_year: '2016',
        notable_actor: 'tbd',
        director: 'Jason Bourne, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1180': {
        content: '',
        submitted_by: '',
        answers: ["Focus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy, Crime, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Focus, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1181': {
        content: '',
        submitted_by: '',
        answers: ["Tears of the Sun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Action, Drama',
        release_year: '2003',
        notable_actor: '',
        director: 'Tears of the Sun, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1182': {
        content: '',
        submitted_by: '',
        answers: ["A Walk Among the Tombstones"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Mystery, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'A Walk Among the Tombstones, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1183': {
        content: '',
        submitted_by: '',
        answers: ["The Princess Diaries"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Romance',
        release_year: '2001',
        notable_actor: '',
        director: 'The Princess Diaries, 2001 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1184': {
        content: '',
        submitted_by: '',
        answers: ["The Lobster"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2015',
        notable_actor: '',
        director: 'The Lobster, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1185': {
        content: '',
        submitted_by: '',
        answers: ["Bordello of Blood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Comedy',
        release_year: '1996',
        notable_actor: '',
        director: 'Bordello of Blood, 1996 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1186': {
        content: '',
        submitted_by: '',
        answers: ["50 First Dates"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2004',
        notable_actor: '',
        director: '50 First Dates, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1187': {
        content: '',
        submitted_by: '',
        answers: ["Men in Black II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: 'Men in Black II, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1188': {
        content: '',
        submitted_by: '',
        answers: ["Blood and Bone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Blood and Bone, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1189': {
        content: '',
        submitted_by: '',
        answers: ["American Gangster"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Drama, Crime',
        release_year: '2007',
        notable_actor: 'tbd',
        director: 'American Gangster, 2007 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1190': {
        content: '',
        submitted_by: '',
        answers: ["The Searchers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '1956',
        notable_actor: '',
        director: 'The Searchers, 1956 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1191': {
        content: '',
        submitted_by: '',
        answers: ["Jigsaw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Crime, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Jigsaw, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1192': {
        content: '',
        submitted_by: '',
        answers: ["Baby Driver"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime',
        release_year: '2017',
        notable_actor: '',
        director: 'Baby Driver, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1193': {
        content: '',
        submitted_by: '',
        answers: ["Noah"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Adventure',
        release_year: '2014',
        notable_actor: '',
        director: 'Noah, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1194': {
        content: '',
        submitted_by: '',
        answers: ["21 Jump Street"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2012',
        notable_actor: '',
        director: '21 Jump Street, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1195': {
        content: '',
        submitted_by: '',
        answers: ["Armageddon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction, Adventure',
        release_year: '1998',
        notable_actor: '',
        director: 'Armageddon, 1998 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1196': {
        content: '',
        submitted_by: '',
        answers: ["10 Things I Hate About You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance, Drama',
        release_year: '1999',
        notable_actor: '',
        director: '10 Things I Hate About You, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1197': {
        content: '',
        submitted_by: '',
        answers: ["I Came By"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'I Came By, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1198': {
        content: '',
        submitted_by: '',
        answers: ["Silent Hill"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2006',
        notable_actor: '',
        director: 'Silent Hill, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1199': {
        content: '',
        submitted_by: '',
        answers: ["Whitney Houston: I Wanna Dance with Somebody"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Music, History, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Whitney Houston: I Wanna Dance with Somebody, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1200': {
        content: '',
        submitted_by: '',
        answers: ["Mr. & Mrs. Smith"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Comedy, Drama, Thriller',
        release_year: '2005',
        notable_actor: 'tbd',
        director: 'Mr. & Mrs. Smith, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1201': {
        content: '',
        submitted_by: '',
        answers: ["The Final Destination"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2009',
        notable_actor: '',
        director: 'The Final Destination, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1202': {
        content: '',
        submitted_by: '',
        answers: ["Candyman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Candyman, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1203': {
        content: '',
        submitted_by: '',
        answers: ["Whisper of the Heart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Family',
        release_year: '1995',
        notable_actor: '',
        director: 'Whisper of the Heart, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1204': {
        content: '',
        submitted_by: '',
        answers: ["Last Night in Soho"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Last Night in Soho, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1205': {
        content: '',
        submitted_by: '',
        answers: ["Rocky Balboa"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2006',
        notable_actor: '',
        director: 'Rocky Balboa, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1206': {
        content: '',
        submitted_by: '',
        answers: ["Stuart Little"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Family, Fantasy, Comedy, Adventure',
        release_year: '1999',
        notable_actor: 'tbd',
        director: 'Stuart Little, 1999 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1207': {
        content: '',
        submitted_by: '',
        answers: ["Rambo III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sf',
        genre: 'Action, Adventure, Thriller, War',
        release_year: '1988',
        notable_actor: 'sdf',
        director: 'Rambo III, 1988 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1208': {
        content: '',
        submitted_by: '',
        answers: ["The Pursuit of Happyness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2006',
        notable_actor: '',
        director: 'The Pursuit of Happyness, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1209': {
        content: '',
        submitted_by: '',
        answers: ["Yes Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2008',
        notable_actor: '',
        director: 'Yes Man, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1210': {
        content: '',
        submitted_by: '',
        answers: ["Bad Boys II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Comedy, Thriller, Crime',
        release_year: '2003',
        notable_actor: '',
        director: 'Bad Boys II, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1211': {
        content: '',
        submitted_by: '',
        answers: ["Don't Look Up"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Comedy, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Don\'t Look Up, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1212': {
        content: '',
        submitted_by: '',
        answers: ["War Dogs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sdf',
        genre: 'Comedy, Crime, Drama',
        release_year: '2016',
        notable_actor: 'sdf',
        director: 'War Dogs, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1213': {
        content: '',
        submitted_by: '',
        answers: ["The Other Guys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbdt',
        genre: 'Action, Comedy, Crime',
        release_year: '2010',
        notable_actor: 'tbd',
        director: 'The Other Guys, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1214': {
        content: '',
        submitted_by: '',
        answers: ["Hulk"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2003',
        notable_actor: '',
        director: 'Hulk, 2003 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1215': {
        content: '',
        submitted_by: '',
        answers: ["Dumb and Dumber"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sdf',
        genre: 'Comedy',
        release_year: '1994',
        notable_actor: 'sdf',
        director: 'Dumb and Dumber, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1216': {
        content: '',
        submitted_by: '',
        answers: ["True Lies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '1994',
        notable_actor: '',
        director: 'True Lies, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1217': {
        content: '',
        submitted_by: '',
        answers: ["Solace"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Mystery',
        release_year: '2015',
        notable_actor: '',
        director: 'Solace, 2015 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1218': {
        content: '',
        submitted_by: '',
        answers: ["The Harder They Fall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '2021',
        notable_actor: '',
        director: 'The Harder They Fall, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1219': {
        content: '',
        submitted_by: '',
        answers: ["Oblivion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure, Mystery',
        release_year: '2013',
        notable_actor: '',
        director: 'Oblivion, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1220': {
        content: '',
        submitted_by: '',
        answers: ["The Mechanic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2011',
        notable_actor: '',
        director: 'The Mechanic, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1221': {
        content: '',
        submitted_by: '',
        answers: ["He's All That"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2021',
        notable_actor: '',
        director: 'He\'s All That, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1222': {
        content: '',
        submitted_by: '',
        answers: ["Survival Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Drama, Romance',
        release_year: '2006',
        notable_actor: '',
        director: 'Survival Island, 2006 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1223': {
        content: '',
        submitted_by: '',
        answers: ["Undisputed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '2002',
        notable_actor: '',
        director: 'Undisputed, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1224': {
        content: '',
        submitted_by: '',
        answers: ["Point Break"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '1991',
        notable_actor: '',
        director: 'Point Break, 1991 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1225': {
        content: '',
        submitted_by: '',
        answers: ["Unknown"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Unknown, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1226': {
        content: '',
        submitted_by: '',
        answers: ["Alone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2020',
        notable_actor: '',
        director: 'Alone, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1227': {
        content: '',
        submitted_by: '',
        answers: ["Vertigo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Romance, Thriller',
        release_year: '1958',
        notable_actor: '',
        director: 'Vertigo, 1958 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1228': {
        content: '',
        submitted_by: '',
        answers: ["A Nightmare on Elm Street Part 2: Freddy's Revenge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1985',
        notable_actor: '',
        director: 'A Nightmare on Elm Street Part 2: Freddy\'s Revenge, 1985 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1229': {
        content: '',
        submitted_by: '',
        answers: ["Armageddon Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Armageddon Time, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1230': {
        content: '',
        submitted_by: '',
        answers: ["Love, Rosie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance, Drama',
        release_year: '2014',
        notable_actor: '',
        director: 'Love, Rosie, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1231': {
        content: '',
        submitted_by: '',
        answers: ["Ghost in the Shell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Animation, Science Fiction',
        release_year: '1995',
        notable_actor: 'tbd',
        director: 'Ghost in the Shell, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1232': {
        content: '',
        submitted_by: '',
        answers: ["The Curious Case of Benjamin Button"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Drama, Fantasy, Romance',
        release_year: '2008',
        notable_actor: 'tbd',
        director: 'The Curious Case of Benjamin Button, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1233': {
        content: '',
        submitted_by: '',
        answers: ["The 5th Wave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2016',
        notable_actor: '',
        director: 'The 5th Wave, 2016 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1234': {
        content: '',
        submitted_by: '',
        answers: ["Robin Hood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1973',
        notable_actor: '',
        director: 'Robin Hood, 1973 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1235': {
        content: '',
        submitted_by: '',
        answers: ["The Little Rascals"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy, Family',
        release_year: '1994',
        notable_actor: '',
        director: 'The Little Rascals, 1994 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1236': {
        content: '',
        submitted_by: '',
        answers: ["Descendants 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Music, Fantasy, TV Movie, Adventure, Action, Romance',
        release_year: '2017',
        notable_actor: '',
        director: 'Descendants 2, 2017 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1237': {
        content: '',
        submitted_by: '',
        answers: ["My Sister's Keeper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2009',
        notable_actor: '',
        director: 'My Sister\'s Keeper, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1238': {
        content: '',
        submitted_by: '',
        answers: ["Demonic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Demonic, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1239': {
        content: '',
        submitted_by: '',
        answers: ["Insidious: Chapter 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Insidious: Chapter 2, 2013 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1240': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Goblet of Fire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tb',
        genre: 'Adventure, Fantasy, Family',
        release_year: '2005',
        notable_actor: 'sdf',
        director: 'Harry Potter and the Goblet of Fire, 2005 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1241': {
        content: '',
        submitted_by: '',
        answers: ["Belle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Belle, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1242': {
        content: '',
        submitted_by: '',
        answers: ["Skyline"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Skyline, 2010 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1243': {
        content: '',
        submitted_by: '',
        answers: ["Hypnotic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Hypnotic, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1244': {
        content: '',
        submitted_by: '',
        answers: ["Splice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Splice, 2009 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1245': {
        content: '',
        submitted_by: '',
        answers: ["A Walk to Remember"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2002',
        notable_actor: '',
        director: 'A Walk to Remember, 2002 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1246': {
        content: '',
        submitted_by: '',
        answers: ["Crush"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Crush, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1247': {
        content: '',
        submitted_by: '',
        answers: ["Ready or Not"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Ready or Not, 2019 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1248': {
        content: '',
        submitted_by: '',
        answers: ["Sucker Punch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Sucker Punch, 2011 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1249': {
        content: '',
        submitted_by: '',
        answers: ["Those Who Wish Me Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Those Who Wish Me Dead, 2021 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1250': {
        content: '',
        submitted_by: '',
        answers: ["Step Up All In"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama, Music',
        release_year: '2014',
        notable_actor: '',
        director: 'Step Up All In, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1251': {
        content: '',
        submitted_by: '',
        answers: ["The Fault in Our Stars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Romance, Drama',
        release_year: '2014',
        notable_actor: 'tbd',
        director: 'The Fault in Our Stars, 2014 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1252': {
        content: '',
        submitted_by: '',
        answers: ["The Expendables 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'The Expendables 2, 2012 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1253': {
        content: '',
        submitted_by: '',
        answers: ["Casper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Fantasy, Comedy, Family',
        release_year: '1995',
        notable_actor: 'tbd',
        director: 'Casper, 1995 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1254': {
        content: '',
        submitted_by: '',
        answers: ["Cast Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama',
        release_year: '2000',
        notable_actor: '',
        director: 'Cast Away, 2000 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1255': {
        content: '',
        submitted_by: '',
        answers: ["Skylines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Skylines, 2020 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1256': {
        content: '',
        submitted_by: '',
        answers: ["Shaun of the Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Horror, Comedy',
        release_year: '2004',
        notable_actor: 'tbd',
        director: 'Shaun of the Dead, 2004 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1257': {
        content: '',
        submitted_by: '',
        answers: ["Mortal Engines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Mortal Engines, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1258': {
        content: '',
        submitted_by: '',
        answers: ["Do Revenge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Do Revenge, 2022 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1259': {
        content: '',
        submitted_by: '',
        answers: ["Annihilation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Horror',
        release_year: '2018',
        notable_actor: '',
        director: 'Annihilation, 2018 director',
        ignore_images: 'False',
        imdb_id: '',
    },
    '1260': {
        content: '',
        submitted_by: '',
        answers: ["The Mummy: Tomb of the Dragon Emperor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2008',
        notable_actor: '',
        director: 'The Mummy: Tomb of the Dragon Emperor, 2008 director',
        ignore_images: 'False',
        imdb_id: '',
    },
};
