// @flow
import { KOFI_URL, SEARCH_ITEM_SUBMISSION_URL } from '../../constants';
import { setModal } from '../../store/slices/uxSlice';

import { useDispatch, useSelector } from 'react-redux';
import { answers } from '../../puzzles/gtgq_puzzles';

import React, { useMemo, useRef, useEffect } from 'react';
import type { TDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../settings/useSettings';
import CloseModalButton from './CloseModalButton';

const QuestCompletionModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();

    const modalRef = useRef();

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
        }
    }, []);

    const { puzzleId } = useSelector((state: State) => state.ux);

    const completionMessage = useMemo(() => (puzzleId !== '' ? answers[puzzleId].completion_message : ''), [puzzleId]);

    return (
        <div ref={modalRef} className="modal love-modal">
            <div className="love-modal-inner">
                <CloseModalButton />

                <h3>You Beat The Quest</h3>
                <p>{completionMessage}</p>

                <p>
                    I hope you enjoy this daily puzzle game! If it has brightened your day, the best way you can support
                    us is to share the site with a friend, post your daily score on reddit, discord or your favorite
                    website, or share {settings.website_title} with your favorite news outlet!
                </p>
                <>
                    <h4>{'Support Us on Ko-fi'}</h4>

                    <p className="login">
                        If you would like to support {settings.website_title} development, you can make a donation or
                        become a monthly member at{' '}
                        <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                            <span style={{ whiteSpace: 'nowrap' }}>Ko-Fi</span>
                        </a>
                        <br />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                                <img src={'/img/kofi_s_tag_dark.png'} alt="Ko-Fi" style={{ height: '50px' }} />
                            </a>
                        </div>
                    </p>
                </>
            </div>
        </div>
    );
};

export default QuestCompletionModal;
