// @flow
import { setModal } from '../../store/slices/uxSlice';

import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import type { TDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import type { State } from '../../store/types';
import { CORRECT_EMOJI, INCORRECT_EMOJI, PARTIAL_EMOJI } from '../../constants';
import { useSettings } from '../../settings/useSettings';
import CloseModalButton from './CloseModalButton';

const HowToPlayModal = (): React$Element<'div'> => {
    const { t } = useTranslation();
    const settings = useSettings();
    const dispatch: TDispatch = useDispatch();
    const { puzzleType } = useSelector((state: State) => state.ux);
    const { modalTexts } = settings.TEXTS.modal.HowToPlayModal;

    return (
        <div className="modal howtoplay-modal">
            <div className="howtoplay-modal-inner">
                <CloseModalButton />

                <h3>
                    {t('How To Play')} {settings.title}
                </h3>
                {modalTexts.map((text, index) => (
                    <p key={index}>
                        {typeof text === 'string' ? (
                            text
                        ) : (
                            <>
                                {text.beforeLink}
                                <a href={text.url} target="_blank" rel="noopener noreferrer">
                                    {text.linkText}
                                </a>
                                {text.afterLink}
                            </>
                        )}
                    </p>
                ))}
                <p>
                    {CORRECT_EMOJI}= {t('Correct')}{' '}
                </p>
                <p>
                    {INCORRECT_EMOJI} = {t('Incorrect')}
                </p>
                {puzzleType === 'gtg' || puzzleType === 'gtgc' ? (
                    <div>
                        {PARTIAL_EMOJI} = {t('Correct Franchise')}
                        <br />
                        <br />
                        <p style={{ fontSize: '12px' }}>
                            {t('Example')}:
                            <br />
                            {t(
                                'Grand Theft Auto 3, Grand Theft Auto 4, Grand Theft Auto 5 would be the right franchise for Grand Theft Auto 1',
                            )}
                            .
                            <br />
                            <br />
                            {t(
                                'Final Fantasy Tactics, Final Fantasy Online, Fintal Fantasy 12 would be the right franchise for Final Fantasy II',
                            )}
                            .
                            <br />
                            <br />
                            {t('Super Mario Brothers, Mario Galaxy would be the right franchise for Paper Mario')}.
                            <br />
                            <br />
                            {t(
                                'Yoshis Island, Super Smash Brothers, Donkey Kong would not be the right franchise for Paper Mario',
                            )}
                            .
                        </p>
                    </div>
                ) : null}
                <p>{t('Good Luck!')}</p>
            </div>
        </div>
    );
};

export default HowToPlayModal;
