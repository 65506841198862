import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';

const AngleAnimation = ({ angle }) => {
    const lineLength = 100; // Length of the line
    const radius = 20; // Radius of the arc

    // State for the drag angle
    const [dragAngle, setDragAngle] = useState(0);

    // Create rotation animation
    const props = useSpring({ to: { deg: angle + dragAngle }, from: { deg: 0 }, config: { duration: 2000 } });

    // Calculate arc path
    const largeArcFlag = angle > 180 ? 1 : 0;

    // Drag gesture handler
    const bind = useDrag(({ down, movement: [mx, my] }) => {
        // Calculate the angle based on the mouse movement
        const newDragAngle = Math.atan2(my, mx) * (180 / Math.PI);
        setDragAngle(down ? newDragAngle : 0);
    });

    return (
        <svg width="400" height="250" viewBox="0 0 200 250">
            <defs>
                {/* Define arrow marker */}
                <marker id="arrow" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
                    <path d="M0,0 L10,5 L0,10 Z" fill="white" />
                </marker>
                <marker id="arrowRed" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
                    <path d="M0,0 L10,5 L0,10 Z" fill="red" />
                </marker>
            </defs>

            {/* Static line with arrow */}
            <line
                x1="100"
                y1="100"
                x2={100 + lineLength}
                y2="100"
                stroke="white"
                strokeWidth="2"
                markerEnd="url(#arrow)"
            />

            {/* Rotating line with arrow */}
            <animated.line
                {...bind()}
                x1="100"
                y1="100"
                x2={props.deg.interpolate((deg) => 100 + lineLength * Math.cos(deg * (Math.PI / 180)))}
                y2={props.deg.interpolate((deg) => 100 + lineLength * Math.sin(deg * (Math.PI / 180)))}
                stroke="red"
                strokeWidth="2"
                markerEnd="url(#arrowRed)"
            />
            {/* Arc indicating inner angle */}
            <animated.path
                d={props.deg.interpolate((deg) => {
                    const endX = 100 + radius * Math.cos(deg * (Math.PI / 180));
                    const endY = 100 + radius * Math.sin(deg * (Math.PI / 180));
                    return `M 100 100 L 120 100 A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY} Z`;
                })}
                fill="none"
                stroke="white"
                strokeWidth="1"
            />
        </svg>
    );
};

export default AngleAnimation;
