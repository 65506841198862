// @flow
import { setModal } from '../../store/slices/uxSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect, useState, useMemo } from 'react';
import type { TDispatch } from '../../store/store';
import type { State } from '../../store/types';
import { AnimatePresence, motion } from 'framer-motion';
import { $NotNull, API_BASE, DEV_MODE } from '../../globals';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveBar } from '@nivo/bar';
import { TableTooltip, Chip } from '@nivo/tooltip';
import KofiButton from './../Kofi';
import { useSettings } from '../../settings/useSettings';
import axios from 'axios';
import SubscribeOnKofiButton from '../SubscribeOnKofiButton';
import { useQuests } from '../Puzzle/hooks/questHooks';
import CloseModalButton from './CloseModalButton';

const getStatsString = (
    title,
    played,
    won,
    winPercentage,
    currentStreak,
    maxStreak,
    maxGuesses,
    numWinsAtGuess,
    currentRank,
) => {
    let string = `${title} ${'Stats'} \n`;
    string += `${'Played'}: ${played}\n`;
    string += `${'Won'}: ${won}\n`;
    string += `${'Win'}: ${isNaN(winPercentage) ? 0 : winPercentage} %\n`;
    string += `${'Current Streak'}: ${currentStreak}\n`;
    string += `${'Max Streak'}: ${maxStreak}\n\n`;
    string += `${'Win Distribution'}\n`;

    const MAX_DISTRIBUTION_LENGTH = 6;
    let largestValue = Math.max(...Object.values(numWinsAtGuess));
    largestValue = largestValue <= 0 ? 1 : largestValue;
    Array.from(Array(maxGuesses)).forEach((_, index) => {
        const numOfWins = numWinsAtGuess[(index + 1).toString()];
        const displayLength = Math.ceil((numOfWins / largestValue) * (MAX_DISTRIBUTION_LENGTH - 1));
        string += '🟩'.repeat(1 + displayLength) + ` ${numOfWins}\n`;
    });
    if (currentRank) {
        string += `\n\nRank: ${currentRank} 🏆`;
    }
    return string;
};

const graphTheme = {
    textColor: '#fff', // sets the default text color to white
    axis: {
        legend: {
            text: {
                fill: '#fff', // ensures axis legends are also white
            },
        },
        ticks: {
            text: {
                fill: '#fff', // ensures axis ticks text is white
            },
        },
    },
    legends: {
        text: {
            fill: '#29abe0', // legend text color
        },
    },
    tooltip: {
        container: {
            background: '#333', // background color of the tooltip
            color: '#fff', // text color of the tooltip
        },
        table: {
            width: 'auto',
        },
        tableCell: {
            padding: '3px 5px',
        },
    },
};

const funnyMessages = [
    'Fetching Your Results',
    'Assembling your awesomeness...',
    'Searching the data vaults...',
    'Calculating your legendary status...',
    'Counting all the pixels...',
    'Running a marathon of calculations...',
    'Unraveling the mysteries of your stats...',
    'Finding the hidden gems in your performance...',
    'Making sense of your brilliant moves...',
    'Tuning in to your best genre moments...',
    'Rewiring the mainframe... just kidding!',
    'Summoning the data wizards...',
    'Rebooting your leaderboard dominance...',
];

// Function to get 4 random messages
function getRandomMessages() {
    const shuffled = funnyMessages.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3); // Select 4 random messages
}

const StatsModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();

    const settings = useSettings();
    const [showRankToggle, toggleShowRankToggle] = useState(false);
    const [activeTab, setActiveTab] = useState('game');
    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };
    const puzzleType = useMemo(() => {
        // kinda hacky. won't work if we add another sub puzzle that needs to be viewed in stats but thats a problem for another day
        return activeTab === 'game' ? settings.parent_abbr : settings.parent_abbr + 'c';
    }, [activeTab, settings]);
    const currentTabSettings = useSettings(puzzleType);
    const [userGenreStats, setUserGenreStats] = useState({
        'Subscribe On Kofi to see full stats': 1,
        'Tasteful Animation': 0.5,
        'Dating Simulator': 0.5,
        'Spicy Fiction': 0.3,
        'Hip-Hop': 0.5,
        Adventure: 0.85,
    });
    const [userPlatformStats, setUserPlatformStats] = useState({
        'Subscribe on Ko-Fi to see full stats': 1,
        PS5: 0.85,
        Atari: 0.75,
        SNES: 0.54,
        X360: 0.25,
        'Atari Jaguar': 0.25,
    });
    const [userYearsStats, setUserYearsStats] = useState({ '2000': 3, '2010': 2, '2020': 8 });

    let { played, won, currentStreak, maxStreak, winPercentage, currentRank, numWinsAtGuess } = useSelector(
        (state: State) => state.stats[puzzleType],
    );

    const maxGuesses = currentTabSettings.max_guesses;
    console.log('max guesses', maxGuesses);

    const percentageDenominator = Math.max(...Object.values(numWinsAtGuess));
    console.log('numwins at guess: ', numWinsAtGuess);
    console.log('precentage demno: ', percentageDenominator);

    let winsToNextRank: number | string = 0;

    for (let key of Object.keys(settings.progress_ranks)) {
        // console.log('key', key, 'won', won);
        if (parseInt(key) > parseInt(won)) {
            winsToNextRank = parseInt(key) - parseInt(won);
            console.log('winsToNextRank', winsToNextRank);
            break; // exit the loop as soon as we find a match
        }
        winsToNextRank = 'You have reached the highest rank!';
    }

    const [copiedClipboardStatus, setCopiedClipboardStatus] = useState(false);
    const copyStatsToClipboard = async () => {
        let status;
        try {
            const text = getStatsString(
                activeTab === 'game' ? settings.getParentTitle() : settings.getCoverTitle(),
                played,
                won,
                winPercentage,
                currentStreak,
                maxStreak,
                maxGuesses,
                numWinsAtGuess,
                activeTab === 'game' ? currentRank : null,
            );
            await navigator.clipboard.writeText(text);
            status = true;
        } catch (err) {
            console.error('Failed to copy to clipboard: ', err);
            status = 'failed';
        } finally {
            setCopiedClipboardStatus(status);
            setTimeout(() => {
                setCopiedClipboardStatus(false);
            }, 1500);
        }
    };

    const userState = useSelector((state: State) => state.user);
    // Determine the class name based on userState.user
    useEffect(() => {
        if (userState.user || DEV_MODE) {
            const updateLoadingStats = (setStatsFn) => {
                const messages = getRandomMessages();
                const stats = messages.reduce((acc, msg, index) => {
                    acc[msg] = index + 1; // Assigns values 1 to 4
                    return acc;
                }, {});
                setStatsFn(stats);
            };

            // Update both genre and platform stats with random messages
            updateLoadingStats(setUserGenreStats);
            updateLoadingStats(setUserPlatformStats);

            const getUserStats = async () => {
                try {
                    const response = await axios.get(`${API_BASE}/api/get_user_stats/` + settings.parent_abbr + '/');
                    console.log('response', response);
                    setUserGenreStats(response.data['genres'] || {});
                    setUserPlatformStats(response.data['platforms'] || {});
                    setUserYearsStats(response.data['years'] || {});
                } catch (error) {
                    console.error(error);
                }
            };

            getUserStats();
        }
    }, [settings, userState.user]);

    const radarGenreChartData = Object.entries(userGenreStats).map(([genre, value]) => {
        return { genre, value: Number(value) };
    });
    const radarPlatformChartData = Object.entries(userPlatformStats).map(([genre, value]) => {
        return { genre, value: Number(value) };
    });
    const barYearChartData = Object.entries(userYearsStats)
        .sort((a, b) => b[0].localeCompare(a[0])) // Sort years in descending order
        .map(([year, value]) => {
            return { year, value: Number(value) };
        });

    const quests = useQuests(settings);

    const navigate = useNavigate();
    const onQuestClick = (quest) => {
        const navigateFunc = () => navigate({ pathname: `/gtgq/${quest.id}/1`, state: { quest } });
        if (!userState.user) {
            dispatch(setModal('inter-ad'));
            window.globalThis.interAdNextFunc = navigateFunc;
        } else {
            navigateFunc();
        }
    };

    return (
        <div className="modal stats-modal">
            <div className="stats-modal-inner">
                <CloseModalButton />
                <h3>{'Stats'}</h3>
                {/* Tab headers */}
                <div
                    className="tabs"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}
                >
                    <div
                        onClick={() => handleTabSwitch('game')}
                        className={`tab ${activeTab === 'game' ? 'active' : ''}`}
                        style={{ opacity: activeTab === 'game' ? 1 : 0.5 }}
                    >
                        <span className={'modal-mini-heading'}>{settings.getStatsModalTitle()}</span>
                        <motion.div
                            className="tab-indicator"
                            initial={false}
                            animate={{ opacity: activeTab === 'game' ? 1 : 0 }}
                            transition={{ duration: 0.3 }}
                        />
                    </div>
                    {settings.paths.guess_the_cover && (
                        <>
                            <div className={'modal-mini-heading'}>|</div>
                            <div
                                onClick={() => handleTabSwitch('cover')}
                                className={`tab ${activeTab === 'cover' ? 'active' : ''}`}
                                style={{ opacity: activeTab === 'cover' ? 1 : 0.5 }}
                            >
                                <span className={'modal-mini-heading'}>{settings.getCoverTitle()}</span>
                                <motion.div
                                    className="tab-indicator"
                                    initial={false}
                                    animate={{ opacity: activeTab === 'cover' ? 1 : 0 }}
                                    transition={{ duration: 0.3 }}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="stats-section">
                    <div className="stat">
                        <div className="stat-value total-games-played">{played}</div>
                        <div className="stat-label">{'Played'}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value total-games-won">{won}</div>
                        <div className="stat-label">{'Won'}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{isNaN(winPercentage) ? 0 : winPercentage}</div>
                        <div className="stat-label">{'Win'} %</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value current-streak">{currentStreak}</div>
                        <div className="stat-label">{'Current Streak'}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value max-streak">{maxStreak}</div>
                        <div className="stat-label">{'Max Streak'}</div>
                    </div>
                </div>
                <h3 style={{ textAlign: 'center', marginTop: '10px' }}>{'Win Distribution'}</h3>
                <div className="guess-distribution">
                    {Array.from({ length: maxGuesses }, (_, i) => i).map((idx) => (
                        <div key={idx + 1} className="guess-distribution-row">
                            <p>{idx + 1}</p>
                            <div
                                className="guess-row-bar"
                                style={{
                                    width: `${(numWinsAtGuess[(idx + 1).toString()] / percentageDenominator) * 100}%`,
                                }}
                            >
                                {numWinsAtGuess[(idx + 1).toString()]}
                            </div>
                        </div>
                    ))}
                </div>
                {/* only show the cool stats if its gtg, gtb, gta, gtm */}
                {settings.parent_abbr === 'gtg' ||
                settings.parent_abbr === 'gtb' ||
                settings.parent_abbr === 'gta' ||
                settings.parent_abbr === 'gtm' ? (
                    <>
                        {activeTab === 'game' && (
                            <>
                                <div
                                    className={'UserStats'}
                                    style={{
                                        containerType: 'inline-size',
                                        marginBottom: '30px',
                                    }}
                                >
                                    <h3 style={{ textAlign: 'center' }}>Best Decade</h3>
                                    <div className="chart-container">
                                        {!userState.user ? <SubscribeOnKofiButton /> : null}
                                        <ResponsiveBar
                                            data={barYearChartData}
                                            keys={['value']}
                                            layout={'horizontal'} // change to horizontal
                                            indexBy="year"
                                            tooltip={BarCustomTooltip}
                                            margin={{ right: 25, bottom: 50, left: 50 }}
                                            padding={0.3}
                                            valueScale={{ type: 'linear' }}
                                            indexScale={{ type: 'band', round: true }}
                                            colors={{ scheme: 'accent' }}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true,
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10,
                                                },
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 1,
                                                tickRotation: 0,
                                                legendPosition: 'middle',
                                                legendOffset: 32,
                                                tickValues: 4,
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legendPosition: 'end',
                                                legendOffset: -40,
                                                legend: 'Year',
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            theme={graphTheme}
                                        />
                                    </div>
                                    <h3 style={{ textAlign: 'center' }}>Genre Stats</h3>

                                    <div className="chart-container">
                                        {!userState.user ? <SubscribeOnKofiButton /> : null}
                                        <ResponsiveRadar
                                            data={radarGenreChartData}
                                            keys={['value']}
                                            indexBy="genre"
                                            valueFormat={(v) => `${(v * 100).toFixed(2)}%`}
                                            sliceTooltip={RadarCustomTooltip}
                                            margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                                            borderColor={{ from: 'color' }}
                                            gridLabelOffset={10}
                                            dotSize={8}
                                            dotColor={{ theme: 'background' }}
                                            dotBorderWidth={2}
                                            colors={{ scheme: 'accent' }}
                                            blendMode="multiply"
                                            motionConfig="wobbly"
                                            theme={graphTheme}
                                        />
                                    </div>
                                    <p style={{ textAlign: 'center' }}>% correct within each genre</p>
                                    {settings.parent_abbr === 'gtg' && (
                                        <>
                                            <h3 style={{ textAlign: 'center' }}>Platform Stats</h3>
                                            <div className="chart-container">
                                                {!userState.user ? <SubscribeOnKofiButton /> : null}
                                                <ResponsiveRadar
                                                    data={radarPlatformChartData}
                                                    keys={['value']}
                                                    indexBy="genre"
                                                    valueFormat={(v) => `${(v * 100).toFixed(2)}%`}
                                                    sliceTooltip={RadarCustomTooltip}
                                                    margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                                                    borderColor={{ from: 'color' }}
                                                    gridLabelOffset={10}
                                                    dotSize={8}
                                                    dotColor={{ theme: 'background' }}
                                                    dotBorderWidth={2}
                                                    colors={{ scheme: 'accent' }}
                                                    blendMode="multiply"
                                                    motionConfig="wobbly"
                                                    theme={graphTheme}
                                                />
                                            </div>
                                            <p style={{ textAlign: 'center' }}>% correct within each platform</p>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                ) : null}
                {/* Quests; only show if settings.puzzle_type is GTGQ */}
                {/*TODO HIDE FOR NOW */}
                {/*{settings.puzzle_type === 'gtgq' && (*/}
                {/*    <>*/}
                {/*        <h2>Quests</h2>*/}
                {/*        <div className="quests-list">*/}
                {/*            {quests &&*/}
                {/*                quests.map((quest) => (*/}
                {/*                    <div key={quest.id} className="quest-item" onClick={() => onQuestClick(quest)}>*/}
                {/*                        <img*/}
                {/*                            className="quest-item-image"*/}
                {/*                            src={`/gtgq_games/${quest.id}/cover.webp`}*/}
                {/*                            alt={quest.title}*/}
                {/*                            style={{*/}
                {/*                                filter: quest.completed ? '' : 'grayscale(100%)',*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                        <p className="quest-item-title">{quest.title}</p>*/}
                {/*                    </div>*/}
                {/*                ))}*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}

                {!currentTabSettings.isChildPuzzle && (
                    <div className={'Rank'}>
                        <h3 style={{ textAlign: 'center', marginTop: '10px' }}>
                            Rank
                            <>
                                <svg
                                    className={`dropdown-icon ${showRankToggle ? 'rotated' : ''}`}
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                        toggleShowRankToggle(!showRankToggle);
                                    }}
                                    style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                                >
                                    <path
                                        d="M7.41 7.84L12 12.42L16.59 7.84L18 9.25L12 15.25L6 9.25L7.41 7.84Z"
                                        fill="currentColor"
                                    />
                                </svg>

                                <AnimatePresence>
                                    {showRankToggle && (
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                Your rank is determined by the total number of games you've won.
                                                <br />
                                                <div style={{ display: 'block' }}>
                                                    {/* iterate through progress_ranksand display the rank name and the number of wins required to reach that rank but dont show any past your current rank */}
                                                    {Object.keys(settings.progress_ranks).map((numWins, idx) => {
                                                        // $FlowIgnore keys are ints
                                                        if (numWins > won) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div
                                                                key={idx}
                                                                style={{
                                                                    margin: '0 10px',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        fontWeight: '400',
                                                                    }}
                                                                >
                                                                    {settings.progress_ranks[numWins]}: {numWins}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </span>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </>
                        </h3>

                        <div>
                            <p className={'rank'} style={{ textAlign: 'center' }}>
                                🏆 You are{' '}
                                {['a', 'e', 'i', 'o', 'u'].includes($NotNull(currentRank)[0].toLowerCase())
                                    ? 'an'
                                    : 'a'}{' '}
                                {currentRank}! 🏆
                                <br></br>
                                <span style={{ fontSize: '13px', fontStyle: 'italic' }}>
                                    ({winsToNextRank} more wins to rank up)
                                </span>
                            </p>
                        </div>
                    </div>
                )}
                <div className="buttons-group">
                    {activeTab === 'cover' && (
                        <>
                            <button
                                className="mainButton share-results-btn"
                                onClick={() => {
                                    const path = settings.paths.guess_the_cover.replace(':puzzleId?', 1);
                                    window.location.assign(path);
                                }}
                                style={{ fontSize: 16 }}
                            >
                                Play {settings.getCoverTitle()}
                            </button>
                            <br />
                        </>
                    )}
                    <button type="button" className="mainButton clipboardButton" onClick={copyStatsToClipboard}>
                        {copiedClipboardStatus === true && '✅ Copied!'}
                        {copiedClipboardStatus === 'failed' && '❌ Failed to copy!'}
                        {copiedClipboardStatus === false && '📋 Copy to clipboard'}
                    </button>
                    <div style={{ textAlign: 'center' }}>
                        <KofiButton preset="kufi-center" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const BarCustomTooltip = ({ color, label, ...data }) => {
    return (
        <TableTooltip
            title={<strong>{data.indexValue}</strong>}
            rows={[[<Chip key={data.id} color={color} />, data.formattedValue]]}
        />
    );
};

export const RadarCustomTooltip = ({ index, data }) => {
    const rows = useMemo(
        () => data.map((datum) => [<Chip key={datum.id} color={datum.color} />, datum.formattedValue]),
        [data],
    );

    return <TableTooltip title={<strong>{index}</strong>} rows={rows} />;
};

export default StatsModal;
