// ProgressBar.js
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { GUESS_IMAGE_FAILURE_COLOR, GUESS_IMAGE_NEUTRAL_COLOR, GUESS_IMAGE_SUCCESS_COLOR } from '../constants';

const ProgressBar = ({ itemStatus, baseUrl }) => {
    const navigate = useNavigate();

    const handleItemClick = (itemId) => {
        navigate(`${baseUrl}/${itemId}`);
    };

    return (
        <div style={{ display: 'flex', width: '100%', height: '20px',  }}>
            {itemStatus.map((status, index) => (
                <Tooltip
                    title={`Game #${index + 1} - ${
                        status === 's' ? 'Correct' : status === 'q' ? 'Unplayed' : 'Incorrect'
                    }`}
                    key={index}
                >
                    <div
                        onClick={() => handleItemClick(index + 1)}
                        style={{
                            flex: 1,
                            cursor: 'pointer',
                            backgroundColor:
                                status === 's'
                                    ? GUESS_IMAGE_SUCCESS_COLOR
                                    : status === 'f'
                                    ? GUESS_IMAGE_FAILURE_COLOR
                                    : GUESS_IMAGE_NEUTRAL_COLOR,
                            borderRight: index < itemStatus.length - 1 ? '2px solid black' : '',
                            // border: '1px solid white',
                        }}
                    />
                </Tooltip>
            ))}
        </div>
    );
};

export default ProgressBar;
