import React from 'react';
import { KOFI_URL } from '../constants';

const PremiumMemberOnly = ({ puzzleText }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                padding: '20px',
                fontSize: '13px',
                zIndex: '100',
                width: '100%',
                height: puzzleText === 'Quest' ? '380px' : '400px',
                backgroundColor: '#0f102a',
            }}
        >
            <h2 style={{ color: '#eee' }}>Guess The {puzzleText} is currently for premium members!</h2>
            <span style={{ color: '#eee' }}>
                Become a premium member by donating or subscribing on Ko-Fi and receive additional benefits:
                <ul>
                    <li>Access to Guess The Cover</li>
                    <li>Automatic Stat Syncing across multiple devices</li>
                    <li>No Ads</li>
                    <li>Access to Guess The Cover Previous Puzzles</li>
                    <li>Export Stats</li>
                </ul>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                        <img src={'/img/kofi_s_tag_dark.png'} alt="Ko-Fi" style={{ height: '50px' }} />
                    </a>
                </div>
            </span>
        </div>
    );
};

export default PremiumMemberOnly;
