/* eslint-disable no-useless-escape */
// @flow

export type TGTANGLEAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
};

type TGTANGLEAnswers = {
    [string]: TGTANGLEAnswer
};

export const answers: TGTANGLEAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["175"],
        affiliate_links: [],
        franchise: 'None',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["25"],
        affiliate_links: [],
        franchise: 'None',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["220"],
        affiliate_links: [],
        franchise: 'None',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["325"],
        affiliate_links: [],
        franchise: 'None',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["265"],
        affiliate_links: [],
        franchise: 'None',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["95"],
        affiliate_links: [],
        franchise: 'None',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["210"],
        affiliate_links: [],
        franchise: 'None',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["10"],
        affiliate_links: [],
        franchise: 'None',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["225"],
        affiliate_links: [],
        franchise: 'None',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["275"],
        affiliate_links: [],
        franchise: 'None',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["155"],
        affiliate_links: [],
        franchise: 'None',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["5"],
        affiliate_links: [],
        franchise: 'None',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["20"],
        affiliate_links: [],
        franchise: 'None',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["230"],
        affiliate_links: [],
        franchise: 'None',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["35"],
        affiliate_links: [],
        franchise: 'None',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["50"],
        affiliate_links: [],
        franchise: 'None',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["5"],
        affiliate_links: [],
        franchise: 'None',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["345"],
        affiliate_links: [],
        franchise: 'None',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["165"],
        affiliate_links: [],
        franchise: 'None',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["90"],
        affiliate_links: [],
        franchise: 'None',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["75"],
        affiliate_links: [],
        franchise: 'None',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["230"],
        affiliate_links: [],
        franchise: 'None',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["15"],
        affiliate_links: [],
        franchise: 'None',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["80"],
        affiliate_links: [],
        franchise: 'None',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["275"],
        affiliate_links: [],
        franchise: 'None',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["90"],
        affiliate_links: [],
        franchise: 'None',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["265"],
        affiliate_links: [],
        franchise: 'None',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["285"],
        affiliate_links: [],
        franchise: 'None',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["255"],
        affiliate_links: [],
        franchise: 'None',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["90"],
        affiliate_links: [],
        franchise: 'None',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["320"],
        affiliate_links: [],
        franchise: 'None',
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["180"],
        affiliate_links: [],
        franchise: 'None',
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["195"],
        affiliate_links: [],
        franchise: 'None',
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["125"],
        affiliate_links: [],
        franchise: 'None',
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["230"],
        affiliate_links: [],
        franchise: 'None',
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["140"],
        affiliate_links: [],
        franchise: 'None',
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["265"],
        affiliate_links: [],
        franchise: 'None',
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["310"],
        affiliate_links: [],
        franchise: 'None',
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["95"],
        affiliate_links: [],
        franchise: 'None',
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["275"],
        affiliate_links: [],
        franchise: 'None',
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["330"],
        affiliate_links: [],
        franchise: 'None',
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["165"],
        affiliate_links: [],
        franchise: 'None',
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["35"],
        affiliate_links: [],
        franchise: 'None',
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["55"],
        affiliate_links: [],
        franchise: 'None',
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["100"],
        affiliate_links: [],
        franchise: 'None',
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["170"],
        affiliate_links: [],
        franchise: 'None',
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '108': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["310"],
        affiliate_links: [],
        franchise: 'None',
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["165"],
        affiliate_links: [],
        franchise: 'None',
    },
    '111': {
        content: '',
        submitted_by: '',
        answers: ["105"],
        affiliate_links: [],
        franchise: 'None',
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["140"],
        affiliate_links: [],
        franchise: 'None',
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["330"],
        affiliate_links: [],
        franchise: 'None',
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["235"],
        affiliate_links: [],
        franchise: 'None',
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["75"],
        affiliate_links: [],
        franchise: 'None',
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["345"],
        affiliate_links: [],
        franchise: 'None',
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["235"],
        affiliate_links: [],
        franchise: 'None',
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["130"],
        affiliate_links: [],
        franchise: 'None',
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["115"],
        affiliate_links: [],
        franchise: 'None',
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["20"],
        affiliate_links: [],
        franchise: 'None',
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["330"],
        affiliate_links: [],
        franchise: 'None',
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["210"],
        affiliate_links: [],
        franchise: 'None',
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["165"],
        affiliate_links: [],
        franchise: 'None',
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["95"],
        affiliate_links: [],
        franchise: 'None',
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["235"],
        affiliate_links: [],
        franchise: 'None',
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["155"],
        affiliate_links: [],
        franchise: 'None',
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["325"],
        affiliate_links: [],
        franchise: 'None',
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["60"],
        affiliate_links: [],
        franchise: 'None',
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["25"],
        affiliate_links: [],
        franchise: 'None',
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["125"],
        affiliate_links: [],
        franchise: 'None',
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["25"],
        affiliate_links: [],
        franchise: 'None',
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["160"],
        affiliate_links: [],
        franchise: 'None',
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["265"],
        affiliate_links: [],
        franchise: 'None',
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '156': {
        content: '',
        submitted_by: '',
        answers: ["35"],
        affiliate_links: [],
        franchise: 'None',
    },
    '157': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '158': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["225"],
        affiliate_links: [],
        franchise: 'None',
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["160"],
        affiliate_links: [],
        franchise: 'None',
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["345"],
        affiliate_links: [],
        franchise: 'None',
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["225"],
        affiliate_links: [],
        franchise: 'None',
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["55"],
        affiliate_links: [],
        franchise: 'None',
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["285"],
        affiliate_links: [],
        franchise: 'None',
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["265"],
        affiliate_links: [],
        franchise: 'None',
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["105"],
        affiliate_links: [],
        franchise: 'None',
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["170"],
        affiliate_links: [],
        franchise: 'None',
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '177': {
        content: '',
        submitted_by: '',
        answers: ["175"],
        affiliate_links: [],
        franchise: 'None',
    },
    '178': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '179': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '180': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '181': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '182': {
        content: '',
        submitted_by: '',
        answers: ["270"],
        affiliate_links: [],
        franchise: 'None',
    },
    '183': {
        content: '',
        submitted_by: '',
        answers: ["320"],
        affiliate_links: [],
        franchise: 'None',
    },
    '184': {
        content: '',
        submitted_by: '',
        answers: ["115"],
        affiliate_links: [],
        franchise: 'None',
    },
    '185': {
        content: '',
        submitted_by: '',
        answers: ["195"],
        affiliate_links: [],
        franchise: 'None',
    },
    '186': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '187': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '188': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '189': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '190': {
        content: '',
        submitted_by: '',
        answers: ["230"],
        affiliate_links: [],
        franchise: 'None',
    },
    '191': {
        content: '',
        submitted_by: '',
        answers: ["255"],
        affiliate_links: [],
        franchise: 'None',
    },
    '192': {
        content: '',
        submitted_by: '',
        answers: ["355"],
        affiliate_links: [],
        franchise: 'None',
    },
    '193': {
        content: '',
        submitted_by: '',
        answers: ["215"],
        affiliate_links: [],
        franchise: 'None',
    },
    '194': {
        content: '',
        submitted_by: '',
        answers: ["100"],
        affiliate_links: [],
        franchise: 'None',
    },
    '195': {
        content: '',
        submitted_by: '',
        answers: ["75"],
        affiliate_links: [],
        franchise: 'None',
    },
    '196': {
        content: '',
        submitted_by: '',
        answers: ["60"],
        affiliate_links: [],
        franchise: 'None',
    },
    '197': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '198': {
        content: '',
        submitted_by: '',
        answers: ["335"],
        affiliate_links: [],
        franchise: 'None',
    },
    '199': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '200': {
        content: '',
        submitted_by: '',
        answers: ["255"],
        affiliate_links: [],
        franchise: 'None',
    },
    '201': {
        content: '',
        submitted_by: '',
        answers: ["120"],
        affiliate_links: [],
        franchise: 'None',
    },
    '202': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '203': {
        content: '',
        submitted_by: '',
        answers: ["320"],
        affiliate_links: [],
        franchise: 'None',
    },
    '204': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '205': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '206': {
        content: '',
        submitted_by: '',
        answers: ["5"],
        affiliate_links: [],
        franchise: 'None',
    },
    '207': {
        content: '',
        submitted_by: '',
        answers: ["160"],
        affiliate_links: [],
        franchise: 'None',
    },
    '208': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '209': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '210': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '211': {
        content: '',
        submitted_by: '',
        answers: ["320"],
        affiliate_links: [],
        franchise: 'None',
    },
    '212': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '213': {
        content: '',
        submitted_by: '',
        answers: ["75"],
        affiliate_links: [],
        franchise: 'None',
    },
    '214': {
        content: '',
        submitted_by: '',
        answers: ["210"],
        affiliate_links: [],
        franchise: 'None',
    },
    '215': {
        content: '',
        submitted_by: '',
        answers: ["5"],
        affiliate_links: [],
        franchise: 'None',
    },
    '216': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '217': {
        content: '',
        submitted_by: '',
        answers: ["215"],
        affiliate_links: [],
        franchise: 'None',
    },
    '218': {
        content: '',
        submitted_by: '',
        answers: ["355"],
        affiliate_links: [],
        franchise: 'None',
    },
    '219': {
        content: '',
        submitted_by: '',
        answers: ["115"],
        affiliate_links: [],
        franchise: 'None',
    },
    '220': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '221': {
        content: '',
        submitted_by: '',
        answers: ["160"],
        affiliate_links: [],
        franchise: 'None',
    },
    '222': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '223': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '224': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '225': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '226': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '227': {
        content: '',
        submitted_by: '',
        answers: ["170"],
        affiliate_links: [],
        franchise: 'None',
    },
    '228': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '229': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '230': {
        content: '',
        submitted_by: '',
        answers: ["185"],
        affiliate_links: [],
        franchise: 'None',
    },
    '231': {
        content: '',
        submitted_by: '',
        answers: ["355"],
        affiliate_links: [],
        franchise: 'None',
    },
    '232': {
        content: '',
        submitted_by: '',
        answers: ["310"],
        affiliate_links: [],
        franchise: 'None',
    },
    '233': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '234': {
        content: '',
        submitted_by: '',
        answers: ["235"],
        affiliate_links: [],
        franchise: 'None',
    },
    '235': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '236': {
        content: '',
        submitted_by: '',
        answers: ["270"],
        affiliate_links: [],
        franchise: 'None',
    },
    '237': {
        content: '',
        submitted_by: '',
        answers: ["15"],
        affiliate_links: [],
        franchise: 'None',
    },
    '238': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '239': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '240': {
        content: '',
        submitted_by: '',
        answers: ["180"],
        affiliate_links: [],
        franchise: 'None',
    },
    '241': {
        content: '',
        submitted_by: '',
        answers: ["320"],
        affiliate_links: [],
        franchise: 'None',
    },
    '242': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '243': {
        content: '',
        submitted_by: '',
        answers: ["355"],
        affiliate_links: [],
        franchise: 'None',
    },
    '244': {
        content: '',
        submitted_by: '',
        answers: ["180"],
        affiliate_links: [],
        franchise: 'None',
    },
    '245': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '246': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '247': {
        content: '',
        submitted_by: '',
        answers: ["80"],
        affiliate_links: [],
        franchise: 'None',
    },
    '248': {
        content: '',
        submitted_by: '',
        answers: ["60"],
        affiliate_links: [],
        franchise: 'None',
    },
    '249': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '250': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '251': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '252': {
        content: '',
        submitted_by: '',
        answers: ["120"],
        affiliate_links: [],
        franchise: 'None',
    },
    '253': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '254': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '255': {
        content: '',
        submitted_by: '',
        answers: ["225"],
        affiliate_links: [],
        franchise: 'None',
    },
    '256': {
        content: '',
        submitted_by: '',
        answers: ["160"],
        affiliate_links: [],
        franchise: 'None',
    },
    '257': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '258': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '259': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '260': {
        content: '',
        submitted_by: '',
        answers: ["35"],
        affiliate_links: [],
        franchise: 'None',
    },
    '261': {
        content: '',
        submitted_by: '',
        answers: ["275"],
        affiliate_links: [],
        franchise: 'None',
    },
    '262': {
        content: '',
        submitted_by: '',
        answers: ["345"],
        affiliate_links: [],
        franchise: 'None',
    },
    '263': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '264': {
        content: '',
        submitted_by: '',
        answers: ["155"],
        affiliate_links: [],
        franchise: 'None',
    },
    '265': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '266': {
        content: '',
        submitted_by: '',
        answers: ["215"],
        affiliate_links: [],
        franchise: 'None',
    },
    '267': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '268': {
        content: '',
        submitted_by: '',
        answers: ["80"],
        affiliate_links: [],
        franchise: 'None',
    },
    '269': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '270': {
        content: '',
        submitted_by: '',
        answers: ["160"],
        affiliate_links: [],
        franchise: 'None',
    },
    '271': {
        content: '',
        submitted_by: '',
        answers: ["220"],
        affiliate_links: [],
        franchise: 'None',
    },
    '272': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '273': {
        content: '',
        submitted_by: '',
        answers: ["285"],
        affiliate_links: [],
        franchise: 'None',
    },
    '274': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '275': {
        content: '',
        submitted_by: '',
        answers: ["10"],
        affiliate_links: [],
        franchise: 'None',
    },
    '276': {
        content: '',
        submitted_by: '',
        answers: ["310"],
        affiliate_links: [],
        franchise: 'None',
    },
    '277': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '278': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '279': {
        content: '',
        submitted_by: '',
        answers: ["185"],
        affiliate_links: [],
        franchise: 'None',
    },
    '280': {
        content: '',
        submitted_by: '',
        answers: ["55"],
        affiliate_links: [],
        franchise: 'None',
    },
    '281': {
        content: '',
        submitted_by: '',
        answers: ["335"],
        affiliate_links: [],
        franchise: 'None',
    },
    '282': {
        content: '',
        submitted_by: '',
        answers: ["25"],
        affiliate_links: [],
        franchise: 'None',
    },
    '283': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '284': {
        content: '',
        submitted_by: '',
        answers: ["320"],
        affiliate_links: [],
        franchise: 'None',
    },
    '285': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '286': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '287': {
        content: '',
        submitted_by: '',
        answers: ["265"],
        affiliate_links: [],
        franchise: 'None',
    },
    '288': {
        content: '',
        submitted_by: '',
        answers: ["325"],
        affiliate_links: [],
        franchise: 'None',
    },
    '289': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '290': {
        content: '',
        submitted_by: '',
        answers: ["20"],
        affiliate_links: [],
        franchise: 'None',
    },
    '291': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '292': {
        content: '',
        submitted_by: '',
        answers: ["115"],
        affiliate_links: [],
        franchise: 'None',
    },
    '293': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '294': {
        content: '',
        submitted_by: '',
        answers: ["310"],
        affiliate_links: [],
        franchise: 'None',
    },
    '295': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '296': {
        content: '',
        submitted_by: '',
        answers: ["50"],
        affiliate_links: [],
        franchise: 'None',
    },
    '297': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '298': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '299': {
        content: '',
        submitted_by: '',
        answers: ["140"],
        affiliate_links: [],
        franchise: 'None',
    },
    '300': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '301': {
        content: '',
        submitted_by: '',
        answers: ["165"],
        affiliate_links: [],
        franchise: 'None',
    },
    '302': {
        content: '',
        submitted_by: '',
        answers: ["200"],
        affiliate_links: [],
        franchise: 'None',
    },
    '303': {
        content: '',
        submitted_by: '',
        answers: ["105"],
        affiliate_links: [],
        franchise: 'None',
    },
    '304': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '305': {
        content: '',
        submitted_by: '',
        answers: ["50"],
        affiliate_links: [],
        franchise: 'None',
    },
    '306': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '307': {
        content: '',
        submitted_by: '',
        answers: ["355"],
        affiliate_links: [],
        franchise: 'None',
    },
    '308': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '309': {
        content: '',
        submitted_by: '',
        answers: ["25"],
        affiliate_links: [],
        franchise: 'None',
    },
    '310': {
        content: '',
        submitted_by: '',
        answers: ["295"],
        affiliate_links: [],
        franchise: 'None',
    },
    '311': {
        content: '',
        submitted_by: '',
        answers: ["50"],
        affiliate_links: [],
        franchise: 'None',
    },
    '312': {
        content: '',
        submitted_by: '',
        answers: ["175"],
        affiliate_links: [],
        franchise: 'None',
    },
    '313': {
        content: '',
        submitted_by: '',
        answers: ["330"],
        affiliate_links: [],
        franchise: 'None',
    },
    '314': {
        content: '',
        submitted_by: '',
        answers: ["55"],
        affiliate_links: [],
        franchise: 'None',
    },
    '315': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '316': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '317': {
        content: '',
        submitted_by: '',
        answers: ["115"],
        affiliate_links: [],
        franchise: 'None',
    },
    '318': {
        content: '',
        submitted_by: '',
        answers: ["210"],
        affiliate_links: [],
        franchise: 'None',
    },
    '319': {
        content: '',
        submitted_by: '',
        answers: ["195"],
        affiliate_links: [],
        franchise: 'None',
    },
    '320': {
        content: '',
        submitted_by: '',
        answers: ["305"],
        affiliate_links: [],
        franchise: 'None',
    },
    '321': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '322': {
        content: '',
        submitted_by: '',
        answers: ["125"],
        affiliate_links: [],
        franchise: 'None',
    },
    '323': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '324': {
        content: '',
        submitted_by: '',
        answers: ["345"],
        affiliate_links: [],
        franchise: 'None',
    },
    '325': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '326': {
        content: '',
        submitted_by: '',
        answers: ["180"],
        affiliate_links: [],
        franchise: 'None',
    },
    '327': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '328': {
        content: '',
        submitted_by: '',
        answers: ["105"],
        affiliate_links: [],
        franchise: 'None',
    },
    '329': {
        content: '',
        submitted_by: '',
        answers: ["170"],
        affiliate_links: [],
        franchise: 'None',
    },
    '330': {
        content: '',
        submitted_by: '',
        answers: ["255"],
        affiliate_links: [],
        franchise: 'None',
    },
    '331': {
        content: '',
        submitted_by: '',
        answers: ["90"],
        affiliate_links: [],
        franchise: 'None',
    },
    '332': {
        content: '',
        submitted_by: '',
        answers: ["195"],
        affiliate_links: [],
        franchise: 'None',
    },
    '333': {
        content: '',
        submitted_by: '',
        answers: ["155"],
        affiliate_links: [],
        franchise: 'None',
    },
    '334': {
        content: '',
        submitted_by: '',
        answers: ["105"],
        affiliate_links: [],
        franchise: 'None',
    },
    '335': {
        content: '',
        submitted_by: '',
        answers: ["120"],
        affiliate_links: [],
        franchise: 'None',
    },
    '336': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '337': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '338': {
        content: '',
        submitted_by: '',
        answers: ["175"],
        affiliate_links: [],
        franchise: 'None',
    },
    '339': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '340': {
        content: '',
        submitted_by: '',
        answers: ["60"],
        affiliate_links: [],
        franchise: 'None',
    },
    '341': {
        content: '',
        submitted_by: '',
        answers: ["230"],
        affiliate_links: [],
        franchise: 'None',
    },
    '342': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '343': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '344': {
        content: '',
        submitted_by: '',
        answers: ["225"],
        affiliate_links: [],
        franchise: 'None',
    },
    '345': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '346': {
        content: '',
        submitted_by: '',
        answers: ["200"],
        affiliate_links: [],
        franchise: 'None',
    },
    '347': {
        content: '',
        submitted_by: '',
        answers: ["15"],
        affiliate_links: [],
        franchise: 'None',
    },
    '348': {
        content: '',
        submitted_by: '',
        answers: ["165"],
        affiliate_links: [],
        franchise: 'None',
    },
    '349': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '350': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '351': {
        content: '',
        submitted_by: '',
        answers: ["55"],
        affiliate_links: [],
        franchise: 'None',
    },
    '352': {
        content: '',
        submitted_by: '',
        answers: ["140"],
        affiliate_links: [],
        franchise: 'None',
    },
    '353': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '354': {
        content: '',
        submitted_by: '',
        answers: ["155"],
        affiliate_links: [],
        franchise: 'None',
    },
    '355': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '356': {
        content: '',
        submitted_by: '',
        answers: ["35"],
        affiliate_links: [],
        franchise: 'None',
    },
    '357': {
        content: '',
        submitted_by: '',
        answers: ["185"],
        affiliate_links: [],
        franchise: 'None',
    },
    '358': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '359': {
        content: '',
        submitted_by: '',
        answers: ["10"],
        affiliate_links: [],
        franchise: 'None',
    },
    '360': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '361': {
        content: '',
        submitted_by: '',
        answers: ["125"],
        affiliate_links: [],
        franchise: 'None',
    },
    '362': {
        content: '',
        submitted_by: '',
        answers: ["285"],
        affiliate_links: [],
        franchise: 'None',
    },
    '363': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '364': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '365': {
        content: '',
        submitted_by: '',
        answers: ["330"],
        affiliate_links: [],
        franchise: 'None',
    },
};
