// @flow
import { PUZZLE_TYPES } from '../../constants';
import { createSlice } from '@reduxjs/toolkit';
import type { ExtractActionType, PayloadAction } from '@reduxjs/toolkit';
import { rankToggle, franchiseToggle, linkToPuzzleToggle } from '../../localStateUtils';
import { checkIfEmbeddedExternally } from '../../clientUtils';

declare var __GTG_BUILD__: boolean;
declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;
declare var __GTM_BUILD__: boolean;
declare var __GTL_BUILD__: boolean;
declare var __GTH_BUILD__: boolean;
declare var __GTANGLE_BUILD__: boolean;

type Modals =
    | 'none'
    | 'love'
    | 'stats'
    | 'about'
    | 'howtoplay'
    | 'emailcallback'
    | 'setting'
    | 'inter-ad'
    | 'questcompletion';
type puzzleTypes = $Values<typeof PUZZLE_TYPES>;
export type UXState = {
    modal: Modals,
    rankToggle: boolean, // <-- added this line for rankToggle state
    franchiseToggle: boolean, // <-- added this line for franchiseToggle state
    puzzleType: puzzleTypes,
    showInterAd: boolean,
    isEmbeddedExternally: boolean,
    puzzleId: string,
};
const initialState: UXState = {
    modal: 'none',
    rankToggle: rankToggle.load(),
    franchiseToggle: franchiseToggle.load(),
    linkToPuzzleToggle: linkToPuzzleToggle.load(),
    puzzleType:
        (__GTG_BUILD__ && PUZZLE_TYPES.GTG) ||
        (__GTA_BUILD__ && PUZZLE_TYPES.GTA) ||
        (__GTB_BUILD__ && PUZZLE_TYPES.GTB) ||
        (__GTM_BUILD__ && PUZZLE_TYPES.GTM) ||
        (__GTL_BUILD__ && PUZZLE_TYPES.GTL) ||
        (__GTH_BUILD__ && PUZZLE_TYPES.GTH) ||
        (__GTANGLE_BUILD__ && PUZZLE_TYPES.GTANGLE) ||
        PUZZLE_TYPES.GTG,
    showInterAd: false,
    isEmbeddedExternally: checkIfEmbeddedExternally(),
    puzzleId: '',
};

// $FlowIgnore
const uxSlice = createSlice({
    name: 'ux',
    initialState: initialState,
    reducers: {
        setModal: (state: UXState, action: PayloadAction<Modals>) => {
            state.modal = action.payload;
        },
        setRankToggle: (state: UXState, action: PayloadAction<boolean>) => {
            state.rankToggle = action.payload;
            rankToggle.save(state.rankToggle); // Save the new state to localStorage
        },
        setPuzzleType: (state: UXState, action: PayloadAction<puzzleTypes>) => {
            state.puzzleType = action.payload;
        },
        setFranchiseToggle: (state: UXState, action: PayloadAction<boolean>) => {
            state.franchiseToggle = action.payload;
            franchiseToggle.save(state.franchiseToggle); // Save the new state to localStorage
        },
        setLinkToPuzzleToggle: (state: UXState, action: PayloadAction<boolean>) => {
            state.linkToPuzzleToggle = action.payload;
            linkToPuzzleToggle.save(state.linkToPuzzleToggle); // Save the new state to localStorage
        },
        setShowInterAd: (state: UXState, action: PayloadAction<boolean>) => {
            state.showInterAd = action.payload;
        },
        setPuzzleId: (state: UXState, action: PayloadAction<string>) => {
            state.puzzleId = action.payload;
        },
    },
});

// For some reason flow won't type this in other files unless i explicitly add the type
//  do let's do that to be safe
const setModal: (Modals) => PayloadAction<Modals> = uxSlice.actions.setModal;
const setRankToggle: (boolean) => PayloadAction<boolean> = uxSlice.actions.setRankToggle;
const setPuzzleType: (puzzleTypes) => PayloadAction<puzzleTypes> = uxSlice.actions.setPuzzleType; // <-- added this action creator
const setFranchiseToggle: (boolean) => PayloadAction<boolean> = uxSlice.actions.setFranchiseToggle;
const setLinkToPuzzleToggle: (boolean) => PayloadAction<boolean> = uxSlice.actions.setLinkToPuzzleToggle;
const setShowInterAd: (boolean) => PayloadAction<boolean> = uxSlice.actions.setShowInterAd;
const setPuzzleId: (puzzleId) => PayloadAction<string> = uxSlice.actions.setPuzzleId;

export type TUXActions = $ObjMap<
    typeof uxSlice.actions.setModal & typeof uxSlice.actions.setRankToggle & typeof uxSlice.actions.setFranchiseToggle,
    ExtractActionType,
>;
export {
    setModal,
    setRankToggle,
    setPuzzleType,
    setFranchiseToggle,
    setLinkToPuzzleToggle,
    setShowInterAd,
    setPuzzleId,
};
export default uxSlice;
